import axios from "axios";
import endpoints from "../config/endpoind.json";

export const dashboardData = (
  timeBlockType,
  timeBlockCount,
  attributions,
  date
) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.DashboardData,
        {
          TimeBlockType: timeBlockType || "Weeks",
          TargetId: 1,
          LinearId: 1,
          TimeBlockCount: timeBlockCount || 4,
          Attributions: [attributions?.id ? attributions.id : 1],
          DateStart: date.dateFrom || "",
          DateEnd: date.dateTo || "",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({ type: "SET_DATA_DASHBOARD", payload: response.data });
      dispatch({
        type: "SET_RENDER",
        payload: false,
      });
    } catch (err) {
      console.log("ERROR dashboard", err);
    }
  };
};
export const dashboardOptions = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.DashboardOptions,
        null,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: "GET_OPTIONS_DASHBOARD",
        payload: response.data,
      });
    } catch (err) {
      console.log("ERROR dashboard", err);
    }
  };
};

export const competitiveData = (
  timeBlockType,
  timeBlockCount,
  attributions,
  CompetitiveAttributions,
  date
) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.CompetitiveData,
        {
          TimeBlockType: timeBlockType || "Weeks",
          TargetId: 1,
          LinearId: 1,
          TimeBlockCount: timeBlockCount || 4,
          Attributions: [attributions?.id ? attributions.id : 1],
          CompetitiveAttributions: [
            CompetitiveAttributions?.id ? CompetitiveAttributions.id : 12,
          ],
          DateStart: date?.dateFrom || "",
          DateEnd: date?.dateTo || "",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({ type: "SET_COMPETITIVE_DATA", payload: response.data });
      dispatch({
        type: "SET_RENDER",
        payload: false,
      });
    } catch (err) {
      console.log("ERROR dashboard", err);
    }
  };
};
export const competitiveOptions = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.CompetitiveOptions,
        null,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: "GET_OPTIONS_COMPETITIVE",
        payload: response.data,
      });
    } catch (err) {
      console.log("ERROR dashboard", err);
    }
  };
};
