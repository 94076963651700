class Helper {
  getMaxElOfArr(arr) {
    return Math.max(...arr.map(Number));
  }
  inArray(arr, item) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].Id === item) {
        return true;
      }
    }
    return false;
  }
  abbreviateNumber(number) {
    const SI_SYMBOL = ["", "K", "M", "G", "T", "P", "E"];
    let tier = (Math.log10(Math.abs(number)) / 3) | 0;

    if (tier == 0) return number;

    let suffix = SI_SYMBOL[tier];
    let scale = Math.pow(10, tier * 3);

    let scaled = number / scale;

    return scaled.toFixed(1) + suffix;
  }
  recursiveTreeTraversal(tree, letter, path, resultArray) {
    if (resultArray === undefined) resultArray = [];

    tree.forEach((node) => {
      let currPath = [];
      if (path !== null && path?.length > 0) {
        currPath = path.slice();
      }
      currPath.push(node.key);

      if (node?.title?.toLowerCase().includes(letter?.toLowerCase())) {
        let res = {
          key: node.key,
          path: currPath,
        };
        resultArray?.push(res);
      }

      if (node.children !== null) {
        this.recursiveTreeTraversal(
          node.children,
          letter,
          currPath,
          resultArray
        );
      }
    });
    return resultArray;
  }
  checkUserRoles(roles) {
    const arr_roles = ["mmpadmin", "super_user", "advanced_user"];
    let is_admin = false;

    roles.forEach((item) => {
      if (arr_roles.indexOf(item) !== -1) {
        is_admin = true;
      }
    });
    return is_admin;
  }
}

export default new Helper();
