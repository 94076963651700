import React from "react";
import { LoadScript, GoogleMap, InfoWindow } from "@react-google-maps/api";
import apiKeys from "../../../config/apiKeys.json";
import { heatmapInit, heatmapData } from "../../../actions/heatmap.js";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./Heatmap.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: "1000px",
  },
  infoText: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "19px",
    letterSpacing: "0em",
    textAlign: "left",
  },
}));

export default function Heatmap({
  timeBlockType,
  timeBlockCount,
  attribution,
  tabName,
  currentQuintile,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();
  let data = useSelector((state) => state.planner[tabName]);

    const containerStyle = {
        height: "698px",
        width: "103%",
    };
    const [tempMap, setTempMap] = React.useState(null);
    const [infoWindow, setInfoWindow] = React.useState(null);
    const positionZero = { lat: -50, lng: -50 };
    const [nhmData, setNhmData] = React.useState(null);

  let hmData = null;
  let misdclr = "#808080";

  const populateMap = (geoJson) => {
    tempMap.data.addGeoJson(geoJson);
  };

  const getHtmlTooltip = (postcode) => {
    var res = "Postcode: " + postcode;

    if (hmData === null) return res;

    let currData = hmData.find((x) => x.Postcode === postcode);

    if (currData === null || currData === undefined) return res;

    let rating = currData.Rating;
    let viewers = currData.Viewers;
    let reach = currData.Reach;
    let cost = (viewers / 1000) * 13;
    let cprp = viewers / reach;

    res += "<br> Rating: " + rating.toFixed(2);
    // += "<br> Cost: " + cost.toFixed(2);
    res += "<br> Impressions: " + viewers;
    res += "<br> Reach: " + reach.toFixed(2);
    //res += "<br> CPRP: " + cprp.toFixed(2);

    return res;
  };

  const getHtmlTooltipN = (postcode) => {
    var res = "Postcode: " + postcode;

    if (nhmData === null) return res;

    let currData = nhmData.find((x) => x.Postcode === postcode);

    if (currData === null || currData === undefined) return res;

    let rating = currData.Rating;
    let viewers = currData.Viewers;
    let reach = currData.Reach;
    let cost = (viewers / 1000) * 13;
    let cprp = viewers / reach;

    res += "<br> Rating: " + rating.toFixed(2);
    // += "<br> Cost: " + cost.toFixed(2);
    res += "<br> Impressions: " + viewers;
    res += "<br> Reach: " + reach.toFixed(2);
    //res += "<br> CPRP: " + cprp.toFixed(2);

    return res;
  };

  const quintileToInt = (value) => {
    switch (value) {
      case "Quintile 1 (Lowest)":
        return 1;
      case "Quintile 2":
        return 2;
      case "Quintile 3":
        return 3;
      case "Quintile 4":
        return 4;
      case "Quintile 5 (Highest)":
        return 5;
      default:
        return 0;
    }
  };

  const colorizeMapQuintile = () => {
    if (
      nhmData === null ||
      nhmData === undefined ||
      tempMap === null ||
      tempMap === undefined
    )
      return;
    var quintile = quintileToInt(currentQuintile);

    tempMap.data.setStyle(function (feature) {
      let name = feature.getProperty("name");

      let currData = nhmData.find((x) => x.Postcode === name);
      if (currData === null || currData === undefined) {
        if (quintile > 0) {
          return {
            fillOpacity: 0,
            strokeWeight: 0,
          };
        } else {
          return {
            fillColor: misdclr,
            fillOpacity: 0.8,
            strokeWeight: 1,
          };
        }
      }

      if (quintile > 0) {
        if (currData.Quintile !== quintile) {
          return {
            fillOpacity: 0,
            strokeWeight: 0,
          };
        } else {
          return {
            fillColor: currData.Color,
            fillOpacity: 0.8,
            strokeWeight: 1,
          };
        }
      } else {
        return {
          fillColor: currData.Color,
          fillOpacity: 0.8,
          strokeWeight: 1,
        };
      }
    });

    tempMap.data.addListener("mouseover", function (event) {
      let name = event.feature.getProperty("name");

      let currData = nhmData.find((x) => x.Postcode === name);

      if (quintile > 0) {
        if (
          currData !== null &&
          currData !== undefined &&
          currData.Quintile === quintile
        ) {
          let html = getHtmlTooltipN(name);

          let coords = event.latLng.toJSON();

          infoWindow.setContent(html);
          infoWindow.setPosition({ lat: coords.lat + 0.1, lng: coords.lng });
        } else {
          infoWindow.setContent("");
          infoWindow.setPosition(positionZero);
        }
      } else {
        let html = getHtmlTooltipN(name);

        let coords = event.latLng.toJSON();

        infoWindow.setContent(html);
        infoWindow.setPosition({ lat: coords.lat + 0.1, lng: coords.lng });
      }
    });
  };

  const colorizeMap = (heatmapData, colors, missedColor, bank) => {
    hmData = heatmapData;
    misdclr = missedColor;

    var quintile = quintileToInt(currentQuintile);

    if (hmData === null || hmData === undefined) return;

    setNhmData(heatmapData);

    tempMap.data.setStyle(function (feature) {
      let name = feature.getProperty("name");

      let currData = heatmapData.find((x) => x.Postcode === name);
      if (currData === null || currData === undefined) {
        if (quintile > 0) {
          return {
            fillOpacity: 0,
            strokeWeight: 0,
          };
        } else {
          return {
            fillColor: missedColor,
            fillOpacity: 0.8,
            strokeWeight: 1,
          };
        }
      }

      if (quintile > 0) {
        if (currData.Quintile !== quintile) {
          return {
            fillOpacity: 0,
            strokeWeight: 0,
          };
        } else {
          return {
            fillColor: currData.Color,
            fillOpacity: 0.8,
            strokeWeight: 1,
          };
        }
      } else {
        return {
          fillColor: currData.Color,
          fillOpacity: 0.8,
          strokeWeight: 1,
        };
      }
    });

    tempMap.data.addListener("mouseover", function (event) {
      let name = event.feature.getProperty("name");

      let currData = heatmapData.find((x) => x.Postcode === name);

      if (quintile > 0) {
        if (
          currData !== null &&
          currData !== undefined &&
          currData.Quintile === quintile
        ) {
          let html = getHtmlTooltip(name);

          let coords = event.latLng.toJSON();

          infoWindow.setContent(html);
          infoWindow.setPosition({ lat: coords.lat + 0.1, lng: coords.lng });
        } else {
          infoWindow.setContent("");
          infoWindow.setPosition(positionZero);
        }
      } else {
        let html = getHtmlTooltip(name);

        let coords = event.latLng.toJSON();

        infoWindow.setContent(html);
        infoWindow.setPosition({ lat: coords.lat + 0.1, lng: coords.lng });
      }
    });

    tempMap.data.addListener("mouseout", function () {
      infoWindow.setContent("");
      infoWindow.setPosition(positionZero);
    });
  };
  React.useEffect(() => {
    if (tempMap !== null && infoWindow !== null) {
      heatmapInit(populateMap);
    }
  }, [tempMap, infoWindow]);
  React.useEffect(() => {
    if (
      tempMap !== null &&
      infoWindow !== null &&
      attribution !== null &&
      attribution !== undefined &&
      data.length !== 0
    ) {
      dispatch(
        heatmapData(colorizeMap, timeBlockType, timeBlockCount, attribution.id)
      );
    }
  }, [data, tempMap, infoWindow]);
  React.useEffect(() => {
    colorizeMapQuintile();
  }, [currentQuintile]);
  return (
    <LoadScript googleMapsApiKey={apiKeys.googleMapsApiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        id="map"
        onLoad={setTempMap}
        center={{
          lat: 39.809734,
          lng: -98.55562,
        }}
        zoom={5}
      >
        <InfoWindow
          position={positionZero}
          onLoad={setInfoWindow}
          options={{ disableAutoPan: true }}
        >
          <div className={classes.infoText}>123</div>
        </InfoWindow>
      </GoogleMap>
    </LoadScript>
  );
}
