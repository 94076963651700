import React, { useEffect } from "react";
import Authorization from "./components/Authorization/Authorization.js";
import Registration from "./components/Registration/Registation.js";
import NotFound from "./components/NoFound/NotFound.js";
import MainPage from "./components/MainPage/MainPage.js";
import Panel from "./components/MainPage/Panel/Panel.js";
import Content from "./components/MainPage/Content/Content.js";
import PlanPerfomance from "./components/PlanPerfomance/PlanPerfomance.js";
import Ads from "./components/PlanPerfomance/Ads.js";
import Channels from "./components/PlanPerfomance/Channels.js";
import DayTime from "./components/PlanPerfomance/DayTime.js";
import BuyOptimizer from "./components/BuyOptimizer/pages/brands/BuyOptimizer.js";
import InventoryPage from "./components/BuyOptimizer/pages/inventory/InventoryPage.js";
import BusinessRulesPage from "./components/BuyOptimizer/pages/business/BusinessRulesPage.js";
import MetricBuilder from "./components/MetricBuilder/MetricBuilder.js";
import CrosstabAnalyzer from "./components/CrosstabAnalyzer/CrosstabAnalyzer.js";
import DataStory from "./components/DataStory/DataStory.js";
import RfAnalyzer from "./components/RfAnalyzer/RfAnalyzer.js";
import ReportBuilder from "./components/ReportBuilder/ReportBuilder.js";
import Retargeting from "./components/Retargeting/Retargeting.js";
import Dashboard from "./components/Dashboard/Dashboard.js";
import CompetitiveOverview from "./components/Dashboard/CompetitiveOverview.js";
import Settings from "./components/Settings/Settings.js";
import Privacy from "./components/MainPage/Privacy/Privacy.js";
import Admin from "./components/Admin/pages/users/Admin.js";
import PermissionsPage from "./components/Admin/pages/permissions/PermissionsPage";
import PermissionsEditPage from "./components/Admin/pages/permissionsEdit/PermissionsEditPage.js";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { auth } from "./actions/user.js";
import Helper from "./helpers/helper.js";
import routes from "./config/routes.json";

function App() {
  const isAuth = useSelector((state) => state.user.isAuth);
  const roles = useSelector((state) => state.user.currentUser.Roles);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(auth());
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        {isAuth ? (
          <MainPage>
            <Panel />
            <Content>
              <Switch>
                <Route
                  exact
                  path={routes.PlanPerfomance}
                  render={() => <PlanPerfomance />}
                />
                {/* <Route exact path={routes.Ads} render={() => <Ads />} /> */}
                <Route
                  exact
                  path={routes.Channels}
                  render={() => <Channels />}
                />
                <Route
                  exact
                  path={routes.Dayandtime}
                  render={() => <DayTime />}
                />
                <Route
                  exact
                  path={routes.BuyOptimizer}
                  render={() => <BuyOptimizer />}
                />
                <Route
                  exact
                  path={routes.Inventory}
                  render={() => <InventoryPage />}
                />
                <Route
                  exact
                  path={routes.BusinessRules}
                  render={() => <BusinessRulesPage />}
                />
                <Route
                  exact
                  path={routes.MetricBuilder}
                  render={() => <MetricBuilder />}
                />
                <Route
                  exact
                  path={routes.CrosstabAnalyzer}
                  render={() => <CrosstabAnalyzer />}
                />
                <Route
                  exact
                  path={routes.DataStory}
                  render={() => <DataStory />}
                />
                <Route
                  exact
                  path={routes.RfAnalyzer}
                  render={() => <RfAnalyzer />}
                />
                <Route
                  exact
                  path={routes.ReportBuilder}
                  render={() => <ReportBuilder />}
                />
                <Route
                  exact
                  path={routes.Retargeting}
                  render={() => <Retargeting />}
                />
                <Route
                  exact
                  path={routes.Dashboard}
                  render={() => <Dashboard />}
                />
                <Route
                  exact
                  path={routes.CompetitiveOverview}
                  render={() => <CompetitiveOverview />}
                />
                {Helper.checkUserRoles(roles) ? (
                  <Route exact path={routes.Admin} render={() => <Admin />} />
                ) : null}
                {Helper.checkUserRoles(roles) ? (
                  <Route
                    exact
                    path={routes.Permissions}
                    render={() => <PermissionsPage />}
                  />
                ) : null}
                {Helper.checkUserRoles(roles) ? (
                  <Route
                    exact
                    path={routes.PermissionsEdit}
                    render={() => <PermissionsEditPage />}
                  />
                ) : null}
                <Route
                  exact
                  path={routes.Settings}
                  render={() => <Settings />}
                />
                <Route exact path={routes.Privacy} render={() => <Privacy />} />
                <Route component={NotFound} />
              </Switch>
            </Content>
          </MainPage>
        ) : (
          <Switch>
            <Route
              exact
              path={routes.registration}
              render={() => <Registration />}
            />
            <Route exact path={routes.home} render={() => <Authorization />} />
            <Route exact path={routes.Privacy} render={() => <Privacy />} />
            <Route component={NotFound} />
          </Switch>
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
