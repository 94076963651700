import React from "react";
import MainPermissionsComponent from "./permissions-components/MainPermissionsComponent/MainPermissionsComponent.js";
import { useSelector, useDispatch } from "react-redux";
import {
  permissionsList,
  permissionsCreate,
  permissionsDelete,
} from "../../../../actions/admin.js";

export default function PermissionsPage({ children }) {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.admin.PermissionsList);

  //modal
  const [openNewPermissions, setOpenNewPermissions] = React.useState(false);
  const [openDeletePermissions, setOpenDeletePermissions] =
    React.useState(false);

  //accardion
  const [expandedAccordion, setExpandedAccordion] = React.useState(false);

  // input
  const [addPermissionsValue, setAddPermissionsValue] = React.useState("");
  // Alert
  const [openAlert, setOpenAlert] = React.useState(false);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  //accardion handler
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  //modal handler
  const handleOpenNewPermissions = () => {
    setOpenNewPermissions(true);
  };
  const handleCloseNewPermissions = () => {
    setOpenNewPermissions(false);
    setAddPermissionsValue("");
    setOpenAlert(false);
  };
  const handleCloseAcceptNewPermissions = () => {
    if (addPermissionsValue !== "") {
      dispatch(permissionsCreate(addPermissionsValue));
      setOpenNewPermissions(false);
      setAddPermissionsValue("");
      setOpenAlert(false);
    } else {
      setOpenAlert(true);
    }
  };
  //delete modal
  const handleCloseDeletePermissions = () => {
    setOpenDeletePermissions(false);
  };
  const handleCloseAcceptDeletePermissions = () => {
    dispatch(permissionsDelete(localStorage.getItem("rowId")));
    setOpenDeletePermissions(false);
  };

  //input handler
  const handleChangeAddInput = (e) => {
    setAddPermissionsValue(e.target.value);
  };

  //table handler
  const handleTableRowCapture = (rowId) => {
    localStorage.setItem("rowId", rowId);
  };
  const handleOpenDeletePermission = () => {
    setOpenDeletePermissions(true);
  };

  React.useEffect(() => {
    dispatch(permissionsList());
  }, []);
  return (
    <MainPermissionsComponent
      permissionsList={permissions}
      expandedAccordion={expandedAccordion}
      handleChangeAccordion={handleChangeAccordion}
      handleOpenNewPermissions={handleOpenNewPermissions}
      handleCloseNewPermissions={handleCloseNewPermissions}
      handleCloseAcceptNewPermissions={handleCloseAcceptNewPermissions}
      openNewPermissions={openNewPermissions}
      handleChangeAddInput={handleChangeAddInput}
      handleCloseAlert={handleCloseAlert}
      openAlert={openAlert}
      handleTableRowCapture={handleTableRowCapture}
      handleOpenDeletePermission={handleOpenDeletePermission}
      openDeletePermissions={openDeletePermissions}
      handleCloseDeletePermissions={handleCloseDeletePermissions}
      handleCloseAcceptDeletePermissions={handleCloseAcceptDeletePermissions}
    />
  );
}
