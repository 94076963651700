import React from "react";
import Navbar from "../../../../utils/Navbar.js";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "97%",
    minWidth: "1000px",
  },
  flex_space_container: {
    display: "flex",
    justifyContent: "space-between",
  },
  //accardion
  accadion_one: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "97%",
    borderBottomLeftRadius: "10px !important",
    borderBottomRightRadius: "10px !important",
    borderTopLeftRadius: "10px !important",
    borderTopRightRadius: "10px !important",
  },
  heading: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  // table
  table: {
    minWidth: 700,
  },
  headTableSell: {
    color: "#783CC6",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    padding: "14px 14px 7px 14px",
  },
  defaultTableSell: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1E2019",
    padding: "14px 14px 7px 14px",
  },
}));

export default function MainUsersComponent({
  users,
  expandedAccordion,
  handleChangeAccordion,
}) {
  const classes = useStyles();
  const table_row = ["Name", "Organization", "Roles", "Permissions"];

  return (
    <div>
      <Navbar />
      <Box style={{ marginTop: "-5rem" }}>
        <Accordion
          expanded={expandedAccordion === "panel1"}
          onChange={handleChangeAccordion("panel1")}
          className={classes.accadion_one}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            {!expandedAccordion ? (
              <Typography className={classes.heading}>Users List</Typography>
            ) : null}
          </AccordionSummary>
          <AccordionDetails className={classes.accadion_details}>
            <TableContainer component={Paper} style={{ boxShadow: "none" }}>
              <Table
                className={classes.table}
                aria-label="customized table"
                style={{ width: "100%" }}
              >
                <TableHead>
                  <TableRow>
                    {table_row.map((i) => (
                      <StyledTableCell align="center" key={i.toString()}>
                        {i}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((row) => (
                    <StyledTableRow key={row.Id}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.headTableSell}
                        align="center"
                      >
                        {row.Name}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        className={classes.defaultTableSell}
                        key={row.Id + Math.random()}
                      >
                        {row.Organization.Name}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        className={classes.defaultTableSell}
                        key={row.Id + Math.random()}
                      >
                        {row.Roles.join()}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        className={classes.defaultTableSell}
                        key={row.Id + Math.random()}
                      >
                        {row.Permissions}
                        {"coming soon"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
}
