const SET_USERS_LIST = "SET_USERS_LIST";
const SET_PERMISSIONS_LIST = "SET_PERMISSIONS_LIST";

const defaultState = {
  UsersList: [],
  PermissionsList: [],
};

export default function adminReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_USERS_LIST:
      return {
        ...state,
        UsersList: [...action.payload.Users],
      };
    case SET_PERMISSIONS_LIST:
      return {
        ...state,
        PermissionsList: [...action.payload.Permissions],
      };
    default:
      return state;
  }
}
