import axios from "axios";
import endpoints from "../config/endpoind.json";

export const login = (email, password) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(endpoints.apiUrl + endpoints.Login, {
        email,
        password,
      });
      if (response.data.Errors.length === 0) {
        dispatch({ type: "SET_USER", payload: response.data });
        localStorage.setItem("token", response.data.AccessToken);
        localStorage.setItem("RefreshToken", response.data.RefreshToken);
      } else {
        dispatch({ type: "LOGIN_REQ_ERR", payload: response.data.Errors });
      }
      // dispatch(setUserActionCreator("response.statusText"))
    } catch (err) {
      console.log("ERROR login", err.response);
    }
  };
};
export const auth = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.Auth,
        { RefreshToken: localStorage.getItem("RefreshToken") },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      localStorage.setItem("token", response.data.AccessToken);
      localStorage.setItem("RefreshToken", response.data.RefreshToken);
      dispatch({ type: "SET_USER_AUTH", payload: response.data });
    } catch (err) {
      console.log("ERROR auth", err.response);
      localStorage.removeItem("token");
      localStorage.removeItem("RefreshToken");
      dispatch({ type: "SESSION_END" });
    }
  };
};
export const TestGet = async () => {
  try {
    const response = await axios.get(endpoints.apiUrl + endpoints.Test, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    console.log("Success auth", response);
  } catch (err) {
    console.log("ERROR auth", err);
  }
};
