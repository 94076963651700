const SET_BUDGET = "SET_BUDGET";
const SET_TIME = "SET_TIME";
const SET_HEAT_MAP_ATTRIBUTION = "SET_HEAT_MAP_ATTRIBUTION";

const defaultState = {
  Budgets: [],
  Time: [],
  Attributions: [],
};

export default function retargetingReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_BUDGET:
      return {
        ...state,
        Budgets: [...state.Budgets, ...action.payload],
      };
    case SET_TIME:
      return {
        ...state,
        Time: [...state.Time, ...action.payload],
      };
    case SET_HEAT_MAP_ATTRIBUTION:
      return {
        ...state,
        Attributions: [...action.payload],
      };
    default:
      return state;
  }
}
