const SET_BRANDS_TREE_DATA = "SET_BRANDS_TREE_DATA";
const SET_BRANDS_SEARCH_TREE_DATA = "SET_BRANDS_SEARCH_TREE_DATA";

const defaultState = {
  BrandsTreeData: [],
  BrandsAllCampaignIds: [],
  SearchTree: [],
};

export default function buyoptimizerReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_BRANDS_TREE_DATA:
      return {
        ...state,
        BrandsTreeData: [...action.payload.fancyTreeData],
        BrandsAllCampaignIds: [...action.payload.allCampaignIds],
      };
    case SET_BRANDS_SEARCH_TREE_DATA:
      return {
        ...state,
        SearchTree: [...action.payload],
      };
    default:
      return state;
  }
}
