import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
export default class Example extends PureComponent {
  render() {
    const data = [];
    let position = null;
    for (let i = 0; i < this.props.sortMode.length; i++) {
      if (this.props.sortMode[i] === " ") {
        position = i;
        break;
      }
    }
    //Cumulative Reach/rating/avg
    const parameter = this.props.sortMode
      .slice(position + 1, this.props.sortMode.length)
      .replace(/\s+/g, "");
    //Adv1/2/All
    const parameter_group = this.props.sortMode.slice(0, position);
    for (let i = 0; i < this.props.channelAmount; i++) {
      if (this.props.Adv1Performance.length !== 0) {
        data.push({
          name: this.props.Adv1Performance[i].Name,
          [`Adv1 ${parameter}`]: this.props.Adv1Performance[i][parameter],
          [`Adv2 ${parameter}`]: this.props.Adv2Performance[i][parameter],
          [`All ${parameter}`]: this.props.AllPerformance[i][parameter],
          amt: 2100,
        });
      }
    }
    if (this.props.sort) {
      data.sort((a, b) =>
        a[`${parameter_group} ${parameter}`] >
        b[`${parameter_group} ${parameter}`]
          ? -1
          : 1
      );
    }

    const CustomTooltip = ({ active, payload, label }) => {
      let firstIndex = 0;
      let secondIndex = this.props.campaingBar ? 1 : 0;
      let metricName = toMetricName(parameter);

      if (active && payload && payload.length) {
        return (
          <div
            style={{
              background: "white",
              padding: "15px",
              borderRadius: "10px",
              border: "1px solid #783CC6",
            }}
          >
            <b> {`${label}`}</b>
            {this.props.campaingBar ? (
              <p
                style={{ color: "#00AAF4" }}
              >{`${this.props.adv1Name} ${metricName}: ${payload[firstIndex].value}`}</p>
            ) : null}
            {this.props.competitiveBar ? (
              <p
                style={{ color: "#FB58A4" }}
              >{`${this.props.adv2Name} ${metricName}: ${payload[secondIndex].value}`}</p>
            ) : null}
          </div>
        );
      }

      return null;
    };

    const toMetricName = (value) => {
      switch (value) {
        case "CumulativeRatings":
          return "Cumulative Ratings";
        case "CumulativeReach":
          return "Cumulative Reach";
        case "AvgRatings":
          return "Average Ratings";
        default:
          return "Metric";
      }
    };

    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={100}
          height={100}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid stroke="rgba(120, 60, 198, 0.2)" vertical={false} />
          <XAxis dataKey="name" axisLine={false} tickLine={false} />
          <YAxis axisLine={false} tickLine={false} />
          <Tooltip content={<CustomTooltip />} />
          {this.props.campaingBar ? (
            <Bar
              dataKey={`Adv1 ${parameter}`}
              fill="#00AAF4"
              radius={[10, 10, 0, 0]}
            />
          ) : null}
          {this.props.competitiveBar ? (
            <Bar
              dataKey={`Adv2 ${parameter}`}
              fill="#FB58A4"
              radius={[10, 10, 0, 0]}
            />
          ) : null}
          {this.props.allInventoryBar ? (
            <Bar
              dataKey={`All ${parameter}`}
              fill="#B8E561"
              radius={[10, 10, 0, 0]}
            />
          ) : null}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
