import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  //modal
  modal_new_campaign: {
    width: "70%",
    height: "254px",
    position: "absolute",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    // display: "flex",
  },
  modal_new_campaign_header_input: {
    background: "#E9D9FF",
    opacity: "0.6",
    border: "0.5px solid #783CC6",
    boxSizing: "border-box",
    borderRadius: "10px",
    width: "60.81%",
    height: "51px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#1E2019",
    outline: "none",
    paddingLeft: "13px",
  },
  modal_new_campaign_header_text: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  modal_new_campaign_accept_btn: {
    background: "#783CC6",
    borderRadius: "10px",
    width: "154px",
    height: "41px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#FFFFFF",
    marginRight: "30px",
    paddingLeft: "45px",
    paddingTop: "7px",
    paddingBottom: "7px",
    cursor: "pointer",
  },
  modal_new_campaign_cancel_btn: {
    background: "#F2E8FF",
    borderRadius: "10px",
    width: "154px",
    height: "41px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1E2019",
    marginRight: "30px",
    paddingLeft: "45px",
    paddingTop: "7px",
    paddingBottom: "7px",
    cursor: "pointer",
  },
}));

export default function PermissionsEditDeleteModal({
  text,
  openDeletePermissions,
  handleCloseAcceptDeletePermissions,
  handleCloseDeletePermissions,
}) {
  const classes = useStyles();

  const bodyNewCampaign = (
    <div
      style={{
        height: "254px",
        top: "37%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        outline: "none",
      }}
      className={classes.modal_new_campaign}
    >
      <div
        style={{
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            marginTop: "60px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          className={classes.modal_new_campaign_header_text}
        >
          You are going to delete your {text}. Are you sure?
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "20px",
        }}
      >
        <div
          className={classes.modal_new_campaign_accept_btn}
          onClick={handleCloseAcceptDeletePermissions}
        >
          Accept
        </div>
        <div
          className={classes.modal_new_campaign_cancel_btn}
          onClick={handleCloseDeletePermissions}
        >
          Cancel
        </div>
      </div>
    </div>
  );
  return (
    <Modal
      open={openDeletePermissions}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {bodyNewCampaign}
    </Modal>
  );
}
