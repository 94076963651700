const SET_DATA_DASHBOARD = "SET_DATA_DASHBOARD";
const GET_OPTIONS_DASHBOARD = "GET_OPTIONS_DASHBOARD";
const GET_OPTIONS_COMPETITIVE = "GET_OPTIONS_COMPETITIVE";
const SET_COMPETITIVE_DATA = "SET_COMPETITIVE_DATA";
const SET_DATE_TO_DASHBOARD = "SET_DATE_TO_DASHBOARD";
const SET_DATE_TO_COMPETITIVE = "SET_DATE_TO_COMPETITIVE";

const defaultState = {
  Data: [],
  CompetitiveData: {
    Data: [],
    CompetitiveData: [],
  },
  OptionsV2: [],
  OptionsV2Competitive: [],
  DateTo: "",
  DateToCompetitive: "",
  Attributions: [],
  dateOptions: [],
  dateOptionsCompetitive: [],
  TopAds: [],
};

export default function dashboardReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_DATA_DASHBOARD:
      return {
        ...state,
        Data: [...action.payload.Data],
        TopAds: [...action.payload.TopAds],
      };
    case GET_OPTIONS_DASHBOARD:
      return {
        ...state,
        Attributions: [...action.payload.Attributions],
        OptionsV2: [...action.payload.OptionsV2],
        dateOptions: [
          ...action.payload.OptionsV2.map((item) => new Date(item.Name)),
        ],
      };
    case GET_OPTIONS_COMPETITIVE:
      return {
        ...state,
        OptionsV2Competitive: [...action.payload.OptionsV2],
        dateOptionsCompetitive: [
          ...action.payload.OptionsV2.map((item) => new Date(item.Name)),
        ],
      };
    case SET_COMPETITIVE_DATA:
      return {
        ...state,
        CompetitiveData: {
          ...state.CompetitiveData,
          Data: [...action.payload.Data],
          CompetitiveData: [...action.payload.CompetitiveData],
        },
      };
    case SET_DATE_TO_DASHBOARD:
      return {
        ...state,
        DateTo: action.payload,
      };
    case SET_DATE_TO_COMPETITIVE:
      return {
        ...state,
        DateToCompetitive: action.payload,
      };
    default:
      return state;
  }
}
