import axios from "axios";
import endpoints from "../config/endpoind.json";

export const heatmapInit = async (populateMap) => {
  try {
    const response = await axios.post(
      endpoints.apiUrl + endpoints.HeatMapInit,
      {},
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    );
    const data = JSON.parse(response.data.GeoJson);
    populateMap(data);
  } catch (err) {
    console.log("ERROR planner", err);
  }
};
export const heatmapData = (
  colorizeMap,
  timeBlockType,
  timeBlockCount,
  attributionId
) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.HeatMapData,
        {
          TimeBlockType: timeBlockType || "Days",
          TargetId: 1,
          LinearId: 1,
          TimeBlockCount: timeBlockCount || 28,
          Attributions: [attributionId],
          DateStart: "",
          DateEnd: "",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      const data = response.data.Data;
      const colors = response.data.QuintilesColors;
      const missedColor = response.data.MissedColor;
      const bank = response.data.Bank;
      const payload = {
        QuintilesColors: [...colors],
        Legend: [...response.data.Legend],
      };
      colorizeMap(data, colors, missedColor, bank);
      dispatch({ type: "MAP_DATA", payload: payload });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
