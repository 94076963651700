import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Helper from "../../../helpers/helper.js";

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload, gradus } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#1E2019"
          fontFamily="Open Sans"
          fontStyle="normal"
          fontWeight="300"
          transform={`rotate(${gradus})`}
          fontSize="14px"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

export default function Chart({
  data1,
  competitiveData,
  competitiveRating,
  twoLines,
  rating,
  lineColor,
  nameLine,
  nameBrand,
  nameBrand2,
  competitiveNameLine,
}) {
  const data = [];
  let max = 0;
  let max_competitive = 0;
  if (twoLines) {
    for (let i = 0; i < data1[0]?.length - 2; i++) {
      data.push({
        name: data1[0][i + 2],
        [`${nameBrand} ${nameLine}`]: data1[rating][i + 2],
        [`${nameBrand2} ${competitiveNameLine}`]:
          competitiveData[competitiveRating][i + 2],
        amt: 2400,
      });
    }
  } else {
    for (let i = 0; i < data1[0]?.length - 2; i++) {
      data.push({
        name: data1[0][i + 2],
        [nameLine]: data1[rating][i + 2],
        pv: 2400,
        amt: 2400,
      });
    }
  }

  for (let i = 2; i < data1[rating]?.length; i++) {
    if (Number(data1[rating][i]) > max) {
      max = Number(data1[rating][i]);
    }
  }
  if (twoLines) {
    for (let i = 2; i < competitiveData[competitiveRating]?.length; i++) {
      if (Number(competitiveData[rating][i]) > max_competitive) {
        max_competitive = Number(competitiveData[rating][i]);
      }
    }
    if (max_competitive > max) {
      max = max_competitive;
    }
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={558}
        height={340}
        data={data}
        margin={{
          top: 50,
          bottom: 120,
        }}
      >
        <Line
          dataKey={twoLines ? `${nameBrand} ${nameLine}` : nameLine}
          stroke={lineColor}
          fill="#FFFFFF"
          strokeWidth={2}
          isAnimationActive={false}
          dot={{ stroke: "rgba(120, 60, 198, 0.5)", strokeWidth: "0.5" }}
        />
        {twoLines ? (
          <Line
            dataKey={`${nameBrand2} ${competitiveNameLine}`}
            stroke="#B8E561"
            fill="#FFFFFF"
            strokeWidth={2}
            isAnimationActive={false}
            dot={{ stroke: "rgba(120, 60, 198, 0.5)", strokeWidth: "0.5" }}
          />
        ) : null}
        <CartesianGrid stroke="rgba(120, 60, 198, 0.2)" vertical={false} />
        <XAxis
          width={40}
          dataKey="name"
          axisLine={false}
          tickLine={false}
          style={{ writingMode: "tb-rl" }}
          padding={data.length > 7 ? { right: 30 } : { right: 30, left: 30 }}
          tick={<CustomizedAxisTick gradus={data.length > 7 ? -90 : -35} />}
          interval={0}
        />
        <YAxis
          type="number"
          axisLine={false}
          tickLine={false}
          domain={[0, max]}
          tickFormatter={(value) => {
            return Helper.abbreviateNumber(value);
          }}
        />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  );
}
