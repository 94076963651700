import axios from "axios";
import endpoints from "../config/endpoind.json";

export const retargetingHeatmapInit = (populateMap) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.RetargetingHeatMapInit,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = JSON.parse(response.data.GeoJson);
      dispatch({
        type: "SET_HEAT_MAP_ATTRIBUTION",
        payload: response.data.Attributions,
      });
      populateMap(data);
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
export const retargetingHeatmapData = async (colorizeMap, attributions) => {
  try {
    const response = await axios.post(
      endpoints.apiUrl + endpoints.RetargetingHeatMapData,
      {
        TimeBlockType: "Weeks",
        TargetId: 1,
        LinearId: 1,
        TimeBlockCount: 4,
        Attributions: [attributions?.id ? attributions.id : 0],
        DateStart: "",
        DateEnd: "",
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    );

    const data = response.data.Data;
    const colors = response.data.QuintilesColors;
      const missedColor = response.data.MissedColor;
      const bank = response.data.Bank;

    colorizeMap(data, colors, missedColor, bank);
  } catch (err) {
    console.log("ERROR planner", err);
  }
};
