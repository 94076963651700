import React from "react";
import Navbar from "../../../../utils/navbar/Navbar";

export default function MainInventoryComponent({ children }) {
  return (
    <div>
      <Navbar />
      Coming soon - Inventory
    </div>
  );
}
