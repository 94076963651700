import React from "react";
import Navbar from "./utils/Navbar.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { dashboardData, dashboardOptions } from "../../actions/dashboard.js";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Chart from "./Charts/Chart.js";
import DatePickerCustom from "../PlanPerfomance/utils/DatePickerCustom.js";
import { useMediaQuery } from "@material-ui/core";
import Helper from "../../helpers/helper.js";
import styles from "./Dashboard.css";

const StyledMenu = withStyles({
  paper: {
    backgroundColor: "#E9D9FF",
    opacity: "0.6",
    border: "0.5px solid #783CC6",
    boxSizing: "border-box",
    backdropFilter: "blur(40px)",
    borderRadius: "10px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#1E2019",
    marginTop: "0",
    marginLeft: "0",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  flex_space_container: {
    display: "flex",
    justifyContent: "space-between",
  },
  flex_container: {
    display: "flex",
  },
  // little display
  flex_space_container_little_display: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  flex_left_container: {
    display: "flex",
    justifyContent: "left",
  },
  top_box: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "442px",
    minWidth: "442px",
  },
  top_box_header: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  top_box_week: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    linHeight: "25px",
    color: "#783CC6",
  },
  top_box_data: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "41px",
    color: "#783CC6",
  },
  top_box_data_description: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#000000",
  },
  // max-widh 1620px
  top_box_little_display: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "97%",
    height: "61px",
  },
  heading: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  accordion_no_shadow: {
    boxShadow: "0 0 0 rgba(120, 60, 198, 0.00)",
    background: "rgba(0, 0, 0, 0.00)",
  },
  accordion_header: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "100%",
  },
  accordion_content: {
    padding: "0px",
  },
  accordion_chart: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "32.87%",
    minWidth: "224px",
    height: "319px",
  },
  // for little display
  accordion_chart_little_display: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "45.87%",
    minWidth: "224px",
    height: "319px",
  },
  accordion_chart_data: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#783CC6",
  },
  simple_border: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
  },
  top_ads_text1: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "27px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  top_ads_text2: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "27px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#783CC6",
  },
  top_ads_text3: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  top_ads_text4: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  header_text1: {
    fontFamily: "Open Sans",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "30px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  header_text2: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "27px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  ddl_control: {
    backgroundColor: "#E9D9FF",
    opacity: "0.6",
    border: "0.5px solid #783CC6",
    boxSizing: "border-box",
    backdropFilter: "blur(40px)",
    borderRadius: "10px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#1E2019",
    width: "5.5%",
    height: "36px",
    marginLeft: "0.63%",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const isActive = useMediaQuery("(max-width: 1620px)");
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboard.Data);
  const options = useSelector((state) => state.dashboard.Attributions);
  //test
  const isRender = useSelector((state) => state.planner.Render);
  const optionsV2 = useSelector((state) => state.dashboard.OptionsV2);
  const date = {
    dateTo: useSelector((state) => state.dashboard.DateTo),
  };
  const currentOptions = optionsV2.filter(
    (item) => item.Name.split(" ")[0] === date.dateTo
  );
  const weeks = currentOptions[0]?.NextCollection.filter(
    (item) => item.Name === "Weeks"
  );
  const days = currentOptions[0]?.NextCollection.filter(
    (item) => item.Name === "Days"
  );
  const topAdsData = useSelector((state) => state.dashboard.TopAds);
  const isLeftMenuOpen = useSelector((state) => state.user.open);

  const [expanded1, setExpanded1] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);

  const handleChange1 = (panel) => (event, isExpanded) => {
    setExpanded1(isExpanded ? panel : false);
  };

  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
  };
  //Helpers---------------
  function isAbove(number1, number2) {
    return Number(number1) > Number(number2);
  }
  //Helpers---------------
  const [attributionValue, setAttributionValue] = React.useState(null);
  const [timeTypeValue, setTimeTypeValue] = React.useState(null);
  const [timeNumberValue, setTimeNumberValue] = React.useState(null);
  const [anchorTimeType, setAnchorTimeType] = React.useState(null);
  const [anchorTimeNumber, setAnchorTimeNumber] = React.useState(null);
  const [anchorAttributions, setAnchorAttributions] = React.useState(null);

  const setDefaultValue = () => {
    setTimeTypeValue("Weeks");
    setTimeNumberValue(4);
    setAttributionValue(null);
  };

  const handleClickTimeType = (event) => {
    setAnchorTimeType(event.currentTarget);
  };
  const handleClickTimeNumber = (event) => {
    setAnchorTimeNumber(event.currentTarget);
  };
  const handleClickAttributions = (event) => {
    setAnchorAttributions(event.currentTarget);
  };

  const handleCloseTimeType = (event) => {
    setAnchorTimeType(null);

    let val = event.currentTarget.dataset.myValue;
    let localStorageData = JSON.parse(
      localStorage.getItem("Common_CampaignAndDashboard_AttributionValue")
    );
    let defaultTimeNumberValueWeeks = currentOptions[0]?.NextCollection.filter(
      (item) => item.Name === "Weeks"
    )[0]?.NextCollection[0]?.Name;
    let defaultTimeNumberValueDays = currentOptions[0]?.NextCollection.filter(
      (item) => item.Name === "Days"
    )[0]?.NextCollection[0]?.Name;

    if (val !== null && val !== undefined) {
      localStorage.setItem("Common_TimeType", val);
      setTimeTypeValue(val);
      if (timeTypeValue === "Weeks") {
        localStorage.setItem("Common_TimeNumber", defaultTimeNumberValueDays);
        setTimeNumberValue(defaultTimeNumberValueDays);
      } else {
        localStorage.setItem("Common_TimeNumber", defaultTimeNumberValueWeeks);
        setTimeNumberValue(defaultTimeNumberValueWeeks);
      }
      //attribution
      if (
        Helper.inArray(
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection,
          localStorageData?.Id
        )
      ) {
        console.log("IF");
        //setAttributions ...
        setAttributionValue({
          ...attributionValue,
          id: localStorageData?.Id,
          Name: localStorageData?.Value,
        });
      } else {
        console.log("ELSE");
        localStorage.setItem(
          "Common_CampaignAndDashboard_AttributionValue",
          JSON.stringify({
            Id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]?.NextCollection.filter(
              (item) => item.Name === localStorage.getItem("Common_TimeNumber")
            )[0]?.NextCollection[0]?.Id,
            Value: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]?.NextCollection.filter(
              (item) => item.Name === localStorage.getItem("Common_TimeNumber")
            )[0]?.NextCollection[0]?.Name,
          })
        );
        setAttributionValue({
          ...attributionValue,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.Name,
        });
      }
      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  };
  const handleCloseTimeNumber = (event) => {
    setAnchorTimeNumber(null);

    let val = event.currentTarget.dataset.myValue;
    let localStorageData = JSON.parse(
      localStorage.getItem("Common_CampaignAndDashboard_AttributionValue")
    );
    if (val !== null && val !== undefined) {
      localStorage.setItem("Common_TimeNumber", val);
      setTimeNumberValue(val);
      if (
        Helper.inArray(
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === timeTypeValue
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection,
          localStorageData?.Id
        )
      ) {
        //setAttributions ...
        setAttributionValue({
          ...attributionValue,
          id: localStorageData?.Id,
          Name: localStorageData?.Value,
        });
      } else {
        localStorage.setItem(
          "Common_CampaignAndDashboard_AttributionValue",
          JSON.stringify({
            Id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeValue
            )[0]?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection[0]?.Id,
            Value: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeValue
            )[0]?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection[0]?.Name,
          })
        );
        setAttributionValue({
          ...attributionValue,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === timeTypeValue
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === timeTypeValue
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.Name,
        });
      }
      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  };
  const handleCloseAttributions = (event) => {
    setAnchorAttributions(null);

    let val = event.currentTarget.dataset.myValue;
    if (val !== null && val !== undefined && val !== "") {
      localStorage.setItem(
        "Common_CampaignAndDashboard_AttributionValue",
        JSON.stringify({ Id: event.currentTarget.id, Value: val })
      );
      setAttributionValue({
        ...attributionValue,
        id: event.currentTarget.id,
        Name: val,
      });
      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  };
  React.useEffect(() => {
    dispatch(dashboardOptions());
  }, []);
  React.useEffect(() => {
    if (
      date.dateTo !== "" &&
      attributionValue !== undefined &&
      attributionValue !== null &&
      isRender
    ) {
      dispatch(
        dashboardData(timeTypeValue, timeNumberValue, attributionValue, date)
      );
    }
  }, [attributionValue, timeTypeValue, timeNumberValue, date.dateTo, isRender]);
  React.useEffect(() => {
    if (optionsV2 !== null && optionsV2 !== undefined && optionsV2.length > 0) {
      let defaultAttributionValue =
        currentOptions[0]?.NextCollection[0]?.NextCollection[0]
          ?.NextCollection[0];
      let defaultTimeType = currentOptions[0]?.NextCollection[0]?.Name;
      let defaultTimeNumber =
        currentOptions[0]?.NextCollection[0]?.NextCollection[0]?.Name;
      let localStorageData = JSON.parse(
        localStorage.getItem("Common_CampaignAndDashboard_AttributionValue")
      );
      let timeTypeLocalStorage = localStorage.getItem("Common_TimeType");
      let timeNumberLocalStorage = localStorage.getItem("Common_TimeNumber");
      if (
        currentOptions[0]?.NextCollection.filter(
          (item) => item.Name === timeTypeLocalStorage
        ).length !== 0
      ) {
        //setTimeType ...
        setTimeTypeValue(timeTypeLocalStorage);
        if (
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === timeTypeLocalStorage
          )[0]?.NextCollection.filter(
            (item) => item.Name === timeNumberLocalStorage
          ).length !== 0
        ) {
          //setTimeNumber ...
          setTimeNumberValue(timeNumberLocalStorage);
          if (
            Helper.inArray(
              currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection,
              localStorageData?.Id
            )
          ) {
            //setAttributions ...
            setAttributionValue({
              ...attributionValue,
              id: localStorageData?.Id,
              Name: localStorageData?.Value,
            });
          } else {
            setAttributionValue({
              ...attributionValue,
              id: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.Id,
              Name: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.Name,
            });
          }
        } else {
          localStorage.setItem(
            "Common_TimeNumber",
            currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.Name
          );
          setTimeNumberValue(
            currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.Name
          );
          setAttributionValue({
            ...attributionValue,
            id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.NextCollection[0]?.Id,
            Name: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.NextCollection[0]?.Name,
          });
        }
      } else {
        //setTimeNumber ... default
        //setTimeType ... default
        localStorage.setItem("Common_TimeNumber", defaultTimeNumber);
        localStorage.setItem("Common_TimeType", defaultTimeType);
        setTimeTypeValue(defaultTimeType);
        setTimeNumberValue(defaultTimeNumber);
        setAttributionValue({
          ...attributionValue,
          id: defaultAttributionValue.Id,
          Name: defaultAttributionValue.Name,
        });
      }
      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  }, [date.dateTo]);
  return (
    <div>
      <Navbar />
      <div className={classes.root}>
        <Box>
          {isActive ? (
            <>
              <div className={classes.top_box_little_display}>
                <div
                  className={classes.flex_space_container}
                  style={{ padding: "12px 10px 0px 16px" }}
                >
                  <div className={classes.header_text1}>Filters</div>
                  <div
                    style={{ width: "312px", minWidth: "156px" }}
                    className={classes.ddl_control}
                    onClick={handleClickAttributions}
                    aria-controls="menu-attributions"
                    aria-haspopup="true"
                    variant="contained"
                  >
                    <Box style={{ padding: "4px 5px" }}>
                      {attributionValue
                        ? attributionValue.Name
                        : "Choose brand"}
                    </Box>
                    <Box style={{ padding: "4px 5px" }}>
                      <Button
                        style={{
                          width: "0px",
                          height: "0px",
                          minWidth: "0px",
                        }}
                      >
                        <img alt="arrow" src="/img/accardion/Vector9.svg" />
                      </Button>
                    </Box>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      className={classes.header_text2}
                      style={{ marginTop: "5px", width: "130px" }}
                    >
                      Trend Period:{" "}
                    </div>
                    <div
                      style={{
                        width: "92px",
                        minWidth: "92px",
                        marginLeft: "20px",
                      }}
                      className={classes.ddl_control}
                      onClick={handleClickTimeType}
                      aria-controls="menu-time-type"
                      aria-haspopup="true"
                      variant="contained"
                    >
                      <Box style={{ padding: "4px 5px" }}>
                        {timeTypeValue ? timeTypeValue : setDefaultValue()}
                      </Box>
                      <Box style={{ padding: "4px 6px" }}>
                        <Button
                          style={{
                            width: "0px",
                            height: "0px",
                            minWidth: "0px",
                          }}
                        >
                          <img alt="arrow" src="/img/accardion/Vector9.svg" />
                        </Button>
                      </Box>
                    </div>
                    <div
                      style={{
                        width: "62px",
                        minWidth: "62px",
                        marginLeft: "15px",
                      }}
                      className={classes.ddl_control}
                      onClick={handleClickTimeNumber}
                      aria-controls="menu-time-number"
                      aria-haspopup="true"
                      variant="contained"
                    >
                      <Box style={{ padding: "4px 5px" }}>
                        {timeNumberValue ? timeNumberValue : setDefaultValue()}
                      </Box>
                      <Box style={{ padding: "4px 5px" }}>
                        <Button
                          style={{
                            width: "0px",
                            height: "0px",
                            minWidth: "0px",
                          }}
                        >
                          <img alt="arrow" src="/img/accardion/Vector9.svg" />
                        </Button>
                      </Box>
                    </div>
                  </div>
                  <div className={classes.flex_left_container}>
                    <div
                      className={classes.header_text2}
                      style={{
                        marginTop: "3px",
                        marginLeft: "8px",
                        width: "220px",
                      }}
                    >
                      Trend period end date:
                    </div>
                    <div style={{ marginLeft: "12px" }}>
                      <DatePickerCustom
                        dateType={"SET_DATE_TO_DASHBOARD"}
                        reducer={"dashboard"}
                        dateArr={"dateOptions"}
                        // dateOptions={optionsV2.map(
                        //   (item) => new Date(item.Name)
                        // )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "30px",
                }}
              >
                <div className={classes.top_box}>
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "22px",
                        paddingTop: "12px",
                        paddingLeft: "16px",
                        paddingRight: "12px",
                      }}
                    >
                      <Box>
                        <Typography className={classes.top_box_header}>
                          Linear
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.top_box_week}>
                          {timeTypeValue === "Weeks" ? "week" : "day"} of{" "}
                          {data.length !== 0
                            ? data[0][data[0]?.length - 1]
                            : null}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "5px",
                        paddingLeft: "33px",
                        paddingRight: "7px",
                      }}
                    >
                      <Box style={{ display: "flex" }}>
                        <Box>
                          <Typography className={classes.top_box_data}>
                            {data.length !== 0
                              ? Helper.abbreviateNumber(
                                  Number(data[2][data[2]?.length - 1])
                                )
                              : null}
                          </Typography>
                        </Box>
                        <Box style={{ paddingTop: "10px", paddingLeft: "4%" }}>
                          {data.length !== 0 ? (
                            isAbove(
                              data[2][data[2]?.length - 1],
                              data[2][data[2]?.length - 2]
                            ) ? (
                              <img alt="arrow" src="/img/common/Arrow1.svg" />
                            ) : (
                              <img alt="arrow" src="/img/common/Arrow2.svg" />
                            )
                          ) : null}
                        </Box>
                      </Box>
                      <Box style={{ display: "flex" }}>
                        <Box>
                          <Typography className={classes.top_box_data}>
                            {data.length !== 0
                              ? Helper.abbreviateNumber(
                                  Number(data[7][data[7]?.length - 1])
                                )
                              : null}
                          </Typography>
                        </Box>
                        <Box style={{ paddingTop: "10px", paddingLeft: "4%" }}>
                          {data.length !== 0 ? (
                            isAbove(
                              data[7][data[7]?.length - 1],
                              data[7][data[7]?.length - 2]
                            ) ? (
                              <img alt="arrow" src="/img/common/Arrow1.svg" />
                            ) : (
                              <img alt="arrow" src="/img/common/Arrow2.svg" />
                            )
                          ) : null}
                        </Box>
                      </Box>
                      <Box style={{ display: "flex" }}>
                        <Box>
                          <Typography className={classes.top_box_data}>
                            {data.length !== 0
                              ? Helper.abbreviateNumber(
                                  Number(data[5][data[5]?.length - 1])
                                )
                              : null}
                          </Typography>
                        </Box>
                        <Box style={{ paddingTop: "10px", paddingLeft: "4%" }}>
                          {data.length !== 0 ? (
                            isAbove(
                              data[5][data[5]?.length - 1],
                              data[5][data[5]?.length - 2]
                            ) ? (
                              <img alt="arrow" src="/img/common/Arrow1.svg" />
                            ) : (
                              <img alt="arrow" src="/img/common/Arrow2.svg" />
                            )
                          ) : null}
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                      }}
                    >
                      <Box>
                        <Typography
                          className={classes.top_box_data_description}
                        >
                          Gross Impressions
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          className={classes.top_box_data_description}
                        >
                          Frequency
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          className={classes.top_box_data_description}
                          style={{ maxWidth: "100px", textAlign: "center" }}
                        >
                          Households Reached
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
                <div className={classes.top_box}>
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "22px",
                        paddingTop: "12px",
                        paddingLeft: "16px",
                        paddingRight: "12px",
                      }}
                    >
                      <Box>
                        <Typography className={classes.top_box_header}>
                          Non-Linear
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.top_box_week}>
                          {timeTypeValue === "Weeks" ? "week" : "day"} of{" "}
                          {data.length !== 0
                            ? data[0][data[0]?.length - 1]
                            : null}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "5px",
                        paddingLeft: "33px",
                        paddingRight: "7px",
                      }}
                    >
                      <Box style={{ display: "flex" }}>
                        <Box>
                          <Typography className={classes.top_box_data}>
                            {data.length !== 0
                              ? Helper.abbreviateNumber(
                                  Number(data[3][data[3]?.length - 1])
                                )
                              : null}
                          </Typography>
                        </Box>
                        <Box style={{ paddingTop: "10px", paddingLeft: "4%" }}>
                          {data.length !== 0 ? (
                            isAbove(
                              data[3][data[3]?.length - 1],
                              data[3][data[3]?.length - 2]
                            ) ? (
                              <img alt="arrow" src="/img/common/Arrow1.svg" />
                            ) : (
                              <img alt="arrow" src="/img/common/Arrow2.svg" />
                            )
                          ) : null}
                        </Box>
                      </Box>
                      <Box style={{ display: "flex" }}>
                        <Box>
                          <Typography className={classes.top_box_data}>
                            {data.length !== 0
                              ? Helper.abbreviateNumber(
                                  Number(data[9][data[9]?.length - 1])
                                )
                              : null}
                          </Typography>
                        </Box>
                        <Box style={{ paddingTop: "10px", paddingLeft: "4%" }}>
                          {data.length !== 0 ? (
                            isAbove(
                              data[9][data[9]?.length - 1],
                              data[9][data[9]?.length - 2]
                            ) ? (
                              <img alt="arrow" src="/img/common/Arrow1.svg" />
                            ) : (
                              <img alt="arrow" src="/img/common/Arrow2.svg" />
                            )
                          ) : null}
                        </Box>
                      </Box>
                      <Box style={{ display: "flex" }}>
                        <Box>
                          <Typography className={classes.top_box_data}>
                            {data.length !== 0
                              ? Helper.abbreviateNumber(
                                  Number(data[6][data[6]?.length - 1])
                                )
                              : null}
                          </Typography>
                        </Box>
                        <Box style={{ paddingTop: "10px", paddingLeft: "4%" }}>
                          {data.length !== 0 ? (
                            isAbove(
                              data[6][data[6]?.length - 1],
                              data[6][data[6]?.length - 2]
                            ) ? (
                              <img alt="arrow" src="/img/common/Arrow1.svg" />
                            ) : (
                              <img alt="arrow" src="/img/common/Arrow2.svg" />
                            )
                          ) : null}
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                      }}
                    >
                      <Box>
                        <Typography
                          className={classes.top_box_data_description}
                        >
                          Gross Impressions
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          className={classes.top_box_data_description}
                        >
                          Frequency
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          className={classes.top_box_data_description}
                          style={{ maxWidth: "100px", textAlign: "center" }}
                        >
                          Households Reached
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </div>
            </>
          ) : (
            <div
              className={classes.flex_space_container}
              style={{ width: "97%", height: "155px" }}
            >
              <div className={classes.top_box}>
                <Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "22px",
                      paddingTop: "12px",
                      paddingLeft: "16px",
                      paddingRight: "12px",
                    }}
                  >
                    <Box>
                      <Typography className={classes.top_box_header}>
                        Linear
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.top_box_week}>
                        {timeTypeValue === "Weeks" ? "week" : "day"} of{" "}
                        {data.length !== 0
                          ? data[0][data[0]?.length - 1]
                          : null}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                      paddingLeft: "33px",
                      paddingRight: "7px",
                    }}
                  >
                    <Box style={{ display: "flex" }}>
                      <Box>
                        <Typography className={classes.top_box_data}>
                          {data.length !== 0
                            ? Helper.abbreviateNumber(
                                Number(data[2][data[2]?.length - 1])
                              )
                            : null}
                        </Typography>
                      </Box>
                      <Box style={{ paddingTop: "10px", paddingLeft: "4%" }}>
                        {data.length !== 0 ? (
                          isAbove(
                            data[2][data[2]?.length - 1],
                            data[2][data[2]?.length - 2]
                          ) ? (
                            <img alt="arrow" src="/img/common/Arrow1.svg" />
                          ) : (
                            <img alt="arrow" src="/img/common/Arrow2.svg" />
                          )
                        ) : null}
                      </Box>
                    </Box>
                    <Box style={{ display: "flex" }}>
                      <Box>
                        <Typography className={classes.top_box_data}>
                          {data.length !== 0
                            ? Helper.abbreviateNumber(
                                Number(data[7][data[7]?.length - 1])
                              )
                            : null}
                        </Typography>
                      </Box>
                      <Box style={{ paddingTop: "10px", paddingLeft: "4%" }}>
                        {data.length !== 0 ? (
                          isAbove(
                            data[7][data[7]?.length - 1],
                            data[7][data[7]?.length - 2]
                          ) ? (
                            <img alt="arrow" src="/img/common/Arrow1.svg" />
                          ) : (
                            <img alt="arrow" src="/img/common/Arrow2.svg" />
                          )
                        ) : null}
                      </Box>
                    </Box>
                    <Box style={{ display: "flex" }}>
                      <Box>
                        <Typography className={classes.top_box_data}>
                          {data.length !== 0
                            ? Helper.abbreviateNumber(
                                Number(data[5][data[5]?.length - 1])
                              )
                            : null}
                        </Typography>
                      </Box>
                      <Box style={{ paddingTop: "10px", paddingLeft: "4%" }}>
                        {data.length !== 0 ? (
                          isAbove(
                            data[5][data[5]?.length - 1],
                            data[5][data[5]?.length - 2]
                          ) ? (
                            <img alt="arrow" src="/img/common/Arrow1.svg" />
                          ) : (
                            <img alt="arrow" src="/img/common/Arrow2.svg" />
                          )
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }}
                  >
                    <Box>
                      <Typography className={classes.top_box_data_description}>
                        Gross Impressions
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.top_box_data_description}>
                        Frequency
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        className={classes.top_box_data_description}
                        style={{ maxWidth: "100px", textAlign: "center" }}
                      >
                        Households Reached
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </div>
              <div className={classes.top_box}>
                <Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "22px",
                      paddingTop: "12px",
                      paddingLeft: "16px",
                      paddingRight: "12px",
                    }}
                  >
                    <Box>
                      <Typography className={classes.top_box_header}>
                        Non-Linear
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.top_box_week}>
                        {timeTypeValue === "Weeks" ? "week" : "day"} of{" "}
                        {data.length !== 0
                          ? data[0][data[0]?.length - 1]
                          : null}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                      paddingLeft: "33px",
                      paddingRight: "7px",
                    }}
                  >
                    <Box style={{ display: "flex" }}>
                      <Box>
                        <Typography className={classes.top_box_data}>
                          {data.length !== 0
                            ? Helper.abbreviateNumber(
                                Number(data[3][data[3]?.length - 1])
                              )
                            : null}
                        </Typography>
                      </Box>
                      <Box style={{ paddingTop: "10px", paddingLeft: "4%" }}>
                        {data.length !== 0 ? (
                          isAbove(
                            data[3][data[3]?.length - 1],
                            data[3][data[3]?.length - 2]
                          ) ? (
                            <img alt="arrow" src="/img/common/Arrow1.svg" />
                          ) : (
                            <img alt="arrow" src="/img/common/Arrow2.svg" />
                          )
                        ) : null}
                      </Box>
                    </Box>
                    <Box style={{ display: "flex" }}>
                      <Box>
                        <Typography className={classes.top_box_data}>
                          {data.length !== 0
                            ? Helper.abbreviateNumber(
                                Number(data[9][data[9]?.length - 1])
                              )
                            : null}
                        </Typography>
                      </Box>
                      <Box style={{ paddingTop: "10px", paddingLeft: "4%" }}>
                        {data.length !== 0 ? (
                          isAbove(
                            data[9][data[9]?.length - 1],
                            data[9][data[9]?.length - 2]
                          ) ? (
                            <img alt="arrow" src="/img/common/Arrow1.svg" />
                          ) : (
                            <img alt="arrow" src="/img/common/Arrow2.svg" />
                          )
                        ) : null}
                      </Box>
                    </Box>
                    <Box style={{ display: "flex" }}>
                      <Box>
                        <Typography className={classes.top_box_data}>
                          {data.length !== 0
                            ? Helper.abbreviateNumber(
                                Number(data[6][data[6]?.length - 1])
                              )
                            : null}
                        </Typography>
                      </Box>
                      <Box style={{ paddingTop: "10px", paddingLeft: "4%" }}>
                        {data.length !== 0 ? (
                          isAbove(
                            data[6][data[6]?.length - 1],
                            data[6][data[6]?.length - 2]
                          ) ? (
                            <img alt="arrow" src="/img/common/Arrow1.svg" />
                          ) : (
                            <img alt="arrow" src="/img/common/Arrow2.svg" />
                          )
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }}
                  >
                    <Box>
                      <Typography className={classes.top_box_data_description}>
                        Gross Impressions
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.top_box_data_description}>
                        Frequency
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        className={classes.top_box_data_description}
                        style={{ maxWidth: "100px", textAlign: "center" }}
                      >
                        Households Reached
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </div>
              <div className={classes.top_box}>
                <div
                  className={classes.flex_space_container}
                  style={{ padding: "12px 10px 0px 16px" }}
                >
                  <div className={classes.header_text1}>Filters</div>
                  <div
                    style={{ width: "312px", minWidth: "156px" }}
                    className={classes.ddl_control}
                    onClick={handleClickAttributions}
                    aria-controls="menu-attributions"
                    aria-haspopup="true"
                    variant="contained"
                  >
                    <Box style={{ padding: "4px 5px" }}>
                      {attributionValue
                        ? attributionValue.Name
                        : "Choose brand"}
                    </Box>
                    <Box style={{ padding: "4px 5px" }}>
                      <Button
                        style={{ width: "0px", height: "0px", minWidth: "0px" }}
                      >
                        <img alt="arrow" src="/img/accardion/Vector9.svg" />
                      </Button>
                    </Box>
                  </div>
                </div>
                <div
                  className={classes.flex_left_container}
                  style={{ padding: "13px 11px 0px 11px" }}
                >
                  <div
                    className={classes.header_text2}
                    style={{ marginTop: "5px" }}
                  >
                    Trend Period:{" "}
                  </div>
                  <div
                    style={{
                      width: "92px",
                      minWidth: "92px",
                      marginLeft: "20px",
                    }}
                    className={classes.ddl_control}
                    onClick={handleClickTimeType}
                    aria-controls="menu-time-type"
                    aria-haspopup="true"
                    variant="contained"
                  >
                    <Box style={{ padding: "4px 5px" }}>
                      {timeTypeValue ? timeTypeValue : setDefaultValue()}
                    </Box>
                    <Box style={{ padding: "4px 6px" }}>
                      <Button
                        style={{ width: "0px", height: "0px", minWidth: "0px" }}
                      >
                        <img alt="arrow" src="/img/accardion/Vector9.svg" />
                      </Button>
                    </Box>
                  </div>
                  <div
                    style={{
                      width: "62px",
                      minWidth: "62px",
                      marginLeft: "15px",
                    }}
                    className={classes.ddl_control}
                    onClick={handleClickTimeNumber}
                    aria-controls="menu-time-number"
                    aria-haspopup="true"
                    variant="contained"
                  >
                    <Box style={{ padding: "4px 5px" }}>
                      {timeNumberValue ? timeNumberValue : setDefaultValue()}
                    </Box>
                    <Box style={{ padding: "4px 5px" }}>
                      <Button
                        style={{ width: "0px", height: "0px", minWidth: "0px" }}
                      >
                        <img alt="arrow" src="/img/accardion/Vector9.svg" />
                      </Button>
                    </Box>
                  </div>
                </div>
                <div
                  className={classes.flex_left_container}
                  style={{ padding: "13px 11px 0px 11px" }}
                >
                  <div
                    className={classes.header_text2}
                    style={{ marginTop: "3px" }}
                  >
                    Trend period end date:
                  </div>
                  <div style={{ marginLeft: "12px" }}>
                    <DatePickerCustom
                      dateType={"SET_DATE_TO_DASHBOARD"}
                      reducer={"dashboard"}
                      dateArr={"dateOptions"}
                      // dateOptions={optionsV2.map((item) => new Date(item.Name))}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            style={
              isActive
                ? { width: "97%", marginTop: "38px" }
                : { width: "97%", marginTop: "30px" }
            }
          >
            <Accordion
              expanded={expanded1 === "panel1"}
              onChange={handleChange1("panel1")}
              className={classes.accordion_no_shadow}
            >
              <div
                style={{ height: "59px" }}
                className={classes.accordion_header}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1dbrd-content"
                  id="panel1dbrd-header"
                >
                  <Typography className={classes.heading}>
                    Linear Trend
                  </Typography>
                </AccordionSummary>
              </div>
              <AccordionDetails className={classes.accordion_content}>
                <div
                  style={
                    isActive && isLeftMenuOpen
                      ? { height: "1000px", width: "100%", marginTop: "7px" }
                      : isActive
                      ? { height: "642px", width: "100%", marginTop: "7px" }
                      : { height: "319px", width: "100%", marginTop: "7px" }
                  }
                  className={
                    isActive
                      ? classes.flex_space_container_little_display
                      : classes.flex_space_container
                  }
                >
                  <div
                    className={
                      isActive
                        ? classes.accordion_chart_little_display
                        : classes.accordion_chart
                    }
                    style={isActive && isLeftMenuOpen ? { width: "51%" } : {}}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ paddingLeft: "14px", paddingTop: "15px" }}
                        >
                          <Typography className={classes.top_box_header}>
                            Gross Impressions
                          </Typography>
                        </div>
                        <div
                          style={{ paddingRight: "25px", paddingTop: "16px" }}
                        >
                          <Typography className={classes.accordion_chart_data}>
                            {data.length !== 0
                              ? Helper.abbreviateNumber(
                                  Number(data[2][data[2]?.length - 1])
                                )
                              : null}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div style={{ paddingRight: "16px" }}>
                          <Typography
                            className={classes.top_box_data_description}
                          >
                            last {timeTypeValue === "Weeks" ? "week" : "day"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Chart
                      data1={data}
                      twoLines={false}
                      nameLine={"Gross Impressions"}
                      rating={2}
                      lineColor={"#00AAF4"}
                    />
                  </div>
                  <div
                    className={
                      isActive
                        ? classes.accordion_chart_little_display
                        : classes.accordion_chart
                    }
                    style={isActive && isLeftMenuOpen ? { width: "51%" } : {}}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ paddingLeft: "14px", paddingTop: "15px" }}
                        >
                          <Typography className={classes.top_box_header}>
                            Frequency
                          </Typography>
                        </div>
                        <div
                          style={{ paddingRight: "25px", paddingTop: "16px" }}
                        >
                          <Typography className={classes.accordion_chart_data}>
                            {data.length !== 0
                              ? Helper.abbreviateNumber(
                                  Number(data[7][data[7]?.length - 1])
                                )
                              : null}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div style={{ paddingRight: "16px" }}>
                          <Typography
                            className={classes.top_box_data_description}
                          >
                            last {timeTypeValue === "Weeks" ? "week" : "day"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Chart
                      data1={data}
                      twoLines={false}
                      nameLine={"Frequency"}
                      rating={7}
                      lineColor={"#FB58A4"}
                    />
                  </div>
                  <div
                    className={
                      isActive
                        ? classes.accordion_chart_little_display
                        : classes.accordion_chart
                    }
                    style={isActive && isLeftMenuOpen ? { width: "51%" } : {}}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ paddingLeft: "14px", paddingTop: "15px" }}
                        >
                          <Typography className={classes.top_box_header}>
                            Households Reached
                          </Typography>
                        </div>
                        <div
                          style={{ paddingRight: "25px", paddingTop: "16px" }}
                        >
                          <Typography className={classes.accordion_chart_data}>
                            {data.length !== 0
                              ? Helper.abbreviateNumber(
                                  Number(data[5][data[5]?.length - 1])
                                )
                              : null}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div style={{ paddingRight: "16px" }}>
                          <Typography
                            className={classes.top_box_data_description}
                          >
                            last {timeTypeValue === "Weeks" ? "week" : "day"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Chart
                      data1={data}
                      twoLines={false}
                      nameLine={"Households Reached"}
                      rating={5}
                      lineColor={"#B8E561"}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div
            style={
              isActive
                ? { width: "97%", marginTop: "38px" }
                : { width: "97%", marginTop: "30px" }
            }
          >
            <Accordion
              expanded={expanded2 === "panel2"}
              onChange={handleChange2("panel2")}
              className={classes.accordion_no_shadow}
            >
              <div
                style={{ height: "59px" }}
                className={classes.accordion_header}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2dbrd-content"
                  id="panel2dbrd-header"
                >
                  <Typography className={classes.heading}>
                    Non-Linear Trend
                  </Typography>
                </AccordionSummary>
              </div>
              <AccordionDetails className={classes.accordion_content}>
                <div
                  style={
                    isActive && isLeftMenuOpen
                      ? { height: "1000px", width: "100%", marginTop: "7px" }
                      : isActive
                      ? { height: "642px", width: "100%", marginTop: "7px" }
                      : { height: "319px", width: "100%", marginTop: "7px" }
                  }
                  className={
                    isActive
                      ? classes.flex_space_container_little_display
                      : classes.flex_space_container
                  }
                >
                  <div
                    className={
                      isActive
                        ? classes.accordion_chart_little_display
                        : classes.accordion_chart
                    }
                    style={isActive && isLeftMenuOpen ? { width: "51%" } : {}}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ paddingLeft: "14px", paddingTop: "15px" }}
                        >
                          <Typography className={classes.top_box_header}>
                            Gross Impressions
                          </Typography>
                        </div>
                        <div
                          style={{ paddingRight: "25px", paddingTop: "16px" }}
                        >
                          <Typography className={classes.accordion_chart_data}>
                            {data.length !== 0
                              ? Helper.abbreviateNumber(
                                  Number(data[3][data[3]?.length - 1])
                                )
                              : null}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div style={{ paddingRight: "16px" }}>
                          <Typography
                            className={classes.top_box_data_description}
                          >
                            last {timeTypeValue === "Weeks" ? "week" : "day"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Chart
                      data1={data}
                      twoLines={false}
                      nameLine={"Gross Impressions"}
                      rating={3}
                      lineColor={"#00AAF4"}
                    />
                  </div>
                  <div
                    className={
                      isActive
                        ? classes.accordion_chart_little_display
                        : classes.accordion_chart
                    }
                    style={isActive && isLeftMenuOpen ? { width: "51%" } : {}}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ paddingLeft: "14px", paddingTop: "15px" }}
                        >
                          <Typography className={classes.top_box_header}>
                            Frequency
                          </Typography>
                        </div>
                        <div
                          style={{ paddingRight: "25px", paddingTop: "16px" }}
                        >
                          <Typography className={classes.accordion_chart_data}>
                            {data.length !== 0
                              ? Helper.abbreviateNumber(
                                  Number(data[9][data[9]?.length - 1])
                                )
                              : null}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div style={{ paddingRight: "16px" }}>
                          <Typography
                            className={classes.top_box_data_description}
                          >
                            last {timeTypeValue === "Weeks" ? "week" : "day"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Chart
                      data1={data}
                      twoLines={false}
                      nameLine={"Frequency"}
                      rating={9}
                      lineColor={"#FB58A4"}
                    />
                  </div>
                  <div
                    className={
                      isActive
                        ? classes.accordion_chart_little_display
                        : classes.accordion_chart
                    }
                    style={isActive && isLeftMenuOpen ? { width: "51%" } : {}}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ paddingLeft: "14px", paddingTop: "15px" }}
                        >
                          <Typography className={classes.top_box_header}>
                            Households Reached
                          </Typography>
                        </div>
                        <div
                          style={{ paddingRight: "25px", paddingTop: "16px" }}
                        >
                          <Typography className={classes.accordion_chart_data}>
                            {data.length !== 0
                              ? Helper.abbreviateNumber(
                                  Number(data[6][data[6]?.length - 1])
                                )
                              : null}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div style={{ paddingRight: "16px" }}>
                          <Typography
                            className={classes.top_box_data_description}
                          >
                            last {timeTypeValue === "Weeks" ? "week" : "day"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Chart
                      data1={data}
                      twoLines={false}
                      nameLine={"Households Reached"}
                      rating={6}
                      lineColor={"#B8E561"}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            style={
              isActive
                ? { width: "97%", marginTop: "30px", minHeight: "550px" }
                : { width: "97%", marginTop: "30px", minHeight: "447px" }
            }
            className={classes.simple_border}
          >
            <div className={classes.heading} style={{ padding: "11px 18px" }}>
              Top Ads
            </div>
            <div
              style={{ marginTop: "0px", padding: "0px 18px" }}
              className={
                topAdsData.length < 4
                  ? classes.flex_container
                  : classes.flex_space_container
              }
            >
              {topAdsData.map((item) => (
                <div
                  key={Number(item.HouseholdsReached) + Math.random()}
                  style={
                    topAdsData.length < 4
                      ? { width: "16.87%", marginRight: "50px" }
                      : { width: "16.87%" }
                  }
                >
                  <div style={{ width: "100%", height: "172px" }}>
                    <video width="100%" height="100%" controls>
                      <source src={item.Url} type="video/mp4" />
                    </video>
                    <div
                      className={classes.flex_left_container}
                      style={{ marginTop: "5px" }}
                    >
                      <div className={classes.top_ads_text1}>
                        {" "}
                        {item.NameOfAd}
                      </div>
                    </div>
                    <div
                      className={classes.flex_left_container}
                      style={{ marginTop: "5px" }}
                    >
                      <div className={classes.top_ads_text2}>
                        {Helper.abbreviateNumber(Number(item.Views))} Views
                      </div>
                    </div>
                    <div
                      className={classes.flex_space_container}
                      style={{ marginTop: "7px" }}
                    >
                      <div className={classes.top_ads_text3}>
                        Households reached
                      </div>
                      <div className={classes.top_ads_text4}>
                        {Helper.abbreviateNumber(
                          Number(item.HouseholdsReached)
                        )}
                      </div>
                    </div>
                    <div
                      className={classes.flex_space_container}
                      style={{ marginTop: "5px" }}
                    >
                      <div className={classes.top_ads_text3}>Frequency</div>
                      <div className={classes.top_ads_text4}>
                        {item.Frequency}
                      </div>
                    </div>
                    <div
                      className={classes.flex_space_container}
                      style={{ marginTop: "5px" }}
                    >
                      <div className={classes.top_ads_text3}>Product</div>
                      <div className={classes.top_ads_text4}>
                        {item.Product}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Box>
      </div>
      <StyledMenu
        id="menu-time-type"
        anchorEl={anchorTimeType}
        keepMounted
        open={Boolean(anchorTimeType)}
        onClose={handleCloseTimeType}
        PaperProps={{
          style: {
            width: "92px",
          },
        }}
      >
        {timeTypeValue === "Weeks"
          ? days?.map((item) => (
              <MenuItem
                onClick={handleCloseTimeType}
                data-my-value={item.Name}
                key={item.Id}
              >
                {item.Name}
              </MenuItem>
            ))
          : weeks?.map((item) => (
              <MenuItem
                onClick={handleCloseTimeType}
                data-my-value={item.Name}
                key={item.Id}
              >
                {item.Name}
              </MenuItem>
            ))}
      </StyledMenu>
      <StyledMenu
        id="menu-time-number"
        anchorEl={anchorTimeNumber}
        keepMounted
        open={Boolean(anchorTimeNumber)}
        onClose={handleCloseTimeNumber}
        PaperProps={{
          style: {
            width: "62px",
          },
        }}
      >
        {timeTypeValue === "Weeks"
          ? weeks !== undefined
            ? weeks[0]?.NextCollection.map((item) => (
                <MenuItem
                  onClick={handleCloseTimeNumber}
                  data-my-value={item.Name}
                  key={item.Id}
                >
                  {" "}
                  {item.Name}{" "}
                </MenuItem>
              ))
            : null
          : days !== undefined
          ? days[0]?.NextCollection.map((item) => (
              <MenuItem
                onClick={handleCloseTimeNumber}
                data-my-value={item.Name}
                key={item.Id}
              >
                {" "}
                {item.Name}{" "}
              </MenuItem>
            ))
          : null}
      </StyledMenu>
      <StyledMenu
        id="menu-attributions"
        anchorEl={anchorAttributions}
        keepMounted
        open={Boolean(anchorAttributions)}
        onClose={handleCloseAttributions}
        PaperProps={{
          style: isActive
            ? { width: "21.5%" }
            : {
                width: "16.2%",
              },
        }}
      >
        {timeTypeValue === "Weeks"
          ? weeks !== undefined
            ? weeks[0]?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]?.NextCollection.map((item) => (
                <MenuItem
                  onClick={handleCloseAttributions}
                  id={item.Id}
                  data-my-value={item.Name}
                  key={item.Id}
                >
                  {item.Name}
                </MenuItem>
              ))
            : null
          : days !== undefined
          ? days[0]?.NextCollection.filter(
              (item) => item.Name === localStorage.getItem("Common_TimeNumber")
            )[0]?.NextCollection.map((item) => (
              <MenuItem
                onClick={handleCloseAttributions}
                id={item.Id}
                data-my-value={item.Name}
                key={item.Id}
              >
                {item.Name}
              </MenuItem>
            ))
          : null}
      </StyledMenu>
    </div>
  );
}
