import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Navbar from "../../../../utils/navbar/Navbar";
import SearchInput from "../SearchInput/SearchInput";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Typography from "@material-ui/core/Typography";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import styles from "./MainBrandsComponent.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "97%",
    minWidth: "1000px",
  },
  downlod_text: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    textDecorationLine: "underline",
    whiteSpace: "nowrap",
    color: "#1E2019",
    cursor: "pointer",
  },
  green_box: {
    width: "25px",
    height: "25px",
    borderRadius: "5px",
    background: "#B8E561",
  },
  red_box: {
    width: "25px",
    height: "25px",
    borderRadius: "5px",
    background: "#FB58A4",
  },
  national_local_text: {
    fontFamily: "Open Sans",
    fontWtyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    whiteSpace: "nowrap",
    color: "#783CC6",
  },
  flex_space_container: {
    display: "flex",
    justifyContent: "space-between",
  },
  //accardion
  accadion_one: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "97%",
    borderBottomLeftRadius: "10px !important",
    borderBottomRightRadius: "10px !important",
    borderTopLeftRadius: "10px !important",
    borderTopRightRadius: "10px !important",
  },
  heading: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  // tree
  tree_item_text: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "27px",
    whiteSpace: "nowrap",
    color: "#1E2019",
  },
  icons_menu_items: {
    marginLeft: "30px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
  },
  hr_line: {
    height: "66px",
    padding: "13px",
    display: "flex",
    borderTop: "1px solid rgba(120, 60, 198, 0.2)",
  },
  tree_item_btn: {
    borderRadius: "10px",
    width: "154px",
    height: "41px",
    background: "#F2E8FF",
    marginLeft: "29px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
  },
  tree_item_head_btn: {
    borderRadius: "10px",
    width: "154px",
    height: "41px",
    background: "#783CC6",
    marginLeft: "auto",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
  },
}));

export default function MainBrandsComponent({
  treeData,
  searchData,
  expandedAccordion,
  handleChangeAccordion,
  expandedTree,
  selectedTreeNode,
  handleExpandClick,
  handleCollapseClick,
  handleSelectTree,
  handleToggleTree,
  handleChangheSearchInput,
}) {
  const classes = useStyles();

  return (
    <div>
      <Navbar />
      <div className={classes.root} style={{ marginTop: "-4rem" }}>
        <div className={classes.flex_space_container}>
          <div
            className={classes.flex_space_container}
            style={{ width: "277px" }}
          >
            <div style={{ display: "flex" }}>
              <div>
                <img alt="expand" src="/img/accardion/expand1.svg" />
              </div>
              <div className={classes.downlod_text} onClick={handleExpandClick}>
                Expand all
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <img alt="collapse" src="/img/accardion/collapse1.svg" />
              </div>
              <div
                className={classes.downlod_text}
                onClick={handleCollapseClick}
              >
                Collapse all
              </div>
            </div>
          </div>
          <div
            className={classes.flex_space_container}
            style={{ width: "446px" }}
          >
            <div
              className={classes.flex_space_container}
              style={{ width: "232px" }}
            >
              <div className={classes.green_box}></div>
              <div className={classes.national_local_text}>
                National campaign
              </div>
            </div>
            <div
              className={classes.flex_space_container}
              style={{ width: "196px" }}
            >
              <div className={classes.red_box}></div>
              <div className={classes.national_local_text}>Local campaign</div>
            </div>
          </div>
          <div style={{ width: "27%" }}>
            <SearchInput handleChange={handleChangheSearchInput} />
          </div>
        </div>
      </div>
      {/* Accardion with Tree */}
      <Box style={{ marginTop: "2rem" }}>
        <Accordion
          expanded={expandedAccordion === "panel1"}
          onChange={handleChangeAccordion("panel1")}
          className={classes.accadion_one}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panelOpt1bh-content"
            id="panelOpt1bh-header"
          >
            {!expandedAccordion ? (
              <Typography className={classes.heading}>
                Name of agency
              </Typography>
            ) : null}
          </AccordionSummary>
          <AccordionDetails className={classes.accadion_details}>
            <div
              style={{
                height: "66px",
                padding: "13px",
                display: "flex",
              }}
            >
              <div
                style={{
                  marginTop: "7px",
                  marginLeft: "10px",
                  fontWeight: "bold",
                }}
                className={classes.tree_item_text}
              >
                Name of agency
              </div>
            </div>
            <TreeView
              aria-label="controlled"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expandedTree}
              selected={selectedTreeNode}
              onNodeToggle={handleToggleTree}
              onNodeSelect={handleSelectTree}
              multiSelect
            >
              {searchData.length === 0
                ? treeData.map((item) => (
                    <TreeItem
                      nodeId={item.key}
                      key={item.key}
                      label={
                        <div className={classes.hr_line}>
                          <div
                            style={{
                              marginTop: "7px",
                              marginLeft: "10px",
                              fontWeight: "bold",
                            }}
                            className={classes.tree_item_text}
                          >
                            {item.title}
                          </div>
                          {item.nationalAllowed ? (
                            <div className={classes.tree_item_head_btn}>
                              <div
                                style={{
                                  textAlign: "center",
                                  color: "#FFFFFF",
                                }}
                                className={classes.tree_item_text}
                              >
                                Add National
                              </div>
                            </div>
                          ) : null}
                          {item.localAllowed ? (
                            <div
                              style={{
                                marginLeft: item.nationalAllowed
                                  ? "15px"
                                  : "auto",
                              }}
                              className={classes.tree_item_head_btn}
                            >
                              <div
                                style={{
                                  textAlign: "center",
                                  color: "#FFFFFF",
                                }}
                                className={classes.tree_item_text}
                              >
                                Add Local
                              </div>
                            </div>
                          ) : null}
                        </div>
                      }
                    >
                      {item?.children !== null
                        ? item?.children.map((childItem) => (
                            <TreeItem
                              nodeId={childItem.key}
                              key={childItem.key}
                              label={
                                <div className={classes.hr_line}>
                                  <div
                                    className={
                                      childItem.isLocal
                                        ? classes.red_box
                                        : classes.green_box
                                    }
                                    style={{ marginTop: "8px" }}
                                  ></div>
                                  <div
                                    style={{
                                      marginTop: "7px",
                                      marginLeft: "10px",
                                    }}
                                    className={classes.tree_item_text}
                                  >
                                    {childItem.title}
                                  </div>
                                  {!childItem.isLocal ? (
                                    <div className={classes.tree_item_btn}>
                                      <div
                                        style={{ textAlign: "center" }}
                                        className={classes.tree_item_text}
                                      >
                                        Make active
                                      </div>
                                    </div>
                                  ) : (
                                    <div className={classes.tree_item_btn}>
                                      <div
                                        style={{ textAlign: "center" }}
                                        className={classes.tree_item_text}
                                      >
                                        Copy to DMAs
                                      </div>
                                    </div>
                                  )}

                                  {!childItem.isLocal ? (
                                    <div
                                      style={{
                                        marginLeft: "10px",
                                      }}
                                      className={classes.tree_item_btn}
                                    >
                                      <div
                                        style={{ textAlign: "center" }}
                                        className={classes.tree_item_text}
                                      >
                                        Add Local
                                      </div>
                                    </div>
                                  ) : null}

                                  <div
                                    style={{
                                      marginLeft: "auto",
                                    }}
                                    className={classes.icons_menu_items}
                                  >
                                    <img
                                      alt="trash"
                                      src="/img/optimizer/trash1.svg"
                                    />
                                  </div>
                                  <div className={classes.icons_menu_items}>
                                    <img
                                      alt="file"
                                      src="/img/optimizer/file_1.svg"
                                    />
                                  </div>
                                  <div className={classes.icons_menu_items}>
                                    <img
                                      alt="edit"
                                      src="/img/optimizer/edit1.svg"
                                    />
                                  </div>
                                  <div className={classes.icons_menu_items}>
                                    <img
                                      alt="stats"
                                      src="/img/optimizer/statistics1.svg"
                                    />
                                  </div>
                                </div>
                              }
                            >
                              {childItem?.children !== null
                                ? childItem?.children.map((childChildItem) => (
                                    <TreeItem
                                      nodeId={childChildItem.key}
                                      key={childChildItem.key}
                                      label={
                                        <div className={classes.hr_line}>
                                          <div
                                            className={
                                              childChildItem.isLocal
                                                ? classes.red_box
                                                : classes.green_box
                                            }
                                            style={{ marginTop: "8px" }}
                                          ></div>
                                          <div
                                            style={{
                                              marginTop: "7px",
                                              marginLeft: "10px",
                                            }}
                                            className={classes.tree_item_text}
                                          >
                                            {childChildItem.title}
                                          </div>
                                          <div
                                            className={classes.tree_item_btn}
                                          >
                                            <div
                                              style={{ textAlign: "center" }}
                                              className={classes.tree_item_text}
                                            >
                                              Copy to DMAs
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              marginLeft: "auto",
                                            }}
                                            className={classes.icons_menu_items}
                                          >
                                            <img
                                              alt="trash"
                                              src="/img/optimizer/trash1.svg"
                                            />
                                          </div>
                                          <div
                                            className={classes.icons_menu_items}
                                          >
                                            <img
                                              alt="file"
                                              src="/img/optimizer/file_1.svg"
                                            />
                                          </div>
                                          <div
                                            className={classes.icons_menu_items}
                                          >
                                            <img
                                              alt="edit"
                                              src="/img/optimizer/edit1.svg"
                                            />
                                          </div>
                                          <div
                                            className={classes.icons_menu_items}
                                          >
                                            <img
                                              alt="stats"
                                              src="/img/optimizer/statistics1.svg"
                                            />
                                          </div>
                                        </div>
                                      }
                                    />
                                  ))
                                : null}
                            </TreeItem>
                          ))
                        : null}
                    </TreeItem>
                  ))
                : treeData.map((item) =>
                    searchData.indexOf(item.key) !== -1 ? (
                      <TreeItem
                        nodeId={item.key}
                        key={item.key}
                        label={
                          <div className={classes.hr_line}>
                            <div
                              style={{
                                marginTop: "7px",
                                marginLeft: "10px",
                                fontWeight: "bold",
                              }}
                              className={classes.tree_item_text}
                            >
                              {item.title}
                            </div>
                            {item.nationalAllowed ? (
                              <div className={classes.tree_item_head_btn}>
                                <div
                                  style={{
                                    textAlign: "center",
                                    color: "#FFFFFF",
                                  }}
                                  className={classes.tree_item_text}
                                >
                                  Add National
                                </div>
                              </div>
                            ) : null}
                            {item.localAllowed ? (
                              <div
                                style={{
                                  marginLeft: item.nationalAllowed
                                    ? "15px"
                                    : "auto",
                                }}
                                className={classes.tree_item_head_btn}
                              >
                                <div
                                  style={{
                                    textAlign: "center",
                                    color: "#FFFFFF",
                                  }}
                                  className={classes.tree_item_text}
                                >
                                  Add Local
                                </div>
                              </div>
                            ) : null}
                          </div>
                        }
                      >
                        {item?.children !== null
                          ? item?.children.map((childItem) =>
                              searchData.indexOf(childItem.key) !== -1 ? (
                                <TreeItem
                                  nodeId={childItem.key}
                                  key={childItem.key}
                                  label={
                                    <div className={classes.hr_line}>
                                      <div
                                        className={
                                          childItem.isLocal
                                            ? classes.red_box
                                            : classes.green_box
                                        }
                                        style={{ marginTop: "8px" }}
                                      ></div>
                                      <div
                                        style={{
                                          marginTop: "7px",
                                          marginLeft: "10px",
                                        }}
                                        className={classes.tree_item_text}
                                      >
                                        {childItem.title}
                                      </div>
                                      {!childItem.isLocal ? (
                                        <div className={classes.tree_item_btn}>
                                          <div
                                            style={{ textAlign: "center" }}
                                            className={classes.tree_item_text}
                                          >
                                            Make active
                                          </div>
                                        </div>
                                      ) : (
                                        <div className={classes.tree_item_btn}>
                                          <div
                                            style={{ textAlign: "center" }}
                                            className={classes.tree_item_text}
                                          >
                                            Copy to DMAs
                                          </div>
                                        </div>
                                      )}

                                      {!childItem.isLocal ? (
                                        <div
                                          style={{
                                            marginLeft: "10px",
                                          }}
                                          className={classes.tree_item_btn}
                                        >
                                          <div
                                            style={{ textAlign: "center" }}
                                            className={classes.tree_item_text}
                                          >
                                            Add Local
                                          </div>
                                        </div>
                                      ) : null}

                                      <div
                                        style={{
                                          marginLeft: "auto",
                                        }}
                                        className={classes.icons_menu_items}
                                      >
                                        <img
                                          alt="trash"
                                          src="/img/optimizer/trash1.svg"
                                        />
                                      </div>
                                      <div className={classes.icons_menu_items}>
                                        <img
                                          alt="file"
                                          src="/img/optimizer/file_1.svg"
                                        />
                                      </div>
                                      <div className={classes.icons_menu_items}>
                                        <img
                                          alt="edit"
                                          src="/img/optimizer/edit1.svg"
                                        />
                                      </div>
                                      <div className={classes.icons_menu_items}>
                                        <img
                                          alt="stats"
                                          src="/img/optimizer/statistics1.svg"
                                        />
                                      </div>
                                    </div>
                                  }
                                >
                                  {childItem?.children !== null
                                    ? childItem?.children.map(
                                        (childChildItem) =>
                                          searchData.indexOf(
                                            childChildItem.key
                                          ) !== -1 ? (
                                            <TreeItem
                                              nodeId={childChildItem.key}
                                              key={childChildItem.key}
                                              label={
                                                <div
                                                  className={classes.hr_line}
                                                >
                                                  <div
                                                    className={
                                                      childChildItem.isLocal
                                                        ? classes.red_box
                                                        : classes.green_box
                                                    }
                                                    style={{ marginTop: "8px" }}
                                                  ></div>
                                                  <div
                                                    style={{
                                                      marginTop: "7px",
                                                      marginLeft: "10px",
                                                    }}
                                                    className={
                                                      classes.tree_item_text
                                                    }
                                                  >
                                                    {childChildItem.title}
                                                  </div>
                                                  <div
                                                    className={
                                                      classes.tree_item_btn
                                                    }
                                                  >
                                                    <div
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                      className={
                                                        classes.tree_item_text
                                                      }
                                                    >
                                                      Copy to DMAs
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      marginLeft: "auto",
                                                    }}
                                                    className={
                                                      classes.icons_menu_items
                                                    }
                                                  >
                                                    <img
                                                      alt="trash"
                                                      src="/img/optimizer/trash1.svg"
                                                    />
                                                  </div>
                                                  <div
                                                    className={
                                                      classes.icons_menu_items
                                                    }
                                                  >
                                                    <img
                                                      alt="file"
                                                      src="/img/optimizer/file_1.svg"
                                                    />
                                                  </div>
                                                  <div
                                                    className={
                                                      classes.icons_menu_items
                                                    }
                                                  >
                                                    <img
                                                      alt="edit"
                                                      src="/img/optimizer/edit1.svg"
                                                    />
                                                  </div>
                                                  <div
                                                    className={
                                                      classes.icons_menu_items
                                                    }
                                                  >
                                                    <img
                                                      alt="stats"
                                                      src="/img/optimizer/statistics1.svg"
                                                    />
                                                  </div>
                                                </div>
                                              }
                                            />
                                          ) : null
                                      )
                                    : null}
                                </TreeItem>
                              ) : null
                            )
                          : null}
                      </TreeItem>
                    ) : null
                  )}
            </TreeView>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
}
