import React from "react";
import Navbar from "./utils/Navbar.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./Retargeting.css";
import { useDispatch, useSelector } from "react-redux";
import Heatmap from "./utils/Heatmap.js";
import { useMediaQuery } from "@material-ui/core";

const StyledMenu = withStyles({
  paper: {
    backgroundColor: "#E9D9FF",
    opacity: "0.6",
    border: "0.5px solid #783CC6",
    boxSizing: "border-box",
    backdropFilter: "blur(40px)",
    borderRadius: "10px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#1E2019",
    marginTop: "0",
    marginLeft: "0",
    height: "100px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  box: {
    backgroundColor: "#E9D9FF",
    opacity: "0.6",
    border: "0.5px solid #783CC6",
    boxSizing: "border-box",
    backdropFilter: "blur(40px)",
    borderRadius: "10px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#1E2019",
    width: "5.5%",
    height: "36px",
    marginLeft: "0.63%",
  },
  check_box: {
    backgroundColor: "#E9D9FF",
    opacity: "0.6",
    border: "0.5px solid #783CC6",
    boxSizing: "border-box",
    backdropFilter: "blur(40px)",
    borderRadius: "5px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#1E2019",
    width: "30px",
    height: "30px",
    marginLeft: "0.63%",
    marginRight: "0",
  },
  check_box_icon: {
    paddingTop: "7px",
    paddingLeft: "4px",
    paddingRight: "4px",
    paddingBottom: "6px",
  },
  my_campaigns_border: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "97%",
    paddingBottom: "12px",
  },
  my_campaigns_header: {
    padding: "15px 17px 19px 17px",
    fontFamily: "Open Sans",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "30px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  my_campaigns_row: {
    width: "100%",
    height: "46px",
    display: "flex",
    justifyContent: "space-between",
  },
  row_header: {
    padding: "9px 17px 9px 17px",
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "27px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  row_buttons_container: {
    width: "130px",
    padding: "7px 0px 8px 0px",
    marginRight: "21px",
    display: "flex",
    justifyContent: "space-between",
  },
  row_line: {
    height: "1px",
    background: "rgba(120, 60, 198, 0.1)",
    margin: "0px 19px 0px 19px",
  },
  row_button: {
    width: "30px",
    height: "30px",
  },
  new_campaign_border: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "97%",
    height: "59px",
    marginBottom: "30px",
    marginTop: "30px",
  },
  new_campaign_header: {
    paddingTop: "13px",
    paddingLeft: "21px",
    fontFamily: "Open Sans",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "30px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  flex_container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  btn_create_campaign: {
    width: "191px",
    height: "41px",
    background: "#783CC6",
    borderRadius: "10px",
    marginTop: "8px",
    marginRight: "26px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#FFFFFF",
    borderWidth: "0px",
    cursor: "pointer",
  },
  accordion_one: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "97%",
    borderBottomLeftRadius: "10px !important",
    borderBottomRightRadius: "10px !important",
    borderTopLeftRadius: "10px !important",
    borderTopRightRadius: "10px !important",
    marginTop: "7px !important",
  },
  heading: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  accordion_details: {
    paddingTop: "0px",
    height: "53px",
  },
  accordion_header1: {
    fontFamily: "Open Sans",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "30px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  accordion_text: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "27px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  flex1: {
    display: "flex",
    justifyContent: "space-between",
    width: "404px",
    marginLeft: "40px",
  },
  flex2: {
    display: "flex",
    justifyContent: "space-between",
    width: "328px",
    marginLeft: "30px",
  },
  modal_time: {
    width: "884px",
    height: "450px",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    display: "flex",
  },
  modal_budget: {
    width: "884px",
    height: "406px",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    display: "flex",
  },
  modal_new_campaign: {
    width: "88%",
    height: "383px",
    position: "absolute",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    // display: "flex",
  },
  modal_new_campaign_header_text: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  modal_new_campaign_header_input: {
    background: "#E9D9FF",
    opacity: "0.6",
    border: "0.5px solid #783CC6",
    boxSizing: "border-box",
    borderRadius: "10px",
    width: "605px",
    height: "51px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#1E2019",
    outline: "none",
    paddingLeft: "13px",
  },
  modal_new_campaign_accept_btn: {
    background: "#783CC6",
    borderRadius: "10px",
    width: "154px",
    height: "41px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#FFFFFF",
    marginRight: "30px",
    paddingLeft: "45px",
    paddingTop: "7px",
    paddingBottom: "7px",
    cursor: "pointer",
  },
  modal_new_campaign_cancel_btn: {
    background: "#F2E8FF",
    borderRadius: "10px",
    width: "154px",
    height: "41px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1E2019",
    marginRight: "30px",
    paddingLeft: "45px",
    paddingTop: "7px",
    paddingBottom: "7px",
    cursor: "pointer",
  },
  time_left_bar: {
    width: "258px",
    height: "362px",
    padding: "16px 30px",
  },
  time_right_bar: {
    width: "283px",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    marginLeft: "auto",
  },
  budget_right_bar: {
    width: "349px",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    marginLeft: "auto",
  },
  budget_left_bar: {
    width: "222px",
    height: "336px",
    padding: "16px 0px 16px 30px",
  },
  check_box_wrapper: {
    width: "100%",
    marginBottom: "20px",
    height: "30px",
    display: "flex",
  },
  modal_violet_text: {
    fontFamily: "Open Sans",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "30px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#783CC6",
  },
  modal_violet_text2: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "27px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#783CC6",
  },
  flex3: {
    display: "flex",
    justifyContent: "space-between",
    width: "278px",
    marginTop: "16px",
  },
  flex4: {
    display: "flex",
    justifyContent: "left",
    marginTop: "12px",
  },
  modal_text: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "27px",
    letterSpacing: "0em",
  },
  modal_text2: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "27px",
    letterSpacing: "0em",
  },
  modal_text3: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "27px",
    letterSpacing: "0em",
  },
  modal_hr: {
    background: "rgba(120, 60, 198, 0.1)",
    height: "1px",
  },
  btn_accept: {
    height: "41px",
    background: "#783CC6",
    borderRadius: "10px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#FFFFFF",
    borderWidth: "0px",
    cursor: "pointer",
  },
  btn_cancel: {
    height: "41px",
    background: "#F2E8FF",
    borderRadius: "10px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1E2019",
    borderWidth: "0px",
    cursor: "pointer",
  },
  default_input: {
    paddingLeft: "20px",
    paddingTop: "6px",
    paddingRight: "20px",
    width: "88px",
    paddingBottom: "5px",
    outline: "none",
  },
}));

export default function Retargeting() {
  const classes = useStyles();
  const isActive = useMediaQuery("(max-width: 1620px)");

  const [expanded, setExpanded] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [openTime, setOpenTime] = React.useState(false);
  const [openBudget, setOpenBudget] = React.useState(false);
  const [openNewCampaign, setOpenNewCampaign] = React.useState(false);
  const [maxcpm, setMaxCpm] = React.useState("");
  const [dailybudget, setDailyBudget] = React.useState("");
  const [timeFrom, setTimeFrom] = React.useState("");
  const [timeTo, setTimeTo] = React.useState("");
  const [anchorAttributions, setAnchorAttributions] = React.useState(null);
  const [attributionValue, setAttributionValue] = React.useState(null);

  const dispatch = useDispatch();
  const state = useSelector((state) => state.retargeting.Budgets);
  const time = useSelector((state) => state.retargeting.Time);
  const attributions = useSelector((state) => state.retargeting.Attributions);
  //budgets-------------
  const [google, setGoogle] = React.useState({
    Name: "Google Search",
    check: false,
  });
  const [facebook, setFacebook] = React.useState({
    Name: "Facebook",
    check: false,
  });
  const [youtube, setYoutube] = React.useState({
    Name: "YouTube",
    check: false,
  });
  const [snapchat, setSnapchat] = React.useState({
    Name: "SnapChat",
    check: false,
  });
  const [instagram, setInstagram] = React.useState({
    Name: "Instagram",
    check: false,
  });
  const [pinterest, setPinterest] = React.useState({
    Name: "Pinterest",
    check: false,
  });
  //budgets-------------
  //time----------------
  const [monday, setMonday] = React.useState({
    Name: "Monday",
    check: false,
  });
  const [tuesday, setTuesday] = React.useState({
    Name: "Tuesday",
    check: false,
  });
  const [wednesday, setWednesday] = React.useState({
    Name: "Wednesday",
    check: false,
  });
  const [thursday, setThursday] = React.useState({
    Name: "Thursday",
    check: false,
  });
  const [friday, setFriday] = React.useState({
    Name: "Friday",
    check: false,
  });
  const [saturday, setSaturday] = React.useState({
    Name: "Saturday",
    check: false,
  });
  const [sunday, setSunday] = React.useState({
    Name: "Sunday",
    check: false,
  });
  //time------------------
  const selectedBudget = [];
  const budgetNames = [
    google,
    facebook,
    youtube,
    snapchat,
    instagram,
    pinterest,
  ];

  let timeStr = "";
  const selectedTime = [];
  const timeNames = [
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
  ];
  const handleBudget = () => {
    if (maxcpm === "") {
      alert("Fill in the fields!");
    } else {
      for (let i = 0; i < budgetNames.length; i++) {
        if (budgetNames[i].check) {
          selectedBudget.push({
            name: budgetNames[i].Name,
            cpm: maxcpm,
            daily: dailybudget,
          });
        }
      }
      setMaxCpm("");
      setDailyBudget("");
      dispatch({ type: "SET_BUDGET", payload: selectedBudget });
    }
  };
  const handleTime = () => {
    let pointer = null;
    if (timeFrom === "") {
      alert("Fill in the fields!");
    } else {
      for (let i = 0; i < timeNames.length; i++) {
        if (timeNames[i].check) {
          timeStr = timeNames[i].Name;
          pointer = i;
          break;
        }
      }
      for (let i = pointer + 1; i < timeNames.length; i++) {
        if (!timeNames[i].check) {
          if (timeNames[i - 1].Name !== timeNames[pointer].Name) {
            timeStr += `-${timeNames[i - 1].Name}`;
          }
          pointer = i;
          break;
        } else if (timeNames.length - 1 === i) {
          timeStr += `-${timeNames[i].Name}`;
          pointer = i;
        }
      }
      for (let i = pointer + 1; i < timeNames.length; i++) {
        if (timeNames[i].check) {
          timeStr += `,${timeNames[i].Name}`;
        }
      }
      selectedTime.push({ day: timeStr, timeFrom: timeFrom, timeTo: timeTo });
      dispatch({ type: "SET_TIME", payload: selectedTime });
    }
  };

  const handleCloseAttributions = (event) => {
    setAnchorAttributions(null);

    let val = event.currentTarget.dataset.myValue;
    if (val !== null && val !== undefined)
      setAttributionValue({
        ...attributionValue,
        id: event.currentTarget.id,
        Name: val,
      });
  };
  const handleClickAttributions = (event) => {
    setAnchorAttributions(event.currentTarget);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
  };
  const handleOpenTime = () => {
    setOpenTime(true);
  };
  const handleCloseTime = () => {
    setOpenTime(false);
  };
  const handleOpenBudget = () => {
    setOpenBudget(true);
  };
  const handleCloseBudget = () => {
    setOpenBudget(false);
  };
  const handleOpenNewCampaign = () => {
    setOpenNewCampaign(true);
  };
  const handleCloseNewCampaign = () => {
    setOpenNewCampaign(false);
  };

  const bodyTimeModal = (
    <div
      style={{
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        outline: "none",
      }}
      className={classes.modal_time}
    >
      <div style={{ width: "601px", height: "100%" }}>
        <div style={{ width: "100%", height: "386px", display: "flex" }}>
          <div className={classes.time_left_bar}>
            <div className={classes.check_box_wrapper}>
              <Box
                className={classes.check_box}
                onClick={() => setMonday({ ...monday, check: !monday.check })}
              >
                {monday.check ? (
                  <img
                    alt="select"
                    src={"/img/common/Vector.svg"}
                    className={classes.check_box_icon}
                  />
                ) : null}
              </Box>
              <div
                className={classes.modal_violet_text}
                style={{ marginLeft: "10px" }}
              >
                Monday
              </div>
            </div>
            <div className={classes.check_box_wrapper}>
              <Box
                className={classes.check_box}
                onClick={() =>
                  setTuesday({ ...tuesday, check: !tuesday.check })
                }
              >
                {tuesday.check ? (
                  <img
                    alt="select"
                    src={"/img/common/Vector.svg"}
                    className={classes.check_box_icon}
                  />
                ) : null}
              </Box>
              <div
                className={classes.modal_violet_text}
                style={{ marginLeft: "10px" }}
              >
                Tuesday
              </div>
            </div>
            <div className={classes.check_box_wrapper}>
              <Box
                className={classes.check_box}
                onClick={() =>
                  setWednesday({ ...wednesday, check: !wednesday.check })
                }
              >
                {wednesday.check ? (
                  <img
                    alt="select"
                    src={"/img/common/Vector.svg"}
                    className={classes.check_box_icon}
                  />
                ) : null}
              </Box>
              <div
                className={classes.modal_violet_text}
                style={{ marginLeft: "10px" }}
              >
                Wednesday
              </div>
            </div>
            <div className={classes.check_box_wrapper}>
              <Box
                className={classes.check_box}
                onClick={() =>
                  setThursday({ ...thursday, check: !thursday.check })
                }
              >
                {thursday.check ? (
                  <img
                    alt="select"
                    src={"/img/common/Vector.svg"}
                    className={classes.check_box_icon}
                  />
                ) : null}
              </Box>
              <div
                className={classes.modal_violet_text}
                style={{ marginLeft: "10px" }}
              >
                Thursday
              </div>
            </div>
            <div className={classes.check_box_wrapper}>
              <Box
                className={classes.check_box}
                onClick={() => setFriday({ ...friday, check: !friday.check })}
              >
                {friday.check ? (
                  <img
                    alt="select"
                    src={"/img/common/Vector.svg"}
                    className={classes.check_box_icon}
                  />
                ) : null}
              </Box>
              <div
                className={classes.modal_violet_text}
                style={{ marginLeft: "10px" }}
              >
                Friday
              </div>
            </div>
            <div className={classes.check_box_wrapper}>
              <Box
                className={classes.check_box}
                onClick={() =>
                  setSaturday({ ...saturday, check: !saturday.check })
                }
              >
                {saturday.check ? (
                  <img
                    alt="select"
                    src={"/img/common/Vector.svg"}
                    className={classes.check_box_icon}
                  />
                ) : null}
              </Box>
              <div
                className={classes.modal_violet_text}
                style={{ marginLeft: "10px" }}
              >
                Saturday
              </div>
            </div>
            <div className={classes.check_box_wrapper}>
              <Box
                className={classes.check_box}
                onClick={() => setSunday({ ...sunday, check: !sunday.check })}
              >
                {sunday.check ? (
                  <img
                    alt="select"
                    src={"/img/common/Vector.svg"}
                    className={classes.check_box_icon}
                  />
                ) : null}
              </Box>
              <div
                className={classes.modal_violet_text}
                style={{ marginLeft: "10px" }}
              >
                Sunday
              </div>
            </div>
          </div>
          <div style={{ width: "278px" }}>
            <div className={classes.flex3}>
              <div
                className={classes.accordion_text}
                style={{ paddingTop: "3px" }}
              >
                from
              </div>
              <input
                className={classes.box}
                style={{
                  paddingLeft: "20px",
                  paddingTop: "6px",
                  paddingRight: "20px",
                  width: "88px",
                  paddingBottom: "5px",
                  outline: "none",
                }}
                value={timeFrom}
                onChange={(event) => setTimeFrom(event.target.value)}
                type="text"
                placeholder="9:00"
              />
              <div
                className={classes.accordion_text}
                style={{ paddingTop: "3px" }}
              >
                to
              </div>
              <input
                className={classes.box}
                style={{
                  paddingLeft: "20px",
                  paddingTop: "6px",
                  paddingRight: "20px",
                  width: "88px",
                  paddingBottom: "5px",
                  outline: "none",
                }}
                value={timeTo}
                onChange={(event) => setTimeTo(event.target.value)}
                type="text"
                placeholder="17:00"
              />
            </div>
            <div
              className={classes.modal_violet_text2}
              style={{
                textAlign: "center",
                marginTop: "31px",
                cursor: "pointer",
              }}
              onClick={handleTime}
            >
              {"Add >>"}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className={classes.btn_accept}
            style={{ width: "154px" }}
            onClick={handleCloseTime}
          >
            Accept
          </button>
          <button
            className={classes.btn_cancel}
            style={{ width: "154px", marginLeft: "30px" }}
            onClick={handleCloseTime}
          >
            Cancel
          </button>
        </div>
      </div>
      <div className={classes.time_right_bar}>
        <div
          className={classes.modal_text}
          style={{ textAlign: "center", marginTop: "16px" }}
        >
          Time Periods:
        </div>
        {time.map((item) => (
          <>
            <div style={{ textAlign: "center", marginTop: "31px" }}>
              <span className={classes.modal_violet_text2}>{item.day}</span>
              <span className={classes.modal_text2}> </span>
              <span className={classes.modal_text2}>
                {item.timeFrom} - {item.timeTo}
              </span>
            </div>
            <div
              className={classes.modal_hr}
              style={{ margin: "9px 8px 0px 8px" }}
            ></div>
          </>
        ))}
      </div>
    </div>
  );

  const bodyBudgetModal = (
    <div
      style={{
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        outline: "none",
      }}
      className={classes.modal_budget}
    >
      <div style={{ width: "536px", height: "100%" }}>
        <div style={{ width: "100%", height: "336px", display: "flex" }}>
          <div className={classes.budget_left_bar}>
            <div className={classes.check_box_wrapper}>
              <Box
                className={classes.check_box}
                onClick={() => setGoogle({ ...google, check: !google.check })}
              >
                {google.check ? (
                  <img
                    alt="select"
                    src={"/img/common/Vector.svg"}
                    className={classes.check_box_icon}
                  />
                ) : null}
              </Box>
              <div
                className={classes.modal_violet_text}
                style={{ marginLeft: "8px" }}
              >
                Google Search
              </div>
            </div>
            <div className={classes.check_box_wrapper}>
              <Box
                className={classes.check_box}
                onClick={() =>
                  setFacebook({ ...facebook, check: !facebook.check })
                }
              >
                {facebook.check ? (
                  <img
                    alt="select"
                    src={"/img/common/Vector.svg"}
                    className={classes.check_box_icon}
                  />
                ) : null}
              </Box>
              <div
                className={classes.modal_violet_text}
                style={{ marginLeft: "10px" }}
              >
                Facebook
              </div>
            </div>
            <div className={classes.check_box_wrapper}>
              <Box
                className={classes.check_box}
                onClick={() =>
                  setYoutube({ ...youtube, check: !youtube.check })
                }
              >
                {youtube.check ? (
                  <img
                    alt="select"
                    src={"/img/common/Vector.svg"}
                    className={classes.check_box_icon}
                  />
                ) : null}
              </Box>
              <div
                className={classes.modal_violet_text}
                style={{ marginLeft: "10px" }}
              >
                YouTube
              </div>
            </div>
            <div className={classes.check_box_wrapper}>
              <Box
                className={classes.check_box}
                onClick={() =>
                  setSnapchat({ ...snapchat, check: !snapchat.check })
                }
              >
                {snapchat.check ? (
                  <img
                    alt="select"
                    src={"/img/common/Vector.svg"}
                    className={classes.check_box_icon}
                  />
                ) : null}
              </Box>
              <div
                className={classes.modal_violet_text}
                style={{ marginLeft: "10px" }}
              >
                SnapChat
              </div>
            </div>
            <div className={classes.check_box_wrapper}>
              <Box
                className={classes.check_box}
                onClick={() =>
                  setInstagram({ ...instagram, check: !instagram.check })
                }
              >
                {instagram.check ? (
                  <img
                    alt="select"
                    src={"/img/common/Vector.svg"}
                    className={classes.check_box_icon}
                  />
                ) : null}
              </Box>
              <div
                className={classes.modal_violet_text}
                style={{ marginLeft: "10px" }}
              >
                Instagram
              </div>
            </div>
            <div className={classes.check_box_wrapper}>
              <Box
                className={classes.check_box}
                onClick={() =>
                  setPinterest({ ...pinterest, check: !pinterest.check })
                }
              >
                {pinterest.check ? (
                  <img
                    alt="select"
                    src={"/img/common/Vector.svg"}
                    className={classes.check_box_icon}
                  />
                ) : null}
              </Box>
              <div
                className={classes.modal_violet_text}
                style={{ marginLeft: "10px" }}
              >
                Pinterest
              </div>
            </div>
          </div>
          <div style={{ width: "314px" }}>
            <div className={classes.flex4} style={{ paddingLeft: "65px" }}>
              <div
                className={classes.accordion_text}
                style={{ paddingTop: "3px" }}
              >
                Max CPM
              </div>
              <input
                className={classes.box}
                style={{
                  width: "88px",
                  marginLeft: "10px",
                  paddingLeft: "11px",
                  paddingTop: "4px",
                  outline: "none",
                }}
                placeholder="$"
                value={maxcpm}
                onChange={(event) => setMaxCpm(event.target.value)}
                type="number"
              />
            </div>
            <div className={classes.flex4} style={{ paddingLeft: "65px" }}>
              <div
                className={classes.accordion_text}
                style={{ paddingTop: "3px" }}
              >
                Daily Budget
              </div>
              <input
                className={classes.box}
                style={{
                  width: "88px",
                  marginLeft: "10px",
                  paddingLeft: "11px",
                  paddingTop: "4px",
                  outline: "none",
                }}
                placeholder="$"
                value={dailybudget}
                onChange={(event) => setDailyBudget(event.target.value)}
                type="number"
              />
            </div>
            <div
              className={classes.modal_violet_text2}
              style={{
                textAlign: "center",
                marginTop: "31px",
                cursor: "pointer",
              }}
              onClick={handleBudget}
            >
              {"Add >>"}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className={classes.btn_accept}
            style={{ width: "154px" }}
            onClick={handleCloseBudget}
          >
            Accept
          </button>
          <button
            className={classes.btn_cancel}
            style={{ width: "154px", marginLeft: "30px" }}
            onClick={handleCloseBudget}
          >
            Cancel
          </button>
        </div>
      </div>
      <div className={classes.budget_right_bar}>
        <div
          className={classes.modal_text}
          style={{ textAlign: "center", marginTop: "16px" }}
        >
          Channel Budgets:
        </div>
        {state.map((item) => (
          <>
            <div
              style={{ textAlign: "center", marginTop: "31px" }}
              key={item.cpm + Math.random(0, 10)}
            >
              <span className={classes.modal_violet_text2}>{item.name}</span>
              <span className={classes.modal_text2}> </span>
              <span className={classes.modal_text2}>CPM:</span>
              <span className={classes.modal_text3}> ${item.cpm}</span>
              <span className={classes.modal_text2}>, Budget:</span>
              <span className={classes.modal_text3}> ${item.daily}</span>
            </div>
            <div
              className={classes.modal_hr}
              style={{ margin: "9px 8px 0px 8px" }}
              key={item.daily + Math.random(0, 10)}
            ></div>
          </>
        ))}
      </div>
    </div>
  );

  const bodyNewCampaign = (
    <div
      style={
        expanded2
          ? isActive
            ? {
                height: "785px",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                outline: "none",
              }
            : {
                height: "981px",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                outline: "none",
              }
          : {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              outline: "none",
            }
      }
      className={classes.modal_new_campaign}
    >
      <div style={{ display: "flex", marginBottom: "30px" }}>
        <div
          style={{ marginLeft: "58px", marginTop: "53px", marginRight: "20px" }}
        >
          <Typography className={classes.modal_new_campaign_header_text}>
            New Campaign
          </Typography>
        </div>
        <div style={{ marginTop: "43px" }}>
          <input
            type="text"
            placeholder="Enter VG name"
            className={classes.modal_new_campaign_header_input}
          />
        </div>
      </div>
      <div>
        <Box style={{ marginLeft: "58px" }}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className={classes.accordion_one}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1rtg-content"
              id="panel1rtg-header"
            >
              {!expanded ? (
                <Typography className={classes.heading}>Viewing</Typography>
              ) : null}
            </AccordionSummary>
            <AccordionDetails className={classes.accordion_details}>
              <div
                className={classes.accordion_header1}
                style={{ paddingTop: "4px" }}
              >
                Viewing
              </div>
              <div className={classes.flex1}>
                <div
                  className={classes.accordion_text}
                  style={{ paddingTop: "6px" }}
                >
                  viewed
                </div>
                <div
                  className={classes.box}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "326px",
                  }}
                >
                  <Box style={{ paddingLeft: "5px", paddingTop: "4px" }}>
                    BMV 3 Series Summer Push
                  </Box>
                  <Box style={{ paddingTop: "2px" }}>
                    <Button
                      style={{ width: "0px", height: "0px", minWidth: "0px" }}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                    >
                      <img alt="arrow" src="/img/accardion/Vector9.svg" />
                    </Button>
                  </Box>
                </div>
              </div>
              <div className={classes.flex2}>
                <div
                  className={classes.accordion_text}
                  style={{ paddingTop: "6px" }}
                >
                  frequency
                </div>
                <div
                  className={classes.box}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "88px",
                  }}
                >
                  <Box style={{ paddingLeft: "5px", paddingTop: "4px" }}>
                    no min
                  </Box>
                </div>
                <div
                  className={classes.accordion_text}
                  style={{ paddingTop: "2px" }}
                >
                  -
                </div>
                <div
                  className={classes.box}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "88px",
                  }}
                >
                  <Box style={{ paddingLeft: "5px", paddingTop: "4px" }}>
                    no max
                  </Box>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </Box>
      </div>
      <div style={{ marginBottom: "47px" }}>
        <Box style={{ marginLeft: "58px" }}>
          <Accordion
            expanded={expanded2 === "panel1"}
            onChange={handleChange2("panel1")}
            className={classes.accordion_one}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1rtg-content"
              id="panel1rtg-header"
            >
              {!expanded2 ? (
                <Typography className={classes.heading}>Geography</Typography>
              ) : null}
            </AccordionSummary>
            <AccordionDetails
              className={classes.accordion_details}
              style={
                isActive
                  ? { height: "497px", flexWrap: "wrap" }
                  : { height: "705px", flexWrap: "wrap" }
              }
            >
              <div
                className={classes.accordion_header1}
                style={{ paddingTop: "4px" }}
              >
                Geography
              </div>
              <div className={classes.flex1}>
                <div
                  className={classes.box}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "405px",
                  }}
                >
                  <Box style={{ paddingLeft: "5px", paddingTop: "4px" }}>
                    {attributionValue?.Name
                      ? attributionValue?.Name
                      : attributions[0]?.Value}
                  </Box>
                  <Box style={{ paddingTop: "2px" }}>
                    <Button
                      style={{ width: "0px", height: "0px", minWidth: "0px" }}
                      aria-haspopup="true"
                      onClick={handleClickAttributions}
                      aria-controls="menu-attributions"
                      aria-haspopup="true"
                      variant="contained"
                    >
                      <img alt="arrow" src="/img/accardion/Vector9.svg" />
                    </Button>
                  </Box>
                </div>
              </div>
              <div
                className={classes.flex2}
                style={{ width: "1000px", marginLeft: "-66px" }}
              >
                <div
                  className={classes.accordion_text}
                  style={{ paddingTop: "6px", paddingLeft: "99px" }}
                >
                  selected postcodes
                </div>
                <div
                  className={classes.box}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "276px",
                  }}
                >
                  <Box style={{ paddingLeft: "20px", paddingTop: "4px" }}>
                    None
                  </Box>
                </div>
                <div
                  className={classes.accordion_text}
                  style={{ paddingTop: "2px" }}
                >
                  quintiles
                </div>
                <div
                  className={classes.box}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "307px",
                  }}
                >
                  <Box style={{ paddingLeft: "5px", paddingTop: "4px" }}>
                    All quantities
                  </Box>
                  <Box style={{ paddingTop: "2px", paddingLeft: "125px" }}>
                    <Button
                      style={{ width: "0px", height: "0px", minWidth: "0px" }}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                    >
                      <img alt="arrow" src="/img/accardion/Vector9.svg" />
                    </Button>
                  </Box>
                </div>
              </div>
              <Box
                style={
                  isActive
                    ? {
                        height: "366px",
                        width: "100%",
                        marginTop: "84px",
                        position: "absolute",
                        marginLeft: "-15px",
                      }
                    : {
                        height: "621px",
                        width: "100%",
                        marginTop: "84px",
                        position: "absolute",
                        marginLeft: "-15px",
                      }
                }
              >
                <Heatmap
                  timeBlockType={"Weeks"}
                  timeBlockCount={"4"}
                  attributions={attributionValue}
                  isActive={isActive}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </div>
      <div
        style={
          expanded2
            ? { display: "flex", justifyContent: "center", marginTop: "-31px" }
            : { display: "flex", justifyContent: "center" }
        }
      >
        <div
          className={classes.modal_new_campaign_accept_btn}
          onClick={handleCloseNewCampaign}
        >
          Accept
        </div>
        <div
          className={classes.modal_new_campaign_cancel_btn}
          onClick={handleCloseNewCampaign}
        >
          Cancel
        </div>
        <StyledMenu
          id="menu-attributions"
          anchorEl={anchorAttributions}
          keepMounted
          open={Boolean(anchorAttributions)}
          onClose={handleCloseAttributions}
          PaperProps={{
            style: {
              width: "405px",
            },
          }}
        >
          {attributions.map((item) => (
            <MenuItem
              onClick={handleCloseAttributions}
              id={item.Id}
              data-my-value={item.Value}
              key={item.Id}
            >
              {item.Value}
            </MenuItem>
          ))}
        </StyledMenu>
      </div>
    </div>
  );

  return (
    <div>
      <Navbar />
      <div className={classes.root}>
        <Box>
          <div className={classes.new_campaign_border}>
            <div className={classes.flex_container}>
              <div className={classes.new_campaign_header}>New Campaign</div>
              <button
                className={classes.btn_create_campaign}
                onClick={handleOpenNewCampaign}
              >
                Create Campaign
              </button>
            </div>
          </div>
          <div className={classes.my_campaigns_border}>
            <div className={classes.my_campaigns_header}>
              My Retargeting Campaigns
            </div>
            <div className={classes.my_campaigns_row}>
              <div className={classes.row_header}>BMW 1+ reach</div>
              <div className={classes.row_buttons_container}>
                <img
                  alt="logout"
                  src="/img/common/RetargetingBuyIcon.svg"
                  className={classes.row_button}
                  onClick={handleOpenBudget}
                />
                <img
                  alt="logout"
                  src="/img/common/RetargetingTimeIcon.svg"
                  className={classes.row_button}
                  onClick={handleOpenTime}
                />
                <img
                  alt="logout"
                  src="/img/common/RetargetingPauseIcon.svg"
                  className={classes.row_button}
                />
              </div>
            </div>
            <div className={classes.row_line}></div>
            <div className={classes.my_campaigns_row}>
              <div className={classes.row_header}>
                Nestle Carnation frequency 2
              </div>
              <div className={classes.row_buttons_container}>
                <img
                  alt="logout"
                  src="/img/common/RetargetingBuyIcon.svg"
                  className={classes.row_button}
                  onClick={handleOpenBudget}
                />
                <img
                  alt="logout"
                  src="/img/common/RetargetingTimeIcon.svg"
                  className={classes.row_button}
                  onClick={handleOpenTime}
                />
                <img
                  alt="logout"
                  src="/img/common/RetargetingPlayIcon.svg"
                  className={classes.row_button}
                />
              </div>
            </div>
            <div className={classes.row_line}></div>
            <div className={classes.my_campaigns_row}>
              <div className={classes.row_header}>Kellogs 2-3 frequency</div>
              <div className={classes.row_buttons_container}>
                <img
                  alt="logout"
                  src="/img/common/RetargetingBuyIcon.svg"
                  className={classes.row_button}
                  onClick={handleOpenBudget}
                />
                <img
                  alt="logout"
                  src="/img/common/RetargetingTimeIcon.svg"
                  className={classes.row_button}
                  onClick={handleOpenTime}
                />
                <img
                  alt="logout"
                  src="/img/common/RetargetingPlayIcon.svg"
                  className={classes.row_button}
                />
              </div>
            </div>
          </div>
          <Modal
            open={openTime}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyTimeModal}
          </Modal>
          <Modal
            open={openBudget}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyBudgetModal}
          </Modal>
          <Modal
            open={openNewCampaign}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyNewCampaign}
          </Modal>
        </Box>
      </div>
    </div>
  );
}
