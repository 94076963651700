import React, { useState } from "react";
import styles from "../style.module.css";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Helper from "../../../helpers/helper.js";

export default function Dashboard() {
  const [render, setRender] = useState(0);
  const [open, setOpen] = useState(false);

  const roles = useSelector((state) => state.user.currentUser.Roles);

  const dispatch = useDispatch();
  const params = [
    {
      location: "/Dashboard",
      alt: "dashboard",
      src: "/img/sidebar/Group730.svg",
      text: "Dashboard",
      key: 1,
    },
    {
      location: "/Planner",
      alt: "planner",
      src: "/img/sidebar/Group.svg",
      text: "Plan Perfomance",
      key: 2,
    },
    {
      location: "/Retargeting",
      alt: "retargeting",
      src: "/img/sidebar/darts1.svg",
      text: "Retargeting",
      key: 3,
    },
    {
      location: "/BuyOptimizer",
      alt: "BuyOptimizer",
      src: "/img/sidebar/Group4.svg",
      text: "Buy Optimizer",
      key: 4,
    },
    {
      location: "/MetricBuilder",
      alt: "MetricBuilder",
      src: "/img/sidebar/Frame.svg",
      text: "Metric Builder",
      key: 5,
    },
    {
      location: "/CrosstabAnalyzer",
      alt: "CrosstabAnalyzer",
      src: "/img/sidebar/list1.svg",
      text: "Crosstab Analyzer",
      key: 6,
    },
    {
      location: "/DataStory",
      alt: "Data Story",
      src: "/img/sidebar/Group2.svg",
      text: "Data Story",
      key: 7,
    },
    {
      location: "/RFAnalyzer",
      alt: "RF Analyzer",
      src: "/img/sidebar/Group727.svg",
      text: "RF Analyzer",
      key: 8,
    },
    {
      location: "/ReportBuilder",
      alt: "Report Builder",
      src: "/img/sidebar/profit-report2.svg",
      text: "Report Builder",
      key: 9,
    },
    {
      location: "/Admin",
      alt: "admin",
      src: "/img/sidebar/Setting_line.svg",
      text: "Admin",
      key: 11,
    },
    {
      location: "/Settings",
      alt: "Settings",
      src: "/img/sidebar/Setting_line.svg",
      text: "Settings",
      key: 10,
    },
  ];

  const history = useHistory();
  React.useEffect(() => {
    if (history.location.pathname === "/") {
      setRender(1);
    }
  }, []);
  return (
    <div
      className={styles.sidebar}
      style={
        history.location.pathname === "/Privacy" ? { display: "none" } : {}
      }
    >
      <div className={styles.sidebar_inner}>
        <ul>
          <li>
            <div className={styles.menu}>
              <div
                className={styles.hamburger}
                onClick={() => {
                  setOpen(!open);
                  dispatch({ type: "SET_OPEN", payload: !open });
                }}
              >
                <img
                  alt="arrow"
                  id="point"
                  className={styles.point}
                  src={
                    open
                      ? "/img/sidebar/Expand_right_double.svg"
                      : "/img/sidebar/Expand_left_double.svg"
                  }
                  style={{ marginTop: "49px", marginLeft: "27px" }}
                />
              </div>
            </div>
          </li>
          {params.map((item) => (
            <li
              style={item.key === 10 ? { marginTop: "20rem" } : null}
              className={
                item.key === 11 && !Helper.checkUserRoles(roles)
                  ? styles.admin
                  : null
              }
              onClick={() => setRender(item.key)}
              key={item.key}
            >
              <div className={styles.menu_item}>
                <div>
                  {history.location.pathname.includes(item.location) ? (
                    <img
                      alt="Select"
                      src="/img/sidebar/Group803.svg"
                      className={styles.active}
                    />
                  ) : null}
                </div>
                <div>
                  <NavLink to={item.location}>
                    <div className={styles.item_conteiner}>
                      <span className={styles.icon}>
                        <img alt={item.alt} src={item.src} />
                      </span>
                      <span className={styles.text}>{item.text}</span>
                    </div>
                  </NavLink>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
