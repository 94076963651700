import React from "react";
import { useSelector } from "react-redux";

export default function Privacy() {
  const isAuth = useSelector((state) => state.user.isAuth);
  return (
    <div
      style={
        !isAuth
          ? {
              display: "flex",
              justifyContent: "center",
              marginLeft: "100px",
              padding: "25px",
              width: "calc(100% - 100px)",
            }
          : {}
      }
    >
          <div>
              <div style={{ fontSize: "14pt", fontWeight: "bold", textAlign: "center", width: "100%" }}>MediaBrain Platform Privacy Statement</div>
              <br />
              <br />
        <p>
          MediaBrain values your privacy, and we want you to feel comfortable
          about our business relationship.
        </p>
        <br />
        <br />
        <br />
        <p>
          At any point while we are in possession of or processing your Personal
          Data, you, the data subject, have the following rights:
        </p>
        <br />
        <br />
        <br />
        <p>- Right of access</p>
        <br />
        <p>- Right of rectification</p>
        <br />
        <p>- Right to be forgotten</p>
        <br />
        <p>- Right to restriction of processing</p>
        <br />
        <p>- Right of portability</p>
        <br />
        <p>- Right to object</p>
        <br />
        <p>- Right to object to automated processing, including profiling</p>
        <br />
        <p>
          - Right to judicial review - in the event that MediaBrain refuses your
          request under rights of access
        </p>
        <br />
        <br />
        <br />
        <p>
          All the above rights still apply should there be a third party
          involved in the processing of your Personal Data.
        </p>
        <br />
        <br />
        <br />
        <p>
          <strong>
            FAQ 1. Why does MediaBrain need to collect and store Personal Data?
          </strong>
        </p>
        <br />
        <br />
        <br />
        <p>
          MediaBrain collects information volunteered by yourself, which may
          include your name, email address company name and job role.
        </p>
        <br />
        <br />
        <br />
        <p>
          The information we collect is necessary for us to provide the services
          you have requested and administer your customer account.
        </p>
        <br />
        <br />
        <br />
        <p>
          We will only collect and process data about you where we have a lawful
          basis. Lawful basis include where processing is necessary to provide
          the services we offer, and to pursue the legitimate interests of
          MediaBrain as a provider of media analytic tools. In any event, we are
          committed to ensuring that information we collect, and use is
          appropriate for this purpose, and does not constitute an invasion of
          your privacy.
        </p>
        <br />
        <br />
        <br />
        <p>
          We will endeavour to keep your details accurate and up to date, and to
          erase the data 24 months after the end of our business relationship.
        </p>
        <br />
        <br />
        <br />
        <p>
          <strong>
            FAQ 2. Will MediaBrain share my Personal Data with anyone else?
          </strong>
        </p>
        <br />
        <br />
        <br />
        <p>
          Where appropriate for purpose, we may share your contact details with
          the MediaBrain Group of companies. Where necessary we may have to
          release your Personal Data on to third parties when it is necessary to
          comply with the law or to protect our legitimate interest in the event
          of legal proceedings. This does not include selling or sharing
          Personal Data for commercial purposes in a way that is contrary to
          this privacy statement.
        </p>
        <br />
        <br />
        <br />
        <p>
          Any third parties that we may share your data with are obliged to keep
          your details securely, and to use them only for the specified purpose.
          When they no longer need your data to fulfil this service, they will
          dispose of the details in line with MediaBrain procedures.
        </p>
        <br />
        <br />
        <br />
        <p>
          In limited circumstances, we may transfer your contact details to
          MediaBrain Group of companies or service providers located outside of
          the USA and EEA. In this event, we have in place legal and
          organisational safeguards to ensure the security of your data.
        </p>
        <br />
        <br />
        <br />
        <p>
          <strong>
            FAQ 3. Can I find out the Personal Data that MediaBrain holds about
            me?
          </strong>
        </p>
        <br />
        <br />
        <br />
        <p>
          At your request, MediaBrain can confirm what information we hold about
          you and how it is processed. If MediaBrain does hold Personal Data
          about you, you can review, correct, or update your Personal Data at
          any time by contacting the Data Protection Officer: -
          <a href="mailto:nick@mediabrain.io">nick@mediabrain.io</a>
        </p>
        <br />
        <br />
        <br />
        <p>
          <strong>FAQ 4: What data does MediaBrain use?</strong>
        </p>
        <br></br>
        <p>
          Data presented in the MediaBrain Platform must be provided or
          permissioned to us by you, our customers from your own data sources or
          third parties. This may include media consumption data collected for
          the purpose of advertising personalisation or for measuring the
          effectiveness of advertising. All data received by MedaBrain is
          aggregated and anonymized to a level that does not allow the
          identification of individuals.
        </p>
      </div>
    </div>
  );
}
