import React from "react";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";

export default function Navbar() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);

  return (
    <div>
      <div className={styles.main_container}>
        <div className={styles.company_logo}>
          <img alt="logo" src="/img/common/Layer2.svg" />
        </div>
        <div className={styles.main_container_menu}></div>
        <div className={styles.user_container}>
          <div className={styles.user_icon} style={{ marginLeft: "150px" }}>
            {`${user.UserName[0]}${user.UserName[1]}`}
          </div>
          <div className={styles.user_text}>
            {/* {user.UserName.split('.')[0]} */}
          </div>
          <div>
            <img
              alt="logout"
              src="/img/common/Shape.svg"
              className={styles.logout_icon}
              onClick={() => dispatch({ type: "LOGOUT" })}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
