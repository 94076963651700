import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";

export default function Navbar() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const history = useHistory();

  return (
    <div>
      <div className={styles.main_container}>
        <div className={styles.company_logo}>
          <img alt="logo" src="/img/common/Layer2.svg" />
        </div>
        <div className={styles.main_container_menu}>
          <div className={styles.menu_item}>
            <NavLink
              exact
              to={"/BuyOptimizer"}
              activeClassName={styles.active}
              style={{ color: "#1E2019" }}
            >
              Brands
            </NavLink>
            {history.location.pathname === "/BuyOptimizer" ? (
              <img alt="selectedItem" src="/img/common/Rectangle120B.svg" />
            ) : null}
          </div>
          <div className={styles.menu_item}>
            <NavLink
              exact
              to={"/BuyOptimizer/Inventory"}
              activeClassName={styles.active}
              style={{ color: "#1E2019" }}
            >
              Inventory
            </NavLink>
            {history.location.pathname === "/BuyOptimizer/Inventory" ? (
              <img alt="selectedItem" src="/img/common/Rectangle121B.svg" />
            ) : null}
          </div>
          <div className={styles.menu_item}>
            <NavLink
              exact
              to={"/BuyOptimizer/BusinessRules"}
              activeClassName={styles.active}
              style={{ color: "#1E2019" }}
            >
              Business Rules
            </NavLink>
            {history.location.pathname === "/BuyOptimizer/BusinessRules" ? (
              <img alt="selectedItem" src="/img/common/Rectangle122B.svg" />
            ) : null}
          </div>
        </div>
        <div className={styles.user_container}>
          <div className={styles.user_icon} style={{ marginLeft: "150px" }}>
            {`${user.UserName[0]}${user.UserName[1]}`}
          </div>
          <div className={styles.user_text}>
            {/* {user.UserName.split('.')[0]}  */}
          </div>
          <div>
            <img
              alt="logout"
              src="/img/common/Shape.svg"
              className={styles.logout_icon}
              onClick={() => dispatch({ type: "LOGOUT" })}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
