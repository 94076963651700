import React from "react";
import Navbar from "../Navbar/Navbar.js";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import PermissionsEditDeleteModal from "../permissionsEditModal/PermissionsEditDeleteModal.js";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "97%",
    minWidth: "1000px",
  },
  flex_space_container: {
    display: "flex",
    justifyContent: "space-between",
  },
  //accardion
  accadion_one: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "95%",
    borderBottomLeftRadius: "10px !important",
    borderBottomRightRadius: "10px !important",
    borderTopLeftRadius: "10px !important",
    borderTopRightRadius: "10px !important",
  },
  heading: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  // table
  table: {
    minWidth: 300,
  },
  headTableSell: {
    color: "#783CC6",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    padding: "14px 14px 7px 14px",
  },
  defaultTableSell: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1E2019",
    padding: "14px 14px 7px 14px",
  },
  //add new permissions
  new_campaign_border: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "95%",
    height: "59px",
    marginBottom: "30px",
    marginTop: "30px",
  },
  flex_container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  new_campaign_header: {
    paddingTop: "13px",
    paddingLeft: "21px",
    fontFamily: "Open Sans",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "30px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  btn_create_campaign: {
    width: "191px",
    height: "41px",
    background: "#783CC6",
    borderRadius: "10px",
    marginTop: "8px",
    marginRight: "26px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#FFFFFF",
    borderWidth: "0px",
    cursor: "pointer",
  },
}));

export default function MainPermissionsEditComponent({
  permissionsList,
  handleTableRowViewRolesCapture,
  openDeleteViewRoles,
  handleOpenDeleteViewRoles,
  handleCloseDeleteViewRoles,
  handleCloseAcceptDeleteViewRoles,
  openDeleteEditRoles,
  handleOpenDeleteEditRoles,
  handleCloseDeleteEditRoles,
  handleCloseAcceptDeleteEditRoles,
  expandedAccordion,
  expandedAccordion2,
  expandedAccordion3,
  handleChangeAccordion,
  handleChangeAccordion2,
  handleChangeAccordion3,
}) {
  const isActive = useMediaQuery("(max-width: 1645px)");
  const classes = useStyles();
  const table_row = ["Name", ""];

  //icons button
  const deleteTableItem = (
    <img
      alt="trash"
      src="/img/optimizer/trash1.svg"
      style={{ cursor: "pointer" }}
      onClick={handleOpenDeleteViewRoles}
    />
  );
  const deleteTableItemEditRoles = (
    <img
      alt="trash"
      src="/img/optimizer/trash1.svg"
      style={{ cursor: "pointer" }}
      onClick={handleOpenDeleteEditRoles}
    />
  );
  return (
    <div>
      <Navbar />
      <div
        style={
          isActive
            ? {
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }
            : { display: "flex" }
        }
      >
        <div style={isActive ? { width: "45%" } : { width: "100%" }}>
          <div
            className={classes.new_campaign_border}
            style={{ marginTop: "-4rem" }}
          >
            <div className={classes.flex_container}>
              <div className={classes.new_campaign_header}>New View Roles</div>
              <button
                className={classes.btn_create_campaign}
                // onClick={handleOpenNewPermissions}
              >
                Add View Roles
              </button>
            </div>
          </div>
          <Box style={{ marginTop: "1rem" }}>
            <Accordion
              expanded={expandedAccordion === "panel1"}
              onChange={handleChangeAccordion("panel1")}
              className={classes.accadion_one}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {!expandedAccordion ? (
                  <Typography className={classes.heading}>
                    View Roles
                  </Typography>
                ) : null}
              </AccordionSummary>
              <AccordionDetails className={classes.accadion_details}>
                <TableContainer component={Paper} style={{ boxShadow: "none" }}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                    style={{ width: "100%" }}
                  >
                    <TableHead>
                      <TableRow>
                        {table_row.map((i) => (
                          <StyledTableCell align="center" key={i.toString()}>
                            {i}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {permissionsList[0]?.ViewRoles?.map((row) => (
                        <StyledTableRow
                          key={row.Id}
                          onClickCapture={() =>
                            handleTableRowViewRolesCapture(row.Id)
                          }
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            className={classes.headTableSell}
                            align="center"
                          >
                            {row.Name}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.defaultTableSell}
                            key={row.Id + Math.random()}
                          >
                            {deleteTableItem}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>
        </div>
        <div style={isActive ? { width: "45%" } : { width: "100%" }}>
          <div
            className={classes.new_campaign_border}
            style={{ marginTop: "-4rem" }}
          >
            <div className={classes.flex_container}>
              <div className={classes.new_campaign_header}>New Edit Roles</div>
              <button
                className={classes.btn_create_campaign}
                // onClick={handleOpenNewPermissions}
              >
                Add Edit Roles
              </button>
            </div>
          </div>
          <Box style={{ marginTop: "1rem" }}>
            <Accordion
              expanded={expandedAccordion2 === "panel2"}
              onChange={handleChangeAccordion2("panel2")}
              className={classes.accadion_one}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                {!expandedAccordion2 ? (
                  <Typography className={classes.heading}>
                    Edit Roles
                  </Typography>
                ) : null}
              </AccordionSummary>
              <AccordionDetails className={classes.accadion_details}>
                <TableContainer component={Paper} style={{ boxShadow: "none" }}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                    style={{ width: "100%" }}
                  >
                    <TableHead>
                      <TableRow>
                        {table_row.map((i) => (
                          <StyledTableCell align="center" key={i.toString()}>
                            {i}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {permissionsList[0]?.EditRoles?.map((row) => (
                        <StyledTableRow
                          key={row.Id}
                          onClickCapture={() =>
                            handleTableRowViewRolesCapture(row.Id)
                          }
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            className={classes.headTableSell}
                            align="center"
                          >
                            {row.Name}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.defaultTableSell}
                            key={row.Id + Math.random()}
                          >
                            {deleteTableItemEditRoles}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>
        </div>
        <div
          style={
            isActive ? { width: "45%", margin: "80px" } : { width: "100%" }
          }
        >
          <div
            className={classes.new_campaign_border}
            style={{ marginTop: "-4rem" }}
          >
            <div className={classes.flex_container}>
              <div className={classes.new_campaign_header}>New Attributes</div>
              <button
                className={classes.btn_create_campaign}
                // onClick={handleOpenNewPermissions}
              >
                Add Attributes
              </button>
            </div>
          </div>
          <Box style={{ marginTop: "1rem" }}>
            <Accordion
              expanded={expandedAccordion3 === "panel3"}
              onChange={handleChangeAccordion3("panel3")}
              className={classes.accadion_one}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                {!expandedAccordion3 ? (
                  <Typography className={classes.heading}>
                    Attributes
                  </Typography>
                ) : null}
              </AccordionSummary>
              <AccordionDetails className={classes.accadion_details}>
                <TableContainer component={Paper} style={{ boxShadow: "none" }}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                    style={{ width: "100%" }}
                  >
                    <TableHead>
                      <TableRow>
                        {table_row.map((i) => (
                          <StyledTableCell align="center" key={i.toString()}>
                            {i}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {permissionsList[0]?.Attributes?.map((row) => (
                        <StyledTableRow
                          key={row.Id}
                          //   onClickCapture={() => handleTableRowCapture(row.Id)}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            className={classes.headTableSell}
                            align="center"
                          >
                            {row.Name}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.defaultTableSell}
                            key={row.Id + Math.random()}
                          >
                            {deleteTableItem}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>
        </div>
      </div>
      <PermissionsEditDeleteModal
        text={"view role"}
        openDeletePermissions={openDeleteViewRoles}
        handleCloseDeletePermissions={handleCloseDeleteViewRoles}
        handleCloseAcceptDeletePermissions={handleCloseAcceptDeleteViewRoles}
      />
      <PermissionsEditDeleteModal
        text={"edit role"}
        openDeletePermissions={openDeleteEditRoles}
        handleCloseDeletePermissions={handleCloseDeleteEditRoles}
        handleCloseAcceptDeletePermissions={handleCloseAcceptDeleteEditRoles}
      />
    </div>
  );
}
