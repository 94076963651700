import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";

export default function Navbar() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const history = useHistory();

  return (
    <div>
      <div className={styles.main_container}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={styles.company_logo} style={{ marginRight: "30px" }}>
            <img alt="logo" src="/img/common/Layer2.svg" />
          </div>
          <div style={{ display: "flex", marginTop: "7px" }}>
            <div style={{ marginRight: "10px" }}>
              <img
                width="16"
                height="16"
                alt="backArrow"
                src="/img/common/Vector11.svg"
              />
            </div>
            <div style={{ marginTop: "-2px" }}>
              <NavLink
                className={styles.back_to_permission}
                exact
                to={"/Admin/Permissions"}
              >
                back to Permissions
              </NavLink>
            </div>
          </div>
        </div>
        <div className={styles.user_container}>
          <div className={styles.user_icon} style={{ marginLeft: "150px" }}>
            {`${user.UserName[0]}${user.UserName[1]}`}
          </div>
          <div className={styles.user_text}>
            {/* {user.UserName.split('.')[0]}  */}
          </div>
          <div>
            <img
              alt="logout"
              src="/img/common/Shape.svg"
              className={styles.logout_icon}
              onClick={() => dispatch({ type: "LOGOUT" })}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
