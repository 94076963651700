import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <button
    className="custom-input"
    style={{
      backgroundColor: "#E9D9FF",
      opacity: "0.6",
      border: "0.5px solid #783CC6",
      boxSizing: "border-box",
      backdropFilter: "blur(40px)",
      borderRadius: "10px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "18px",
      lineHeight: "25px",
      color: "#1E2019",
      width: "100%",
      height: "36px",
      marginLeft: "0.63%",
    }}
    onClick={onClick}
    ref={ref}
  >
    {value}
  </button>
));

export default function DatePickerCustom({ dateType, reducer, dateArr }) {
  const [startDate, setStartDate] = React.useState(new Date());
  const dispatch = useDispatch();
  let dateOptions = useSelector((state) => state[reducer][dateArr]);
  // let dates = [];
  // // 09/14/2021 12:00 AM -> 09/14/2021
  // for (let i = 0; i < dateOptions.length; i++) {
  //   dates.push(new Date(dateOptions[i].Name));
  // }
  const handleChange = (date) => {
    localStorage.setItem("Common_Date", date);
    setStartDate(date);
  };
  React.useEffect(() => {
    const dateFromLocalStorage = localStorage.getItem("Common_Date");
    if (dateOptions.length > 0) {
      if (dateFromLocalStorage !== undefined && dateFromLocalStorage !== null) {
        if (inArray(dateOptions, dateFromLocalStorage)) {
          setStartDate(new Date(dateFromLocalStorage));
        } else {
          dateOptions = bubbleSort(dateOptions);
          setStartDate(dateOptions[0]);
        }
      } else {
        dateOptions = bubbleSort(dateOptions);
        setStartDate(dateOptions[0]);
      }
    }
  }, [dateOptions]);
  React.useEffect(() => {
    if (inArray(dateOptions, startDate)) {
      dispatch({ type: dateType, payload: format(startDate, "M/d/yyyy") });
    }
  }, [startDate]);
  // sort date
  function bubbleSort(arr) {
    for (let i = 0, endI = arr.length - 1; i < endI; i++) {
      for (let j = 0, endJ = endI - i; j < endJ; j++) {
        if (arr[j] < arr[j + 1]) {
          let swap = arr[j];
          arr[j] = arr[j + 1];
          arr[j + 1] = swap;
        }
      }
    }
    return arr;
  }
  function inArray(arr, item) {
    for (let i = 0; i < arr.length; i++) {
      if (String(arr[i]) == String(item)) {
        return true;
      }
    }
    return false;
  }
  return (
    <DatePicker
      selected={startDate}
      onChange={handleChange}
      includeDates={dateOptions}
      customInput={<CustomInput />}
    />
  );
}
