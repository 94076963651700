import axios from "axios";
import endpoints from "../config/endpoind.json";

export const campaignData = (
  timeBlockType,
  timeBlockCount,
  targetId,
  linearId,
  attributionId,
  date
) => {
  return async (dispatch) => {
    try {
      if (
        attributionId === null ||
        attributionId === undefined ||
        linearId === null ||
        linearId === undefined
      )
        return;

      let attrs = [attributionId];

      const response = await axios.post(
        endpoints.apiUrl + endpoints.CampaignData,
        {
          TimeBlockType: timeBlockType || "Weeks",
          TargetId: Number(targetId) || 1,
          LinearId: Number(linearId) || 1,
          TimeBlockCount: timeBlockCount || 4,
          Attributions: attrs,
          DateStart: date.dateFrom || "",
          DateEnd: date.dateTo || "",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({ type: "SET_DATA", payload: response.data.Data });
      dispatch({
        type: "SET_RENDER",
        payload: false,
      });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
export const campaignOptions = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.CampaignOptions,
        {
          TimeBlockType: "Weekly",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({ type: "GET_OPTIONS", payload: response.data });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
export const channelsData = (
  timeBlockType,
  timeBlockCount,
  targetId,
  linearId,
  attributionId,
  competitiveAttributionsId,
  date
) => {
  return async (dispatch) => {
    try {
      if (
        attributionId === null ||
        attributionId === undefined ||
        linearId === null ||
        linearId === undefined
      )
        return;

      let attrs = [attributionId];
      let comAttrs = [competitiveAttributionsId];

      const response = await axios.post(
        endpoints.apiUrl + endpoints.ChannelsData,
        {
          TimeBlockType: timeBlockType || "Weeks",
          TargetId: Number(targetId) || 1,
          LinearId: Number(linearId) || 1,
          TimeBlockCount: timeBlockCount || 4,
          Attributions: attrs,
          CompetitiveAttributions: comAttrs || [12],
          DateStart: date.dateFrom || "",
          DateEnd: date.dateTo || "",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: "SET_CHANNEL_DATA",
        payload: response.data,
      });
      dispatch({
        type: "SET_RENDER",
        payload: false,
      });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
export const channelsOptions = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.ChannelsOptions,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({ type: "GET_OPTIONS_CHANNEL", payload: response.data });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
export const dayAndTimeData = (
  timeBlockType,
  timeBlockCount,
  targetId,
  linearId,
  attributionId,
  competitiveAttributionsId,
  date
) => {
  return async (dispatch) => {
    try {
      if (
        attributionId === null ||
        attributionId === undefined ||
        linearId === null ||
        linearId === undefined
      )
        return;

      let attrs = [attributionId];
      let comAttrs = [competitiveAttributionsId];

      const response = await axios.post(
        endpoints.apiUrl + endpoints.DayAndTimeData,
        {
          TimeBlockType: timeBlockType || "Weeks",
          TargetId: Number(targetId) || 1,
          LinearId: Number(linearId) || 1,
          TimeBlockCount: timeBlockCount || 4,
          Attributions: attrs,
          CompetitiveAttributions: comAttrs || [12],
          DateStart: date.dateFrom || "",
          DateEnd: date.dateTo || "",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: "SET_DAYANDTIME_DATA",
        payload: response.data,
      });
      dispatch({
        type: "SET_RENDER",
        payload: false,
      });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
export const dayAndTimeOptions = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.DayAndTimeOptions,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({ type: "GET_OPTIONS_DAYANDTIME", payload: response.data });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
export const adsData = (
  timeBlockType,
  timeBlockCount,
  targetId,
  linearId,
  attributionId,
  date
) => {
  return async (dispatch) => {
    try {
      if (
        attributionId === null ||
        attributionId === undefined ||
        linearId === null ||
        linearId === undefined
      )
        return;

      let attrs = [attributionId];

      const response = await axios.post(
        endpoints.apiUrl + endpoints.AdsData,
        {
          TimeBlockType: timeBlockType || "Weeks",
          TargetId: Number(targetId) || 1,
          LinearId: Number(linearId) || 1,
          TimeBlockCount: timeBlockCount || 4,
          Attributions: attrs,
          DateStart: date.dateFrom || "",
          DateEnd: date.dateTo || "",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({ type: "SET_ADS_DATA", payload: response.data.Data });
      dispatch({
        type: "SET_RENDER",
        payload: false,
      });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
export const adsOptions = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.AdsOptions,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({ type: "GET_OPTIONS_ADS", payload: response.data });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
