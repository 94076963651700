import React, { PureComponent } from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';

class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, payload, gradus } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16}
                    textAnchor="end" fill="#1E2019" fontFamily="Open Sans"
                    fontStyle="normal" fontWeight="300" transform={`rotate(${gradus})`}
                    fontSize="14px"
                >
                    {payload.value}
                </text>
            </g>
        );
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    legendText: {
        fontFamily: "Open Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "22px",
        letterSpacing: "0em",
        textAlign: "left",
        color: "#783CC6",
    },
    legendBox: {
        width: "20px",
        height: "20px",
        minWidth: "20px",
        minHeight: "20px",
        borderRadius: "5px",
        margin: "11px 5px 5px 5px",
    }
}));

export default function Chart({ data1, rating, linearRating, isLinear, nonLinearRating, lineColor, linearColor, nonLinearColor, nameLine, legendTexts, showLegend, colors, chartHeight }) {
    const data = [];
    const classes = useStyles();

    const renderLegend = (props) => {
        const { payload } = props;

        if (showLegend === true) {
            return (
                < div style={{ marginTop: "60px", display: "flex", justifyContent: "space-around", height: "44px", padding: "0px 10px" }}>
                    {
                        payload.map((entry, index) => (
                            <div key={`item-${index}`} style={{ display: "flex", justifyContent: "center", maxWidth: "178px", minWidth: "115px" }}>
                                <div style={{ background: `${colors[index]}` }} className={classes.legendBox}></div>
                                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                    <div className={classes.legendText}>{legendTexts[index]}</div>
                                </div> 
                            </div>
                        ))
                    }
                </div >
            );
        } else {
            return (<div></div>);
        }
    }

    let max = 0;
    for (let i = 0; i < data1[0]?.length - 2; i++) {
        if (isLinear === "All") {
            data.push({ name: data1[0][i + 2], [nameLine]: data1[rating][i + 2], pv: 2400, amt: 2400 })
        } else {
            if (data1.length > 6) {
                data.push({ name: data1[0][i + 2], [nameLine]: data1[rating][i + 2], Linear: data1[linearRating][i + 2], NonLinear: data1[nonLinearRating][i + 2], amt: 2400 })
            } else {
                data.push({ name: data1[0][i + 2], [nameLine]: data1[rating][i + 2], pv: 2400, amt: 2400 })
            }
        }
    }
    for (let i = 2; i < data1[rating]?.length; i++) {
        if (Number(data1[rating][i]) > max) {
            max = Number(data1[rating][i])
        }
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart width={558} height={340} data={data}
                margin={{
                    top: 50,
                    bottom: chartHeight,
                }}>
                <Line dataKey={nameLine} stroke={lineColor} fill="#FFFFFF" strokeWidth={2} isAnimationActive={false} dot={{ stroke: "rgba(120, 60, 198, 0.5)", strokeWidth: "0.5" }} />
                {isLinear === "All" ? null : <Line dataKey="Linear" stroke={linearColor} fill="#FFFFFF" strokeWidth={2} isAnimationActive={false} dot={{ stroke: "rgba(120, 60, 198, 0.5)", strokeWidth: "0.5" }} />}
                {isLinear === "All" ? null : <Line dataKey="NonLinear" stroke={nonLinearColor} fill="#FFFFFF" strokeWidth={2} isAnimationActive={false} dot={{ stroke: "rgba(120, 60, 198, 0.5)", strokeWidth: "0.5" }} />}
                <CartesianGrid stroke="rgba(120, 60, 198, 0.2)" vertical={false} />
                <XAxis width={40} dataKey="name" axisLine={false}
                    tickLine={false} style={{ writingMode: "tb-rl" }} padding={(data.length > 7 ? { right: 30 } : { right: 30, left: 30 })}
                    tick={<CustomizedAxisTick gradus={(data.length > 7 ? -90 : -35)} />} interval={0}
                />
                <YAxis type="number" axisLine={false} tickLine={false} domain={[0, max]} />
                {/* <Legend iconType="square" wrapperStyle={{bottom: "40px"}}/> */}
                <Tooltip />
                <Legend content={renderLegend} verticalAlign="bottom" height={50} />
            </LineChart>
        </ResponsiveContainer>
    )
}