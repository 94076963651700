import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Navbar from "./utils/Navbar.js";
import { useSelector, useDispatch } from "react-redux";
import { campaignData, campaignOptions } from "../../actions/planner.js";
import Chart from "./Charts/Chart.js";
import { CSVLink } from "react-csv";
import Heatmap from "./utils/Heatmap.js";
import DatePickerCustom from "./utils/DatePickerCustom.js";
import styles from "./PlanPerformance.css";
import { useMediaQuery } from "@material-ui/core";
import Helper from "../../helpers/helper.js";

const StyledMenu = withStyles({
  paper: {
    backgroundColor: "#E9D9FF",
    opacity: "0.6",
    border: "0.5px solid #783CC6",
    boxSizing: "border-box",
    backdropFilter: "blur(40px)",
    borderRadius: "10px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#1E2019",
    width: "5%",
    maxHeight: "150px",
    marginTop: "0",
    marginLeft: "0",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: "1000px",
  },
  heading: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accadion_one: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "97%",
    borderBottomLeftRadius: "10px !important",
    borderBottomRightRadius: "10px !important",
    borderTopLeftRadius: "10px !important",
    borderTopRightRadius: "10px !important",
  },
  accadion_details: {
    height: "144px",
    display: "flex",
    flexWrap: "wrap",
  },
  accadion_summury: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  box: {
    backgroundColor: "#E9D9FF",
    opacity: "0.6",
    border: "0.5px solid #783CC6",
    boxSizing: "border-box",
    backdropFilter: "blur(40px)",
    borderRadius: "10px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#1E2019",
    width: "5.5%",
    height: "36px",
    marginLeft: "0.63%",
  },
  discription: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1E2019",
  },
  table: {
    minWidth: 700,
  },
  headTableSell: {
    color: "#783CC6",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    padding: "14px 14px 7px 14px",
  },
  defaultTableSell: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1E2019",
    padding: "14px 14px 7px 14px",
  },
  chart_container: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "2rem",
    marginTop: "-5rem",
    width: "97%",
    height: "100%",
    minWidth: "97%",
  },
  //for little display
  chart_container_little_display: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginBottom: "2rem",
    marginTop: "-5rem",
    width: "97%",
    height: "100%",
    minWidth: "97%",
  },
  chart_little_display: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "45.88%",
    height: "340px",
    margin: "5px",
  },
  chart: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "31.88%",
    height: "340px",
  },
  chart_description: {
    fontFamily: "Open Sans",
    fontWtyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  chart_description_box: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "10px",
  },
  downlod_text: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "16px",
    lineHeight: "22px",
    textDecorationLine: "underline",
    color: "#1E2019",
    cursor: "pointer",
  },
  map_description: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
    paddingLeft: "13px",
    paddingTop: "14px",
  },
  map_container: {
    height: "800px",
    width: "97%",
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

export default function PlanPerfomance() {
  const classes = useStyles();
  const isActive = useMediaQuery("(max-width: 1620px)");
  const [expanded, setExpanded] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);

  console.log("Planner Plan");

  const dispatch = useDispatch();
  const data = useSelector((state) => state.planner.Data);
  const data2 = [...data].slice(1, data.length);
  const target = useSelector((state) => state.planner.Targets);
  const advertiser = useSelector((state) => state.planner.Attributions);
  const isRender = useSelector((state) => state.planner.Render);
  const optionsV2 = useSelector((state) => state.planner.OptionsV2);
  const date = {
    dateFrom: useSelector((state) => state.planner.DateFrom),
    dateTo: useSelector((state) => state.planner.DateTo),
  };
  const currentOptions = optionsV2.filter(
    (item) => item.Name.split(" ")[0] === date.dateTo
  );
  const weeks = currentOptions[0]?.NextCollection.filter(
    (item) => item.Name === "Weeks"
  );
  const days = currentOptions[0]?.NextCollection.filter(
    (item) => item.Name === "Days"
  );
  const isLeftMenuOpen = useSelector((state) => state.user.open);
  const HeatmapData = useSelector((state) => state.planner.HeatMapData);

  const legendColors = ["#00AAF4", "#FB58A4", "#B8E561"];
  const legendTxtRating = ["Ratings", "Linear Ratings", "Non-Linear Ratings"];
  const legendTxtCumulative = [
    "Cumulative Ratings",
    "Linear Cumulative Ratings",
    "Non-Linear Cumulative Ratings",
  ];
  const legendTxtReach = [
    "Cumulative Reach",
    "Linear Cumulative Reach",
    "Non-Linear Cumulative Reach",
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  const [anchorElQuintiles, setAnchorElQuintiles] = React.useState(null);
  const [currentItem, setCurrentItem] = React.useState(null);
  const [currentItem2, setCurrentItem2] = React.useState(null);
  const [currentItem3, setCurrentItem3] = React.useState(
    JSON.parse(localStorage.getItem("Planner_CampaignAndAds_LinearType")) || {
      id: 1,
      Name: "All",
    }
  );
  const [currentItem4, setCurrentItem4] = React.useState(null);
  const [currentItem5, setCurrentItem5] = React.useState(null);
  const [currentQuintile, setCurrentQuintile] = React.useState("All Quintiles");
  if (currentItem3.Name === "All") {
    data2.splice(5, 10);
    data.splice(6);
  }
  const setDefaultValue = () => {
    setCurrentItem("Weeks");
    setCurrentItem2(4);
  };
  const setDefaultValue2 = () => {
    setCurrentItem3({ ...currentItem3, id: 1, Name: "All" });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };
  const handleClick5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };
  const handleClickQuintilie = (event) => {
    setAnchorElQuintiles(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    let val = event.currentTarget.dataset.myValue;
    let localStorageData = JSON.parse(
      localStorage.getItem("Common_CampaignAndDashboard_AttributionValue")
    );
    let localStorageDataTarget = JSON.parse(
      localStorage.getItem("Сommon_TargetValue")
    );
    let defaultTimeNumberValueWeeks = currentOptions[0]?.NextCollection.filter(
      (item) => item.Name === "Weeks"
    )[0]?.NextCollection[0]?.Name;
    let defaultTimeNumberValueDays = currentOptions[0]?.NextCollection.filter(
      (item) => item.Name === "Days"
    )[0]?.NextCollection[0]?.Name;

    if (val !== null && val !== undefined) {
      localStorage.setItem("Common_TimeType", val);
      setCurrentItem(val);
      if (currentItem === "Weeks") {
        localStorage.setItem("Common_TimeNumber", defaultTimeNumberValueDays);
        setCurrentItem2(defaultTimeNumberValueDays);
      } else {
        localStorage.setItem("Common_TimeNumber", defaultTimeNumberValueWeeks);
        setCurrentItem2(defaultTimeNumberValueWeeks);
      }

      //attribution and target
      if (
        Helper.inArray(
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection,
          localStorageData?.Id
        )
      ) {
        //setAttributions ...
        setCurrentItem5({
          ...currentItem5,
          id: localStorageData?.Id,
          Name: localStorageData?.Value,
        });
        if (
          Helper.inArray(
            currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]
              ?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection,
            localStorageDataTarget?.id
          )
        ) {
          //SetTargetValue ...
          setCurrentItem4({
            ...currentItem4,
            id: localStorageDataTarget?.id,
            Name: localStorageDataTarget?.Name,
          });
        } else {
          setCurrentItem4({
            ...currentItem4,
            id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]
              ?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection[0]?.Id,
            Name: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]
              ?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection[0]?.Name,
          });
        }
      } else {
        console.log("ATTRIBUTION");
        localStorage.setItem(
          "Common_CampaignAndDashboard_AttributionValue",
          JSON.stringify({
            Id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]?.NextCollection.filter(
              (item) => item.Name === localStorage.getItem("Common_TimeNumber")
            )[0]?.NextCollection[0]?.Id,
            Value: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]?.NextCollection.filter(
              (item) => item.Name === localStorage.getItem("Common_TimeNumber")
            )[0]?.NextCollection[0]?.Name,
          })
        );
        setCurrentItem5({
          ...currentItem5,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.Name,
        });
        setCurrentItem4({
          ...currentItem4,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.NextCollection[0]?.Name,
        });
      }

      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  };
  const handleClose2 = (event) => {
    setAnchorEl2(null);
    let val = event.currentTarget.dataset.myValue;
    let localStorageData = JSON.parse(
      localStorage.getItem("Common_CampaignAndDashboard_AttributionValue")
    );
    let localStorageDataTarget = JSON.parse(
      localStorage.getItem("Сommon_TargetValue")
    );
    if (val !== null && val !== undefined) {
      localStorage.setItem("Common_TimeNumber", val);
      setCurrentItem2(val);

      //attribution and target
      if (
        Helper.inArray(
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection,
          localStorageData?.Id
        )
      ) {
        //setAttributions ...
        setCurrentItem5({
          ...currentItem5,
          id: localStorageData?.Id,
          Name: localStorageData?.Value,
        });
        if (
          Helper.inArray(
            currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]
              ?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection,
            localStorageDataTarget?.id
          )
        ) {
          //SetTargetValue ...
          setCurrentItem4({
            ...currentItem4,
            id: localStorageDataTarget?.id,
            Name: localStorageDataTarget?.Name,
          });
        } else {
          setCurrentItem4({
            ...currentItem4,
            id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]
              ?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection[0]?.Id,
            Name: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]
              ?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection[0]?.Name,
          });
        }
      } else {
        console.log("ATTRIBUTION");
        localStorage.setItem(
          "Common_CampaignAndDashboard_AttributionValue",
          JSON.stringify({
            Id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection[0]?.Id,
            Value: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection[0]?.Name,
          })
        );
        setCurrentItem5({
          ...currentItem5,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.Name,
        });
        setCurrentItem4({
          ...currentItem4,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.NextCollection[0]?.Name,
        });
      }

      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  };
  const handleClose3 = (event) => {
    setAnchorEl3(null);
    let val = event.currentTarget.dataset.myValue;
    if (val !== null && val !== undefined) {
      localStorage.setItem(
        "Planner_CampaignAndAds_LinearType",
        JSON.stringify({
          id: event.currentTarget.id,
          Name: event.currentTarget.dataset.myValue,
        })
      );
      setCurrentItem3({
        ...currentItem3,
        id: event.currentTarget.id,
        Name: event.currentTarget.dataset.myValue,
      });
    }
  };
  const handleClose4 = (event) => {
    setAnchorEl4(null);

    if (
      event.currentTarget.dataset.myValue !== null &&
      event.currentTarget.dataset.myValue !== undefined
    ) {
      localStorage.setItem(
        "Сommon_TargetValue",
        JSON.stringify({
          id: event.currentTarget.id,
          Name: event.currentTarget.dataset.myValue,
        })
      );
      setCurrentItem4({
        ...currentItem4,
        id: event.currentTarget.id,
        Name: event.currentTarget.dataset.myValue,
      });
      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  };
  const showLegendCheck = (val) => {
    return val.Name !== "All";
  };
  const getChartHeight = (val) => {
    return val.Name !== "All" ? 110 : 70;
  };
  const handleClose5 = (event) => {
    setAnchorEl5(null);

    if (
      event.currentTarget.dataset.myValue !== null &&
      event.currentTarget.dataset.myValue !== undefined
    ) {
      let val = JSON.parse(event.currentTarget.dataset.myValue);
      localStorage.setItem(
        "Common_CampaignAndDashboard_AttributionValue",
        JSON.stringify({
          Id: val.Id,
          Value: val.Name,
        })
      );
      setCurrentItem5({
        ...currentItem5,
        id: val.Id,
        Name: val.Name,
      });
      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  };
  const handleCloseQuintiles = (event) => {
    setAnchorElQuintiles(null);

    if (
      event.currentTarget.dataset.myValue !== null &&
      event.currentTarget.dataset.myValue !== undefined
    ) {
      setCurrentQuintile(event.currentTarget.dataset.myValue);
    }
  };

  React.useEffect(() => {
    dispatch(campaignOptions());
  }, []);

  React.useEffect(() => {
    if (
      currentItem4 !== null &&
      currentItem4 !== undefined &&
      currentItem5 !== null &&
      currentItem5 !== undefined &&
      date.dateTo !== "" &&
      isRender
    ) {
      dispatch(
        campaignData(
          currentItem,
          currentItem2,
          currentItem4.id,
          currentItem3.id,
          currentItem5.id,
          date
        )
      );
    }
  }, [
    currentItem,
    currentItem2,
    currentItem4,
    currentItem3.id,
    currentItem5,
    date.dateTo,
    isRender,
  ]);

  React.useEffect(() => {
    if (optionsV2 !== null && optionsV2 !== undefined && optionsV2.length > 0) {
      let defaultAttributionValue =
        currentOptions[0]?.NextCollection[0]?.NextCollection[0]
          ?.NextCollection[0];
      let defaultTargetValue =
        currentOptions[0]?.NextCollection[0]?.NextCollection[0]
          ?.NextCollection[0]?.NextCollection[0];
      let defaultTimeType = currentOptions[0]?.NextCollection[0]?.Name;
      let defaultTimeNumber =
        currentOptions[0]?.NextCollection[0]?.NextCollection[0]?.Name;
      let localStorageData = JSON.parse(
        localStorage.getItem("Common_CampaignAndDashboard_AttributionValue")
      );
      let localStorageDataTarget = JSON.parse(
        localStorage.getItem("Сommon_TargetValue")
      );
      let timeTypeLocalStorage = localStorage.getItem("Common_TimeType");
      let timeNumberLocalStorage = localStorage.getItem("Common_TimeNumber");
      if (
        currentOptions[0]?.NextCollection.filter(
          (item) => item.Name === timeTypeLocalStorage
        ).length !== 0
      ) {
        //setTimeType ...
        setCurrentItem(timeTypeLocalStorage);
        if (
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === timeTypeLocalStorage
          )[0]?.NextCollection.filter(
            (item) => item.Name === timeNumberLocalStorage
          ).length !== 0
        ) {
          //setTimeNumber ...
          setCurrentItem2(timeNumberLocalStorage);
          if (
            Helper.inArray(
              currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection,
              localStorageData?.Id
            )
          ) {
            //setAttributions ...
            setCurrentItem5({
              ...currentItem5,
              id: localStorageData?.Id,
              Name: localStorageData?.Value,
            });
            if (
              Helper.inArray(
                currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === timeNumberLocalStorage
                  )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === localStorageData.Value
                  )[0]?.NextCollection,
                localStorageDataTarget?.id
              )
            ) {
              //SetTargetValue ...
              setCurrentItem4({
                ...currentItem4,
                id: localStorageDataTarget?.id,
                Name: localStorageDataTarget?.Name,
              });
            } else {
              setCurrentItem4({
                ...currentItem4,
                id: currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === timeNumberLocalStorage
                  )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === localStorageData.Value
                  )[0]?.NextCollection[0]?.Id,
                Name: currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === timeNumberLocalStorage
                  )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === localStorageData.Value
                  )[0]?.NextCollection[0]?.Name,
              });
            }
          } else {
            console.log("ATTRIBUTION");
            localStorage.setItem(
              "Common_CampaignAndDashboard_AttributionValue",
              JSON.stringify({
                Id: currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]?.NextCollection.filter(
                  (item) => item.Name === timeNumberLocalStorage
                )[0]?.NextCollection[0]?.Id,
                Value: currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]?.NextCollection.filter(
                  (item) => item.Name === timeNumberLocalStorage
                )[0]?.NextCollection[0]?.Name,
              })
            );
            setCurrentItem5({
              ...currentItem5,
              id: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.Id,
              Name: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.Name,
            });
            setCurrentItem4({
              ...currentItem4,
              id: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.NextCollection[0]?.Id,
              Name: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.NextCollection[0]?.Name,
            });
          }
        } else {
          console.log("TIME_TYPE");
          localStorage.setItem(
            "Common_TimeNumber",
            currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.Name
          );
          localStorage.setItem(
            "Common_CampaignAndDashboard_AttributionValue",
            JSON.stringify({
              Id: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection[0]?.NextCollection[0]?.Id,
              Value: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection[0]?.NextCollection[0]?.Name,
            })
          );
          setCurrentItem2(
            currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.Name
          );
          setCurrentItem5({
            ...currentItem5,
            id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.NextCollection[0]?.Id,
            Name: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.NextCollection[0]?.Name,
          });
          setCurrentItem4({
            ...currentItem4,
            id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.NextCollection[0]?.NextCollection[0]?.Id,
            Name: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.NextCollection[0]?.NextCollection[0]?.Name,
          });
        }
      } else {
        //setTimeNumber ... default
        //setTimeType ... default
        console.log("DEFAULT");
        localStorage.setItem("Common_TimeNumber", defaultTimeNumber);
        localStorage.setItem(
          "Common_CampaignAndDashboard_AttributionValue",
          JSON.stringify({
            Id: defaultAttributionValue.Id,
            Value: defaultAttributionValue.Name,
          })
        );
        setCurrentItem(defaultTimeType);
        setCurrentItem2(defaultTimeNumber);
        setCurrentItem5({
          ...currentItem5,
          id: defaultAttributionValue.Id,
          Name: defaultAttributionValue.Name,
        });
        setCurrentItem4({
          ...currentItem4,
          id: defaultTargetValue?.Id,
          Name: defaultTargetValue?.Name,
        });
      }
      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  }, [date.dateTo]);
  //test
  return (
    <div>
      <Navbar />
      <div className={classes.root}>
        <Box
          className={
            isActive
              ? classes.chart_container_little_display
              : classes.chart_container
          }
        >
          <Box
            style={
              isActive && isLeftMenuOpen
                ? { width: "55.88%", margin: "5px" }
                : {}
            }
            className={isActive ? classes.chart_little_display : classes.chart}
          >
            <Box className={classes.chart_description_box}>
              <Box className={classes.chart_description}>Weekly ratings</Box>
              <Box>
                <img alt="optionsIcon" src="/img/common/Group0.svg" />
              </Box>
            </Box>
            <Chart
              data1={data}
              nameLine={"Ratings"}
              rating={1}
              linearRating={6}
              isLinear={currentItem3.Name}
              nonLinearRating={11}
              lineColor={"#00AAF4"}
              linearColor={"#FB58A4"}
              nonLinearColor={"#B8E561"}
              showLegend={showLegendCheck(currentItem3)}
              legendTexts={legendTxtRating}
              colors={legendColors}
              chartHeight={getChartHeight(currentItem3)}
            />
          </Box>
          <Box
            style={
              isActive && isLeftMenuOpen
                ? { width: "55.88%", margin: "5px" }
                : {}
            }
            className={isActive ? classes.chart_little_display : classes.chart}
          >
            <Box className={classes.chart_description_box}>
              <Box className={classes.chart_description}>
                Cumulative ratings
              </Box>
              <Box>
                <img alt="optionsIcon" src="/img/common/Group0.svg" />
              </Box>
            </Box>
            <Chart
              data1={data}
              nameLine={"Cumulative ratings"}
              rating={2}
              linearRating={7}
              isLinear={currentItem3.Name}
              nonLinearRating={12}
              lineColor={"#00AAF4"}
              linearColor={"#FB58A4"}
              nonLinearColor={"#B8E561"}
              showLegend={showLegendCheck(currentItem3)}
              legendTexts={legendTxtCumulative}
              colors={legendColors}
              chartHeight={getChartHeight(currentItem3)}
            />
          </Box>
          <Box
            style={
              isActive && isLeftMenuOpen
                ? { width: "55.88%", margin: "5px" }
                : {}
            }
            className={isActive ? classes.chart_little_display : classes.chart}
          >
            <Box className={classes.chart_description_box}>
              <Box className={classes.chart_description}>Cumulative Reach</Box>
              <Box>
                <img alt="optionsIcon" src="/img/common/Group0.svg" />
              </Box>
            </Box>
            <Chart
              data1={data}
              nameLine={"Cumulative Reach"}
              rating={3}
              linearRating={8}
              isLinear={currentItem3.Name}
              nonLinearRating={13}
              lineColor={"#00AAF4"}
              linearColor={"#FB58A4"}
              nonLinearColor={"#B8E561"}
              showLegend={showLegendCheck(currentItem3)}
              legendTexts={legendTxtReach}
              colors={legendColors}
              chartHeight={getChartHeight(currentItem3)}
            />
          </Box>
        </Box>
        <Box>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className={classes.accadion_one}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              {!expanded ? (
                <Typography className={classes.heading}>Filters</Typography>
              ) : null}
            </AccordionSummary>
            <AccordionDetails className={classes.accadion_details}>
              <Typography className={classes.discription}>
                Trend period:
              </Typography>
              <Box
                className={classes.box}
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                <Box
                  style={{
                    width: "49px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                >
                  {currentItem ? currentItem : setDefaultValue()}
                </Box>
                <Box>
                  <Button
                    style={{ width: "0px", height: "0px", minWidth: "0px" }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <img alt="arrow" src="/img/accardion/Vector9.svg" />
                  </Button>
                </Box>
              </Box>
              <Box
                className={classes.box}
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "3.88%",
                  cursor: "pointer",
                }}
                onClick={handleClick2}
              >
                <Box
                  style={{
                    width: "30px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                >
                  {currentItem2 ? currentItem2 : setDefaultValue()}
                </Box>
                <Box>
                  <Button
                    style={{ width: "0px", height: "0px", minWidth: "0px" }}
                    aria-controls="simple-menu2"
                    aria-haspopup="true"
                  >
                    <img alt="arrow" src="/img/accardion/Vector9.svg" />
                  </Button>
                </Box>
              </Box>
              <Box
                className={classes.box}
                style={{
                  width: "18.88%",
                  marginLeft: "0.94%",
                  display: "flex",
                  justifyContent: "space-around",
                  cursor: "pointer",
                }}
                onClick={handleClick3}
              >
                <Box
                  style={{
                    width: "250px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                >
                  {currentItem3.Name ? currentItem3.Name : setDefaultValue2()}
                </Box>
                <Box>
                  <Button
                    style={{ width: "0px", height: "0px", minWidth: "0px" }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <img alt="arrow" src="/img/accardion/Vector9.svg" />
                  </Button>
                </Box>
              </Box>
              <Box
                className={classes.box}
                style={
                  isActive
                    ? {
                        width: "54.88%",
                        marginLeft: "1.38%",
                        display: "flex",
                        justifyContent: "space-around",
                        cursor: "pointer",
                      }
                    : {
                        width: "33.88%",
                        marginLeft: "1.38%",
                        display: "flex",
                        justifyContent: "space-around",
                        cursor: "pointer",
                      }
                }
                onClick={handleClick5}
              >
                <Box
                  style={{
                    width: "550px",
                    paddingLeft: "13px",
                    paddingTop: "4px",
                  }}
                >
                  {currentItem5 ? currentItem5.Name : "Choose Advertiser"}
                </Box>
                <Box>
                  <Button
                    style={{ width: "0px", height: "0px", minWidth: "0px" }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <img alt="arrow" src="/img/accardion/Vector9.svg" />
                  </Button>
                </Box>
              </Box>
              <Box
                style={
                  isActive
                    ? {
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "2.5%",
                      }
                    : {
                        display: "flex",
                        justifyContent: "space-between",
                        width: "22.88%",
                        marginRight: "2.5%",
                      }
                }
              >
                <Box
                  style={
                    isActive ? {} : { marginLeft: "7.5%", marginRight: "2.5%" }
                  }
                >
                  <Typography className={classes.discription}>
                    Trend period end date:
                  </Typography>
                </Box>
                <Box>
                  <DatePickerCustom
                    dateType={"SET_DATE_TO"}
                    reducer={"planner"}
                    dateArr={"dateOptions"}
                  />
                </Box>
              </Box>
              <img
                alt="icon"
                src="/img/accardion/Group736.svg"
                style={{ marginTop: "-1.88%", marginRight: "0.32%" }}
              />
              <Typography className={classes.discription}>including</Typography>
              <Box
                className={classes.box}
                style={{
                  width: "17.19%",
                  marginLeft: "1.25%",
                  display: "flex",
                  justifyContent: "space-around",
                  cursor: "pointer",
                }}
                onClick={handleClick4}
              >
                <Box
                  style={{
                    width: "220px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                >
                  {currentItem4 ? currentItem4.Name : "Target audience"}
                </Box>
                <Box>
                  <Button
                    style={{ width: "0px", height: "0px", minWidth: "0px" }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <img alt="arrow" src="/img/accardion/Vector9.svg" />
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box>
          <Accordion
            expanded={expanded2 === "panel1"}
            onChange={handleChange2("panel1")}
            className={classes.accadion_one}
            style={{ marginTop: "30px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              {!expanded2 ? (
                <Typography className={classes.heading}>Data table</Typography>
              ) : null}
            </AccordionSummary>
            <AccordionDetails
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "-24px",
              }}
            >
              <Box>
                <TableContainer component={Paper} style={{ boxShadow: "none" }}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                    style={
                      currentItem2 == 4
                        ? isActive
                          ? {
                              width: "56.25%",
                              marginLeft: "15%",
                              marginRight: "25%",
                            }
                          : {
                              width: "56.25%",
                              marginLeft: "25%",
                              marginRight: "25%",
                            }
                        : currentItem2 == 7
                        ? {
                            width: "89.88%",
                            marginLeft: "5%",
                            marginRight: "5%",
                          }
                        : { width: "100%" }
                    }
                  >
                    <TableHead>
                      <TableRow>
                        {data[0]?.map((i) => (
                          <StyledTableCell align="center" key={i.toString()}>
                            {i}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data2.map((row) =>
                        row.slice(0, 1).map((i) => (
                          <StyledTableRow key={row}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              className={classes.headTableSell}
                              align="center"
                            >
                              {row[0]}
                            </StyledTableCell>
                            {row.slice(1, row.length).map((i) => (
                              <StyledTableCell
                                align="center"
                                className={classes.defaultTableSell}
                                key={i.toString() + Math.random()}
                              >
                                {i}
                              </StyledTableCell>
                            ))}
                          </StyledTableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Box>
                  <img alt="download" src="/img/accardion/Download_light.svg" />
                </Box>
                <Box>
                  <CSVLink
                    data={data}
                    filename={`${currentItem}-rating${new Date()
                      .toJSON()
                      .slice(0, 10)
                      .replace(/-/g, "/")}.csv`}
                    className={classes.downlod_text}
                  >
                    Download as Excel
                  </CSVLink>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box style={{ marginTop: "30px" }}>
          <Box className={classes.map_container}>
            <Box style={{ display: "flex" }}>
              <Box
                className={classes.map_description}
                style={{ paddingRight: "100px" }}
              >
                Heatmap
              </Box>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Box
                  style={{
                    width: "250px",
                    height: "30px",
                    marginTop: "14px",
                    display: "flex",
                    justifyContent: "space-between",
                    background: `linear-gradient(to right, ${
                      HeatmapData.QuintilesColors[0]
                    }, ${
                      HeatmapData.QuintilesColors[
                        HeatmapData.QuintilesColors.length - 1
                      ]
                    })`,
                  }}
                >
                  <Box style={{ width: "48px", height: "30px" }}></Box>
                  <Box
                    style={{
                      width: "2px",
                      height: "30px",
                      backgroundColor: "white",
                    }}
                  ></Box>
                  <Box style={{ width: "48px", height: "30px" }}></Box>
                  <Box
                    style={{
                      width: "2px",
                      height: "30px",
                      backgroundColor: "white",
                    }}
                  ></Box>
                  <Box style={{ width: "48px", height: "30px" }}></Box>
                  <Box
                    style={{
                      width: "2px",
                      height: "30px",
                      backgroundColor: "white",
                    }}
                  ></Box>
                  <Box style={{ width: "48px", height: "30px" }}></Box>
                  <Box
                    style={{
                      width: "2px",
                      height: "30px",
                      backgroundColor: "white",
                    }}
                  ></Box>
                  <Box style={{ width: "48px", height: "30px" }}></Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "250px",
                  }}
                >
                  {HeatmapData.Legend.map((item) => (
                    <Box key={item}>{item}</Box>
                  ))}
                </Box>
              </Box>
              <Box
                style={{
                  marginTop: "14px",
                  marginLeft: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography className={classes.discription}>Show:</Typography>
                </Box>
                <Box
                  className={classes.box}
                  style={{
                    width: "67.88%",
                    marginLeft: "0.94%",
                    display: "flex",
                    justifyContent: "space-around",
                    cursor: "pointer",
                  }}
                  onClick={handleClickQuintilie}
                >
                  <Box
                    style={{
                      width: "250px",
                      paddingLeft: "5px",
                      paddingTop: "4px",
                    }}
                  >
                    {currentQuintile}
                  </Box>
                  <Box>
                    <Button
                      style={{ width: "0px", height: "0px", minWidth: "0px" }}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                    >
                      <img alt="arrow" src="/img/accardion/Vector9.svg" />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box style={{ height: "698px", width: "97%" }}>
              <Heatmap
                timeBlockType={currentItem}
                timeBlockCount={currentItem2}
                attribution={currentItem5}
                tabName={"Data"}
                currentQuintile={currentQuintile}
              />
            </Box>
          </Box>
        </Box>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {currentItem === "Weeks"
            ? days?.map((item) => (
                <MenuItem
                  onClick={handleClose}
                  data-my-value={item.Name}
                  key={item.Id}
                >
                  {item.Name}
                </MenuItem>
              ))
            : weeks?.map((item) => (
                <MenuItem
                  onClick={handleClose}
                  data-my-value={item.Name}
                  key={item.Id}
                >
                  {item.Name}
                </MenuItem>
              ))}
        </StyledMenu>
        <StyledMenu
          id="simple-menu2"
          anchorEl={anchorEl2}
          keepMounted
          open={Boolean(anchorEl2)}
          onClose={handleClose2}
          PaperProps={{
            style: {
              width: "3.5%",
            },
          }}
        >
          {currentItem === "Weeks"
            ? weeks !== undefined
              ? weeks[0]?.NextCollection.map((item) => (
                  <MenuItem
                    onClick={handleClose2}
                    data-my-value={item.Name}
                    key={item.Id}
                  >
                    {" "}
                    {item.Name}{" "}
                  </MenuItem>
                ))
              : null
            : days !== undefined
            ? days[0]?.NextCollection.map((item) => (
                <MenuItem
                  onClick={handleClose2}
                  data-my-value={item.Name}
                  key={item.Id}
                >
                  {" "}
                  {item.Name}{" "}
                </MenuItem>
              ))
            : null}
        </StyledMenu>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl3}
          keepMounted
          open={Boolean(anchorEl3)}
          onClose={handleClose3}
          PaperProps={{
            style: {
              width: "16.5%",
            },
          }}
        >
          {currentItem3.Name === "All" ? (
            <MenuItem
              onClick={handleClose3}
              id="2"
              data-my-value={"All/Linear/Non-Linear"}
            >
              All/Linear/Non-Linear
            </MenuItem>
          ) : (
            <MenuItem onClick={handleClose3} id="1" data-my-value={"All"}>
              All
            </MenuItem>
          )}
        </StyledMenu>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl4}
          keepMounted
          open={Boolean(anchorEl4)}
          onClose={handleClose4}
          PaperProps={{
            style: {
              width: "15.2%",
            },
          }}
        >
          {currentItem === "Weeks"
            ? weeks !== undefined
              ? weeks[0]?.NextCollection.filter(
                  (item) =>
                    item.Name === localStorage.getItem("Common_TimeNumber")
                )[0]
                  ?.NextCollection.filter(
                    (item) =>
                      item.Name ===
                      JSON.parse(
                        localStorage.getItem(
                          "Common_CampaignAndDashboard_AttributionValue"
                        )
                      )?.Value
                  )[0]
                  ?.NextCollection.map((item) => (
                    <MenuItem
                      onClick={handleClose4}
                      id={item?.Id}
                      data-my-value={item?.Name}
                      key={item?.Id}
                    >
                      {item?.Name}
                    </MenuItem>
                  ))
              : null
            : days !== undefined
            ? days[0]?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]
                ?.NextCollection.filter(
                  (item) =>
                    item.Name ===
                    JSON.parse(
                      localStorage.getItem(
                        "Common_CampaignAndDashboard_AttributionValue"
                      )
                    )?.Value
                )[0]
                ?.NextCollection.map((item) => (
                  <MenuItem
                    onClick={handleClose4}
                    id={item?.Id}
                    data-my-value={item?.Name}
                    key={item?.Id}
                  >
                    {item?.Name}
                  </MenuItem>
                ))
            : null}
        </StyledMenu>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl5}
          keepMounted
          open={Boolean(anchorEl5)}
          onClose={handleClose5}
          PaperProps={{
            style: isActive
              ? { width: "46.5%" }
              : {
                  width: "29.5%",
                },
          }}
        >
          {currentItem === "Weeks"
            ? weeks !== undefined
              ? weeks[0]?.NextCollection.filter(
                  (item) =>
                    item.Name === localStorage.getItem("Common_TimeNumber")
                )[0]?.NextCollection.map((item) => (
                  <MenuItem
                    onClick={handleClose5}
                    id={item.Id}
                    data-my-value={JSON.stringify(item)}
                    key={item.Id}
                  >
                    {item.Name}
                  </MenuItem>
                ))
              : null
            : days !== undefined
            ? days[0]?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]?.NextCollection.map((item) => (
                <MenuItem
                  onClick={handleClose5}
                  data-my-value={JSON.stringify(item)}
                  key={item.Id}
                >
                  {item.Name}
                </MenuItem>
              ))
            : null}
        </StyledMenu>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorElQuintiles}
          keepMounted
          open={Boolean(anchorElQuintiles)}
          onClose={handleCloseQuintiles}
          PaperProps={{
            style: isActive
              ? { width: "15.5%" }
              : {
                  width: "11.5%",
                },
          }}
        >
          {[
            [
              <MenuItem
                onClick={handleCloseQuintiles}
                data-my-value={"All Quintiles"}
              >
                All Quintiles
              </MenuItem>,
            ],
            [
              <MenuItem
                onClick={handleCloseQuintiles}
                data-my-value={"Quintile 1 (Lowest)"}
              >
                Quintile 1 (Lowest)
              </MenuItem>,
            ],
            [
              <MenuItem
                onClick={handleCloseQuintiles}
                data-my-value={"Quintile 2"}
              >
                Quintile 2
              </MenuItem>,
            ],
            [
              <MenuItem
                onClick={handleCloseQuintiles}
                data-my-value={"Quintile 3"}
              >
                Quintile 3
              </MenuItem>,
            ],
            [
              <MenuItem
                onClick={handleCloseQuintiles}
                data-my-value={"Quintile 4"}
              >
                Quintile 4
              </MenuItem>,
            ],
            [
              <MenuItem
                onClick={handleCloseQuintiles}
                data-my-value={"Quintile 5 (Highest)"}
              >
                Quintile 5 (Highest)
              </MenuItem>,
            ],
          ]}
        </StyledMenu>
      </div>
    </div>
  );
}
