import React from "react";
import MainPermissionsEditComponent from "./permissionsEdit-components/MainPermissionsEditComponent/MainPermissionsEditComponent.js";
import { useSelector, useDispatch } from "react-redux";
import {
  permissionsList,
  permissionsDeleteViewRole,
  permissionsDeleteEditRole,
} from "../../../../actions/admin.js";

export default function PermissionsEditPage({ children }) {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.admin.PermissionsList);

  //modal
  const [openDeleteViewRoles, setOpenDeleteViewRoles] = React.useState(false);
  const [openDeleteEditRoles, setOpenDeleteEditRoles] = React.useState(false);

  //accardion
  const [expandedAccordion, setExpandedAccordion] = React.useState(false);
  const [expandedAccordion2, setExpandedAccordion2] = React.useState(false);
  const [expandedAccordion3, setExpandedAccordion3] = React.useState(false);
  //accardion handler
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };
  const handleChangeAccordion2 = (panel) => (event, isExpanded) => {
    setExpandedAccordion2(isExpanded ? panel : false);
  };
  const handleChangeAccordion3 = (panel) => (event, isExpanded) => {
    setExpandedAccordion3(isExpanded ? panel : false);
  };

  //delete modal
  const handleCloseDeleteViewRoles = () => {
    setOpenDeleteViewRoles(false);
  };
  const handleCloseAcceptDeleteViewRoles = () => {
    dispatch(
      permissionsDeleteViewRole(
        localStorage.getItem("rowId"),
        localStorage.getItem("rowIdViewRoles")
      )
    );
    setOpenDeleteViewRoles(false);
  };
  const handleCloseDeleteEditRoles = () => {
    setOpenDeleteEditRoles(false);
  };
  const handleCloseAcceptDeleteEditRoles = () => {
    dispatch(
      permissionsDeleteEditRole(
        localStorage.getItem("rowId"),
        localStorage.getItem("rowIdViewRoles")
      )
    );
    setOpenDeleteEditRoles(false);
  };

  //table handler
  const handleTableRowViewRolesCapture = (rowId) => {
    localStorage.setItem("rowIdViewRoles", rowId);
  };
  const handleOpenDeleteViewRoles = () => {
    setOpenDeleteViewRoles(true);
  };
  const handleOpenDeleteEditRoles = () => {
    setOpenDeleteEditRoles(true);
  };

  React.useEffect(() => {
    dispatch(permissionsList());
  }, []);
  return (
    <MainPermissionsEditComponent
      permissionsList={permissions.filter(
        (i) => i.Id === Number(localStorage.getItem("rowId"))
      )}
      handleTableRowViewRolesCapture={handleTableRowViewRolesCapture}
      openDeleteViewRoles={openDeleteViewRoles}
      handleOpenDeleteViewRoles={handleOpenDeleteViewRoles}
      handleCloseDeleteViewRoles={handleCloseDeleteViewRoles}
      handleCloseAcceptDeleteViewRoles={handleCloseAcceptDeleteViewRoles}
      openDeleteEditRoles={openDeleteEditRoles}
      handleOpenDeleteEditRoles={handleOpenDeleteEditRoles}
      handleCloseDeleteEditRoles={handleCloseDeleteEditRoles}
      handleCloseAcceptDeleteEditRoles={handleCloseAcceptDeleteEditRoles}
      expandedAccordion={expandedAccordion}
      expandedAccordion2={expandedAccordion2}
      expandedAccordion3={expandedAccordion3}
      handleChangeAccordion={handleChangeAccordion}
      handleChangeAccordion2={handleChangeAccordion2}
      handleChangeAccordion3={handleChangeAccordion3}
    />
  );
}
