const SET_DATA = "SET_DATA";
const SET_ADS_DATA = "SET_ADS_DATA";
const SET_CHANNEL_DATA = "SET_CHANNEL_DATA";
const GET_OPTIONS = "GET_OPTIONS";
const GET_OPTIONS_ADS = "GET_OPTIONS_ADS";
const GET_OPTIONS_CHANNEL = "GET_OPTIONS_CHANNEL";
const GET_OPTIONS_DAYANDTIME = "GET_OPTIONS_DAYANDTIME";
const INIT_MAP = "INIT_MAP";
const MAP_DATA = "MAP_DATA";
const SET_DATE_FROM = "SET_DATE_FROM";
const SET_DATE_TO = "SET_DATE_TO";
const SET_DATE_TO_ADS = "SET_DATE_TO_ADS";
const SET_DATE_TO_CHANNEL = "SET_DATE_TO_CHANNEL";
const SET_DATE_TO_DAYANDTIME = "SET_DATE_TO_DAYANDTIME";
const SET_DAYANDTIME_DATA = "SET_DAYANDTIME_DATA";

const defaultState = {
  Data: [],
  AdsData: [],
  ChannelData: {
    Adv1Performance: [],
    Adv2Performance: [],
    AllPerformance: [],
  },
  DayAndTimeData: {
    Adv1Weekday: [],
    Adv2Weekday: [],
    AllWeekday: [],
    Adv1Daypart: [],
    Adv2Daypart: [],
    AllDaypart: [],
    Adv1Hourly: [],
    Adv2Hourly: [],
    AllHourly: [],
  },
  Targets: [],
  Attributions: [],
  GeoJson: [],
  HeatMapData: {
    QuintilesColors: [],
    Legend: [],
  },
  DateFrom: "",
  DateTo: "",
  DateToAds: "",
  DateToСhannels: "",
  DateToDayAndTime: "",
  OptionsV2: [],
  OptionsV2Ads: [],
  OptionsV2Сhannels: [],
  OptionsV2DayAndTime: [],
  dateOptions: [],
  dateOptionsAds: [],
  dateOptionsСhannels: [],
  dateOptionsDayAndTime: [],
  Render: false,
};

export default function plannerReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        Data: [...action.payload],
      };
    case SET_ADS_DATA:
      return {
        ...state,
        AdsData: [...action.payload],
      };
    case SET_CHANNEL_DATA:
      return {
        ...state,
        ChannelData: {
          ...state.ChannelData,
          Adv1Performance: [...action.payload.Adv1Performance],
          Adv2Performance: [...action.payload.Adv2Performance],
          AllPerformance: [...action.payload.AllPerformance],
        },
      };
    case GET_OPTIONS:
      return {
        ...state,
        Targets: [...action.payload.Targets],
        Attributions: [...action.payload.Attributions],
        OptionsV2: [...action.payload.OptionsV2],
        dateOptions: [
          ...action.payload.OptionsV2.map((item) => new Date(item.Name)),
        ],
      };
    case GET_OPTIONS_ADS:
      return {
        ...state,
        OptionsV2Ads: [...action.payload.OptionsV2],
        dateOptionsAds: [
          ...action.payload.OptionsV2.map((item) => new Date(item.Name)),
        ],
      };
    case GET_OPTIONS_CHANNEL:
      return {
        ...state,
        OptionsV2Сhannels: [...action.payload.OptionsV2],
        dateOptionsСhannels: [
          ...action.payload.OptionsV2.map((item) => new Date(item.Name)),
        ],
      };
    case GET_OPTIONS_DAYANDTIME:
      return {
        ...state,
        OptionsV2DayAndTime: [...action.payload.OptionsV2],
        dateOptionsDayAndTime: [
          ...action.payload.OptionsV2.map((item) => new Date(item.Name)),
        ],
      };
    case INIT_MAP:
      return {
        ...state,
        GeoJson: [...action.payload],
      };
    case MAP_DATA:
      return {
        ...state,
        HeatMapData: {
          ...state.HeatMapData,
          QuintilesColors: [...action.payload.QuintilesColors],
          Legend: [...action.payload.Legend],
        },
      };
    case SET_DATE_FROM:
      return {
        ...state,
        DateFrom: action.payload,
      };
    case SET_DATE_TO:
      return {
        ...state,
        DateTo: action.payload,
      };
    case SET_DATE_TO_ADS:
      return {
        ...state,
        DateToAds: action.payload,
      };
    case SET_DATE_TO_CHANNEL:
      return {
        ...state,
        DateToСhannels: action.payload,
      };
    case SET_DATE_TO_DAYANDTIME:
      return {
        ...state,
        DateToDayAndTime: action.payload,
      };
    case SET_DAYANDTIME_DATA:
      return {
        ...state,
        DayAndTimeData: {
          ...state.DayAndTimeData,
          Adv1Weekday: [...action.payload.Adv1Weekday],
          Adv2Weekday: [...action.payload.Adv2Weekday],
          AllWeekday: [...action.payload.AllWeekday],
          Adv1Daypart: [...action.payload.Adv1Daypart],
          Adv2Daypart: [...action.payload.Adv2Daypart],
          AllDaypart: [...action.payload.AllDaypart],
          Adv1Hourly: [...action.payload.Adv1Hourly],
          Adv2Hourly: [...action.payload.Adv2Hourly],
          AllHourly: [...action.payload.AllHourly],
        },
      };
    case "SET_RENDER":
      return {
        ...state,
        Render: action.payload,
      };
    default:
      return state;
  }
}
