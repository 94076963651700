import axios from "axios";
import endpoints from "../config/endpoind.json";

export const usersList = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.UsersList,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: "SET_USERS_LIST",
        payload: response.data,
      });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};

export const permissionsList = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.PermissionsList,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: "SET_PERMISSIONS_LIST",
        payload: response.data,
      });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
export const permissionsCreate = (permissionsName) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.PermissionsCreate,
        {
          Name: permissionsName,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: "SET_PERMISSIONS_LIST",
        payload: response.data,
      });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
export const permissionsDelete = (permissionsId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.PermissionsDelete,
        {
          Id: permissionsId,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: "SET_PERMISSIONS_LIST",
        payload: response.data,
      });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
//edit permissions pages
export const permissionsDeleteViewRole = (PermissionId, RoleId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.PermissionsViewRole,
        {
          PermissionId: PermissionId,
          RoleId: RoleId,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: "SET_PERMISSIONS_LIST",
        payload: response.data,
      });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
export const permissionsDeleteEditRole = (PermissionId, RoleId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrl + endpoints.PermissionsEditRole,
        {
          PermissionId: PermissionId,
          RoleId: RoleId,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: "SET_PERMISSIONS_LIST",
        payload: response.data,
      });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
