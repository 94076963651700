import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  search_box: {
    backgroundColor: "#E9D9FF",
    opacity: "0.6",
    border: "0.5px solid #783CC6",
    boxSizing: "border-box",
    backdropFilter: "blur(40px)",
    borderRadius: "10px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#1E2019",
    width: "100%",
    height: "36px",
    marginLeft: "0.63%",
    outline: "none",
    paddingLeft: "20px",
    paddingTop: "6px",
    paddingRight: "20px",
    paddingBottom: "5px",
  },
  custom_input: {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 10,
      margin: "auto 0",
      width: "20px",
      height: "20px",
      background: `url(${"/img/accardion/Group_search.svg"}) no-repeat`,
      backgroundSize: "cover",
    },
  },
}));

export default function SearchInput({ children, handleChange }) {
  const classes = useStyles();
  return (
    <div className={classes.custom_input}>
      <input
        type="text"
        className={classes.search_box}
        placeholder="Search"
        onChange={handleChange}
      />
    </div>
  );
}
