import React from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";

export default function Dashboard({ children }) {
  const open = useSelector((state) => state.user.open);
  return (
    <div
      className={
        open
          ? (styles.wrapper, styles.click_collapse)
          : (styles.wrapper, styles.hover_collapse)
      }
    >
      {children}
    </div>
  );
}
