import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function NotFound() {
  const history = useHistory();
  const isLogout = useSelector((state) => state.user.isLogout);
  const isLogin = useSelector((state) => state.user.isLogin);
  const isSessionEnd = useSelector((state) => state.user.isSessionEnd);
  const isAuth = useSelector((state) => state.user.isAuth);

  React.useEffect(() => {
    if (isLogout) {
      history.push("/");
    } else {
      if (isLogin) {
        history.push("/Dashboard");
      }
    }
    if (isAuth && history.location.pathname === "/") {
      history.push("/Dashboard");
    }
  }, []);
  React.useEffect(() => {
    if (isSessionEnd) {
      history.push("/");
    }
  }, [isSessionEnd]);
  return <div>{isAuth ? <h1>Page Not Found</h1> : <CircularProgress />}</div>;
}
