import React from "react";
import Navbar from "./utils/Navbar.js";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DatePickerCustom from "./utils/DatePickerCustom.js";
import BarChart from "./Charts/BarCharts.js";
import { CSVLink } from "react-csv";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  dayAndTimeOptions,
  dayAndTimeData as DayAndTimeData,
} from "../../actions/planner.js";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import Helper from "../../helpers/helper.js";

const StyledMenu = withStyles({
  paper: {
    backgroundColor: "#E9D9FF",
    opacity: "0.6",
    border: "0.5px solid #783CC6",
    boxSizing: "border-box",
    backdropFilter: "blur(40px)",
    borderRadius: "10px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#1E2019",
    width: "5%",
    maxHeight: "150px",
    marginTop: "0",
    marginLeft: "0",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableCellDescription = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: "1000px",
  },
  chart_container: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "97%",
    minWidth: "1000px",
    height: "617px",
    marginTop: "-5rem",
    marginBottom: "2rem",
  },
  chart_description: {
    fontFamily: "Open Sans",
    fontWtyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  chart_top_bar: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "19px",
    paddingLeft: "23px",
    paddingBottom: "43px",
  },
  accadion_one: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 5px 1px rgba(120, 60, 198, 0.05), 0px 0px 5px 1px rgba(120, 60, 198, 0.05)",
    borderRadius: "10px",
    width: "97%",
    borderBottomLeftRadius: "10px !important",
    borderBottomRightRadius: "10px !important",
    borderTopLeftRadius: "10px !important",
    borderTopRightRadius: "10px !important",
  },
  accadion_details: {
    height: "144px",
    display: "flex",
    flexWrap: "wrap",
  },
  heading: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#1E2019",
  },
  discription: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1E2019",
  },
  box: {
    backgroundColor: "#E9D9FF",
    opacity: "0.6",
    border: "0.5px solid #783CC6",
    boxSizing: "border-box",
    backdropFilter: "blur(40px)",
    borderRadius: "10px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#1E2019",
    width: "5.5%",
    height: "36px",
    marginLeft: "0.63%",
  },
  downlod_text: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "16px",
    lineHeight: "22px",
    textDecorationLine: "underline",
    color: "#1E2019",
    cursor: "pointer",
  },
  table: {
    minWidth: 700,
  },
  headTableSell: {
    color: "#783CC6",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "27px",
    padding: "14px 14px 7px 14px",
  },
  defaultTableSell: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1E2019",
    padding: "14px 14px 7px 14px",
  },
  check_box: {
    backgroundColor: "#E9D9FF",
    opacity: "0.6",
    border: "0.5px solid #783CC6",
    boxSizing: "border-box",
    backdropFilter: "blur(40px)",
    borderRadius: "5px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#1E2019",
    width: "30px",
    height: "30px",
    marginLeft: "0.63%",
    marginRight: "0",
  },
  check_box_icon: {
    paddingTop: "7px",
    paddingLeft: "4px",
    paddingRight: "4px",
    paddingBottom: "6px",
  },
}));

export default function DatTime() {
  const classes = useStyles();
  const isActive = useMediaQuery("(max-width: 1620px)");

  const dispatch = useDispatch();
  const target = useSelector((state) => state.planner.Targets);
  const advertiser = useSelector((state) => state.planner.Attributions);
  const isRender = useSelector((state) => state.planner.Render);
  const optionsV2 = useSelector((state) => state.planner.OptionsV2DayAndTime);
  const date = {
    dateFrom: useSelector((state) => state.planner.DateFrom),
    dateTo: useSelector((state) => state.planner.DateToDayAndTime),
  };
  const currentOptions = optionsV2.filter(
    (item) => item.Name.split(" ")[0] === date.dateTo
  );
  const weeks = currentOptions[0]?.NextCollection.filter(
    (item) => item.Name === "Weeks"
  );
  const days = currentOptions[0]?.NextCollection.filter(
    (item) => item.Name === "Days"
  );
  const dayAndTimeData = useSelector((state) => state.planner.DayAndTimeData);
  const dayAndTimeDataForTable = [];
  const constantsForTable = [
    "Midnight to 1am",
    "1am to 2am",
    "2am to 3am",
    "3am to 4am",
    "4am to 5am",
    "5am to 6am",
    "6am to 7am",
    "7am to 8am",
    "8am to 9am",
    "9am to 10am",
    "10am to 11am",
    "11am to Noon",
    "Noon to 1pm",
    "1pm to 2pm",
    "2pm to 3pm",
    "3pm to 4pm",
    "4pm to 5pm",
    "5pm to 6pm",
    "6pm to 7pm",
    "7pm to 8pm",
    "8am to 9am",
    "9pm to 10pm",
    "10pm to 11pm",
    "11pm to Midnight",
  ];
  for (let i = 0; i < dayAndTimeData.Adv1Weekday.length; i++) {
    dayAndTimeDataForTable.push({
      Name: dayAndTimeData.Adv1Daypart[i].Name,
      CumulativeReach: dayAndTimeData.Adv1Daypart[i].CumulativeReach,
      CumulativeRatings: dayAndTimeData.Adv1Daypart[i].CumulativeRatings,
      AvgRatings: dayAndTimeData.Adv1Daypart[i].AvgRatings,
      Adv2CumulativeReach: dayAndTimeData.Adv2Daypart[i].CumulativeReach,
      Adv2CumulativeRatings: dayAndTimeData.Adv2Daypart[i].CumulativeRatings,
      Adv2AvgRatings: dayAndTimeData.Adv2Daypart[i].AvgRatings,
      AllCumulativeReach: dayAndTimeData.AllDaypart[i].CumulativeReach,
      AllCumulativeRatings: dayAndTimeData.AllDaypart[i].CumulativeRatings,
      AllAvgRatings: dayAndTimeData.AllDaypart[i].AvgRatings,
    });
  }
  dayAndTimeDataForTable.push({
    Name: "",
    CumulativeReach: "",
    CumulativeRatings: "",
    AvgRatings: "",
    Adv2CumulativeReach: "",
    Adv2CumulativeRatings: "",
    Adv2AvgRatings: "",
    AllCumulativeReach: "",
    AllCumulativeRatings: "",
    AllAvgRatings: "",
  });
  for (let i = 0; i < dayAndTimeData.Adv1Weekday.length; i++) {
    dayAndTimeDataForTable.push({
      Name: dayAndTimeData.Adv1Weekday[i].Name,
      CumulativeReach: dayAndTimeData.Adv1Weekday[i].CumulativeReach,
      CumulativeRatings: dayAndTimeData.Adv1Weekday[i].CumulativeRatings,
      AvgRatings: dayAndTimeData.Adv1Weekday[i].AvgRatings,
      Adv2CumulativeReach: dayAndTimeData.Adv2Weekday[i].CumulativeReach,
      Adv2CumulativeRatings: dayAndTimeData.Adv2Weekday[i].CumulativeRatings,
      Adv2AvgRatings: dayAndTimeData.Adv2Weekday[i].AvgRatings,
      AllCumulativeReach: dayAndTimeData.AllWeekday[i].CumulativeReach,
      AllCumulativeRatings: dayAndTimeData.AllWeekday[i].CumulativeRatings,
      AllAvgRatings: dayAndTimeData.AllWeekday[i].AvgRatings,
    });
  }
  dayAndTimeDataForTable.push({
    Name: "",
    CumulativeReach: "",
    CumulativeRatings: "",
    AvgRatings: "",
    Adv2CumulativeReach: "",
    Adv2CumulativeRatings: "",
    Adv2AvgRatings: "",
    AllCumulativeReach: "",
    AllCumulativeRatings: "",
    AllAvgRatings: "",
  });
  for (let i = 0; i < dayAndTimeData.Adv1Hourly.length; i++) {
    dayAndTimeDataForTable.push({
      Name: constantsForTable[i],
      CumulativeReach: dayAndTimeData.Adv1Hourly[i].CumulativeReach,
      CumulativeRatings: dayAndTimeData.Adv1Hourly[i].CumulativeRatings,
      AvgRatings: dayAndTimeData.Adv1Hourly[i].AvgRatings,
      Adv2CumulativeReach: dayAndTimeData.Adv2Hourly[i].CumulativeReach,
      Adv2CumulativeRatings: dayAndTimeData.Adv2Hourly[i].CumulativeRatings,
      Adv2AvgRatings: dayAndTimeData.Adv2Hourly[i].AvgRatings,
      AllCumulativeReach: dayAndTimeData.AllHourly[i].CumulativeReach,
      AllCumulativeRatings: dayAndTimeData.AllHourly[i].CumulativeRatings,
      AllAvgRatings: dayAndTimeData.AllHourly[i].AvgRatings,
    });
  }
  const [expanded, setExpanded] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  const [anchorEl6, setAnchorEl6] = React.useState(null);
  const [anchorEl7, setAnchorEl7] = React.useState(null);

  const [currentItem, setCurrentItem] = React.useState(null);
  const [currentItem2, setCurrentItem2] = React.useState(null);
  const [currentItem3, setCurrentItem3] = React.useState(
    JSON.parse(
      localStorage.getItem("Planner_Channels_DayAndTime_LinearType")
    ) || {
      id: 1,
      Name: "All",
    }
  );
  const [currentItem4, setCurrentItem4] = React.useState(null);
  const [currentItem5, setCurrentItem5] = React.useState(null);
  const [currentItem6, setCurrentItem6] = React.useState(null);
  const [sortMode, setSortMode] = React.useState("Adv1 Cumulative Reach");
  const [campaignSelect, setCampaignSelect] = React.useState(true);
  const [competitiveSelect, setCompetitiveSelect] = React.useState(true);
  const [allInventorySelect, setAllInventorySelect] = React.useState(false);
  const [campaignSelect2, setCampaignSelect2] = React.useState(true);
  const [competitiveSelect2, setCompetitiveSelect2] = React.useState(true);
  const [allInventorySelect2, setAllInventorySelect2] = React.useState(false);

  const setDefaultValue = () => {
    setCurrentItem("Weeks");
    setCurrentItem2(4);
  };
  const setDefaultValue2 = () => {
    setCurrentItem3({ ...currentItem3, id: 1, Name: "All" });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };
  const handleClick5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };
  const handleClick6 = (event) => {
    setAnchorEl6(event.currentTarget);
  };
  const handleClickSortMode = (event) => {
    setAnchorEl7(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    let val = event.currentTarget.dataset.myValue;
    let localStorageData = JSON.parse(
      localStorage.getItem("Common_CampaignAndDashboard_AttributionValue")
    );
    let localStorageData2 = JSON.parse(
      localStorage.getItem("Dashboard_Competitive_AttributionValue")
    );
    let localStorageDataTarget = JSON.parse(
      localStorage.getItem("Сommon_TargetValue")
    );
    let defaultTimeNumberValueWeeks = currentOptions[0]?.NextCollection.filter(
      (item) => item.Name === "Weeks"
    )[0]?.NextCollection[0]?.Name;
    let defaultTimeNumberValueDays = currentOptions[0]?.NextCollection.filter(
      (item) => item.Name === "Days"
    )[0]?.NextCollection[0]?.Name;

    if (val !== null && val !== undefined) {
      localStorage.setItem("Common_TimeType", val);
      setCurrentItem(val);
      if (currentItem === "Weeks") {
        localStorage.setItem("Common_TimeNumber", defaultTimeNumberValueDays);
        setCurrentItem2(defaultTimeNumberValueDays);
      } else {
        localStorage.setItem("Common_TimeNumber", defaultTimeNumberValueWeeks);
        setCurrentItem2(defaultTimeNumberValueWeeks);
      }

      //attribution and target
      if (
        Helper.inArray(
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection,
          localStorageData?.Id
        ) &&
        Helper.inArray(
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection,
          localStorageData2?.Id
        )
      ) {
        //setAttributions ...
        setCurrentItem5({
          ...currentItem5,
          id: localStorageData?.Id,
          Name: localStorageData?.Value,
        });
        setCurrentItem6({
          ...currentItem6,
          id: localStorageData2?.Id,
          Name: localStorageData2?.Value,
        });
        if (
          Helper.inArray(
            currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]
              ?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection,
            localStorageDataTarget?.id
          )
        ) {
          //SetTargetValue ...
          setCurrentItem4({
            ...currentItem4,
            id: localStorageDataTarget?.id,
            Name: localStorageDataTarget?.Name,
          });
        } else {
          setCurrentItem4({
            ...currentItem4,
            id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]
              ?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection[0]?.Id,
            Name: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]
              ?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection[0]?.Name,
          });
        }
      } else if (
        Helper.inArray(
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection,
          localStorageData?.Id
        )
      ) {
        //setAttributions ...
        setCurrentItem5({
          ...currentItem5,
          id: localStorageData?.Id,
          Name: localStorageData?.Value,
        });
        setCurrentItem6({
          ...currentItem6,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.Name,
        });

        if (
          Helper.inArray(
            currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]
              ?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection,
            localStorageDataTarget?.id
          )
        ) {
          //SetTargetValue ...
          setCurrentItem4({
            ...currentItem4,
            id: localStorageDataTarget?.id,
            Name: localStorageDataTarget?.Name,
          });
        } else {
          setCurrentItem4({
            ...currentItem4,
            id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]
              ?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection[0]?.Id,
            Name: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]
              ?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection[0]?.Name,
          });
        }
      } else if (
        Helper.inArray(
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection,
          localStorageData2?.Id
        )
      ) {
        //set attribution...
        localStorage.setItem(
          "Common_CampaignAndDashboard_AttributionValue",
          JSON.stringify({
            Id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]?.NextCollection.filter(
              (item) => item.Name === localStorage.getItem("Common_TimeNumber")
            )[0]?.NextCollection[0]?.Id,
            Value: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]?.NextCollection.filter(
              (item) => item.Name === localStorage.getItem("Common_TimeNumber")
            )[0]?.NextCollection[0]?.Name,
          })
        );
        setCurrentItem6({
          ...currentItem6,
          id: localStorageData2?.Id,
          Name: localStorageData2?.Value,
        });
        setCurrentItem5({
          ...currentItem5,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.Name,
        });
        //set target
        if (
          Helper.inArray(
            currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]
              ?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData2.Value
              )[0]?.NextCollection,
            localStorageDataTarget?.id
          )
        ) {
          //SetTargetValue ...
          setCurrentItem4({
            ...currentItem4,
            id: localStorageDataTarget?.id,
            Name: localStorageDataTarget?.Name,
          });
        } else {
          setCurrentItem4({
            ...currentItem4,
            id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]
              ?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData2.Value
              )[0]?.NextCollection[0]?.Id,
            Name: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]
              ?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData2.Value
              )[0]?.NextCollection[0]?.Name,
          });
        }
      } else {
        console.log("ATTRIBUTION");
        localStorage.setItem(
          "Common_CampaignAndDashboard_AttributionValue",
          JSON.stringify({
            Id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]?.NextCollection.filter(
              (item) => item.Name === localStorage.getItem("Common_TimeNumber")
            )[0]?.NextCollection[0]?.Id,
            Value: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]?.NextCollection.filter(
              (item) => item.Name === localStorage.getItem("Common_TimeNumber")
            )[0]?.NextCollection[0]?.Name,
          })
        );
        setCurrentItem5({
          ...currentItem5,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.Name,
        });
        setCurrentItem6({
          ...currentItem6,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.Name,
        });
        setCurrentItem4({
          ...currentItem4,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === val
          )[0]?.NextCollection.filter(
            (item) => item.Name === localStorage.getItem("Common_TimeNumber")
          )[0]?.NextCollection[0]?.NextCollection[0]?.Name,
        });
      }

      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  };
  const handleClose2 = (event) => {
    setAnchorEl2(null);
    let val = event.currentTarget.dataset.myValue;
    let localStorageData = JSON.parse(
      localStorage.getItem("Common_CampaignAndDashboard_AttributionValue")
    );
    let localStorageData2 = JSON.parse(
      localStorage.getItem("Dashboard_Competitive_AttributionValue")
    );
    let localStorageDataTarget = JSON.parse(
      localStorage.getItem("Сommon_TargetValue")
    );
    if (val !== null && val !== undefined) {
      localStorage.setItem("Common_TimeNumber", val);
      setCurrentItem2(val);

      //attribution and target
      if (
        Helper.inArray(
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection,
          localStorageData?.Id
        ) &&
        Helper.inArray(
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection,
          localStorageData2?.Id
        )
      ) {
        //setAttributions ...
        setCurrentItem5({
          ...currentItem5,
          id: localStorageData?.Id,
          Name: localStorageData?.Value,
        });
        setCurrentItem6({
          ...currentItem6,
          id: localStorageData2?.Id,
          Name: localStorageData2?.Value,
        });
        if (
          Helper.inArray(
            currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]
              ?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection,
            localStorageDataTarget?.id
          )
        ) {
          //SetTargetValue ...
          setCurrentItem4({
            ...currentItem4,
            id: localStorageDataTarget?.id,
            Name: localStorageDataTarget?.Name,
          });
        } else {
          setCurrentItem4({
            ...currentItem4,
            id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]
              ?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection[0]?.Id,
            Name: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]
              ?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection[0]?.Name,
          });
        }
      } else if (
        Helper.inArray(
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection,
          localStorageData?.Id
        )
      ) {
        //setAttributions ...
        setCurrentItem5({
          ...currentItem5,
          id: localStorageData?.Id,
          Name: localStorageData?.Value,
        });
        setCurrentItem6({
          ...currentItem6,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.Name,
        });
        // set target
        if (
          Helper.inArray(
            currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]
              ?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection,
            localStorageDataTarget?.id
          )
        ) {
          //SetTargetValue ...
          setCurrentItem4({
            ...currentItem4,
            id: localStorageDataTarget?.id,
            Name: localStorageDataTarget?.Name,
          });
        } else {
          setCurrentItem4({
            ...currentItem4,
            id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]
              ?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection[0]?.Id,
            Name: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]
              ?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData.Value
              )[0]?.NextCollection[0]?.Name,
          });
        }
      } else if (
        Helper.inArray(
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection,
          localStorageData2?.Id
        )
      ) {
        localStorage.setItem(
          "Common_CampaignAndDashboard_AttributionValue",
          JSON.stringify({
            Id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]?.NextCollection.filter(
              (item) => item.Name === localStorage.getItem("Common_TimeNumber")
            )[0]?.NextCollection[0]?.Id,
            Value: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === val
            )[0]?.NextCollection.filter(
              (item) => item.Name === localStorage.getItem("Common_TimeNumber")
            )[0]?.NextCollection[0]?.Name,
          })
        );
        //setAttributions ...
        setCurrentItem6({
          ...currentItem6,
          id: localStorageData2?.Id,
          Name: localStorageData2?.Value,
        });
        setCurrentItem5({
          ...currentItem5,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.Name,
        });
        //set target
        if (
          Helper.inArray(
            currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]
              ?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData2.Value
              )[0]?.NextCollection,
            localStorageDataTarget?.id
          )
        ) {
          //SetTargetValue ...
          setCurrentItem4({
            ...currentItem4,
            id: localStorageDataTarget?.id,
            Name: localStorageDataTarget?.Name,
          });
        } else {
          setCurrentItem4({
            ...currentItem4,
            id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]
              ?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData2.Value
              )[0]?.NextCollection[0]?.Id,
            Name: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]
              ?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection.filter(
                (item) => item.Name === localStorageData2.Value
              )[0]?.NextCollection[0]?.Name,
          });
        }
      } else {
        console.log("ATTRIBUTION");
        localStorage.setItem(
          "Common_CampaignAndDashboard_AttributionValue",
          JSON.stringify({
            Id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection[0]?.Id,
            Value: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === currentItem
            )[0]?.NextCollection.filter((item) => item.Name === val)[0]
              ?.NextCollection[0]?.Name,
          })
        );
        setCurrentItem5({
          ...currentItem5,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.Name,
        });
        setCurrentItem6({
          ...currentItem6,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.Name,
        });
        setCurrentItem4({
          ...currentItem4,
          id: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.NextCollection[0]?.Id,
          Name: currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === currentItem
          )[0]?.NextCollection.filter((item) => item.Name === val)[0]
            ?.NextCollection[0]?.NextCollection[0]?.Name,
        });
      }

      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  };
  const handleClose3 = (event) => {
    setAnchorEl3(null);
    let val = event.currentTarget.dataset.myValue;
    if (val !== null && val !== undefined) {
      localStorage.setItem(
        "Planner_Channels_DayAndTime_LinearType",
        JSON.stringify({
          id: event.currentTarget.id,
          Name: event.currentTarget.dataset.myValue,
        })
      );
      setCurrentItem3({
        ...currentItem3,
        id: event.currentTarget.id,
        Name: event.currentTarget.dataset.myValue,
      });
      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  };
  const handleClose4 = (event) => {
    setAnchorEl4(null);

    if (
      event.currentTarget.dataset.myValue !== null &&
      event.currentTarget.dataset.myValue !== undefined
    ) {
      localStorage.setItem(
        "Сommon_TargetValue",
        JSON.stringify({
          id: event.currentTarget.id,
          Name: event.currentTarget.dataset.myValue,
        })
      );
      setCurrentItem4({
        ...currentItem4,
        id: event.currentTarget.id,
        Name: event.currentTarget.dataset.myValue,
      });
      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  };

  const handleClose5 = (event) => {
    setAnchorEl5(null);

    if (
      event.currentTarget.dataset.myValue !== null &&
      event.currentTarget.dataset.myValue !== undefined
    ) {
      let val = JSON.parse(event.currentTarget.dataset.myValue);
      localStorage.setItem(
        "Common_CampaignAndDashboard_AttributionValue",
        JSON.stringify({
          Id: val.Id,
          Value: val.Name,
        })
      );
      setCurrentItem5({
        ...currentItem5,
        id: val.Id,
        Name: val.Name,
      });
      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  };
  const handleClose6 = (event) => {
    setAnchorEl6(null);
    if (
      event.currentTarget.dataset.myValue !== null &&
      event.currentTarget.dataset.myValue !== undefined
    ) {
      let val = JSON.parse(event.currentTarget.dataset.myValue);
      localStorage.setItem(
        "Dashboard_Competitive_AttributionValue",
        JSON.stringify({
          Id: val.Id,
          Value: val.Name,
        })
      );
      setCurrentItem6({
        ...currentItem6,
        id: val.Id,
        Name: val.Name,
      });
      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  };
  const handleClose7 = (event) => {
    setAnchorEl7(null);
    if (
      event.currentTarget.dataset.myValue !== null &&
      event.currentTarget.dataset.myValue !== undefined
    ) {
      localStorage.setItem(
        "Planner_DayAndTime_SortMode",
        event.currentTarget.dataset.myValue
      );
      setSortMode(event.currentTarget.dataset.myValue);
    }
  };

  React.useEffect(() => {
    dispatch(dayAndTimeOptions());
  }, []);
  React.useEffect(() => {
    if (
      currentItem4 !== null &&
      currentItem4 !== undefined &&
      currentItem5 !== null &&
      currentItem5 !== undefined &&
      currentItem6 !== null &&
      currentItem6 !== undefined &&
      date.dateTo !== "" &&
      isRender
    ) {
      dispatch(
        DayAndTimeData(
          currentItem,
          currentItem2,
          currentItem4.id,
          currentItem3.id,
          currentItem5.id,
          currentItem6.id,
          date
        )
      );
    }
  }, [
    currentItem,
    currentItem2,
    currentItem4,
    currentItem3.id,
    currentItem5,
    currentItem6,
    date.dateTo,
    isRender,
  ]);

  React.useEffect(() => {
    if (
      optionsV2 !== null &&
      optionsV2 !== undefined &&
      optionsV2.length > 0 &&
      currentOptions !== undefined &&
      currentOptions !== null &&
      currentOptions.length > 0
    ) {
      let defaultAttributionValue =
        currentOptions[0]?.NextCollection[0]?.NextCollection[0]
          ?.NextCollection[0];
      let defaultTargetValue =
        currentOptions[0]?.NextCollection[0]?.NextCollection[0]
          ?.NextCollection[0]?.NextCollection[0];
      let defaultTimeType = currentOptions[0]?.NextCollection[0]?.Name;
      let defaultTimeNumber =
        currentOptions[0]?.NextCollection[0]?.NextCollection[0]?.Name;
      let localStorageData = JSON.parse(
        localStorage.getItem("Common_CampaignAndDashboard_AttributionValue")
      );
      let localStorageData2 = JSON.parse(
        localStorage.getItem("Dashboard_Competitive_AttributionValue")
      );
      let localStorageDataTarget = JSON.parse(
        localStorage.getItem("Сommon_TargetValue")
      );
      let timeTypeLocalStorage = localStorage.getItem("Common_TimeType");
      let timeNumberLocalStorage = localStorage.getItem("Common_TimeNumber");
      if (
        currentOptions[0]?.NextCollection.filter(
          (item) => item.Name === timeTypeLocalStorage
        ).length !== 0
      ) {
        //setTimeType ...
        setCurrentItem(timeTypeLocalStorage);
        if (
          currentOptions[0]?.NextCollection.filter(
            (item) => item.Name === timeTypeLocalStorage
          )[0]?.NextCollection.filter(
            (item) => item.Name === timeNumberLocalStorage
          ).length !== 0
        ) {
          //setTimeNumber ...
          setCurrentItem2(timeNumberLocalStorage);
          if (
            Helper.inArray(
              currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection,
              localStorageData?.Id
            ) &&
            Helper.inArray(
              currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection,
              localStorageData2?.Id
            )
          ) {
            //setAttributions ...
            setCurrentItem5({
              ...currentItem5,
              id: localStorageData?.Id,
              Name: localStorageData?.Value,
            });
            setCurrentItem6({
              ...currentItem6,
              id: localStorageData2?.Id,
              Name: localStorageData2?.Value,
            });
            if (
              Helper.inArray(
                currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === timeNumberLocalStorage
                  )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === localStorageData.Value
                  )[0]?.NextCollection,
                localStorageDataTarget?.id
              )
            ) {
              //SetTargetValue ...
              setCurrentItem4({
                ...currentItem4,
                id: localStorageDataTarget?.id,
                Name: localStorageDataTarget?.Name,
              });
            } else {
              setCurrentItem4({
                ...currentItem4,
                id: currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === timeNumberLocalStorage
                  )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === localStorageData.Value
                  )[0]?.NextCollection[0]?.Id,
                Name: currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === timeNumberLocalStorage
                  )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === localStorageData.Value
                  )[0]?.NextCollection[0]?.Name,
              });
            }
          } else if (
            Helper.inArray(
              currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection,
              localStorageData?.Id
            )
          ) {
            //setAttributions ...
            setCurrentItem5({
              ...currentItem5,
              id: localStorageData?.Id,
              Name: localStorageData?.Value,
            });
            setCurrentItem6({
              ...currentItem6,
              id: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.Id,
              Name: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.Name,
            });
            //set Target value
            if (
              Helper.inArray(
                currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === timeNumberLocalStorage
                  )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === localStorageData.Value
                  )[0]?.NextCollection,
                localStorageDataTarget?.id
              )
            ) {
              //SetTargetValue ...
              setCurrentItem4({
                ...currentItem4,
                id: localStorageDataTarget?.id,
                Name: localStorageDataTarget?.Name,
              });
            } else {
              setCurrentItem4({
                ...currentItem4,
                id: currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === timeNumberLocalStorage
                  )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === localStorageData.Value
                  )[0]?.NextCollection[0]?.Id,
                Name: currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === timeNumberLocalStorage
                  )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === localStorageData.Value
                  )[0]?.NextCollection[0]?.Name,
              });
            }
          } else if (
            Helper.inArray(
              currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection,
              localStorageData2?.Id
            )
          ) {
            //set atributions...
            setCurrentItem6({
              ...currentItem6,
              id: localStorageData2?.Id,
              Name: localStorageData2?.Value,
            });
            setCurrentItem5({
              ...currentItem5,
              id: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.Id,
              Name: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.Name,
            });
            //set Target value
            if (
              Helper.inArray(
                currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === timeNumberLocalStorage
                  )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === localStorageData2.Value
                  )[0]?.NextCollection,
                localStorageDataTarget?.id
              )
            ) {
              //SetTargetValue ...
              setCurrentItem4({
                ...currentItem4,
                id: localStorageDataTarget?.id,
                Name: localStorageDataTarget?.Name,
              });
            } else {
              setCurrentItem4({
                ...currentItem4,
                id: currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === timeNumberLocalStorage
                  )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === localStorageData2.Value
                  )[0]?.NextCollection[0]?.Id,
                Name: currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === timeNumberLocalStorage
                  )[0]
                  ?.NextCollection.filter(
                    (item) => item.Name === localStorageData2.Value
                  )[0]?.NextCollection[0]?.Name,
              });
            }
          } else {
            console.log("ATTRIBUTION");
            localStorage.setItem(
              "Common_CampaignAndDashboard_AttributionValue",
              JSON.stringify({
                Id: currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]?.NextCollection.filter(
                  (item) => item.Name === timeNumberLocalStorage
                )[0]?.NextCollection[0]?.Id,
                Value: currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]?.NextCollection.filter(
                  (item) => item.Name === timeNumberLocalStorage
                )[0]?.NextCollection[0]?.Name,
              })
            );
            localStorage.setItem(
              "Dashboard_Competitive_AttributionValue",
              JSON.stringify({
                Id: currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]?.NextCollection.filter(
                  (item) => item.Name === timeNumberLocalStorage
                )[0]?.NextCollection[0]?.Id,
                Value: currentOptions[0]?.NextCollection.filter(
                  (item) => item.Name === timeTypeLocalStorage
                )[0]?.NextCollection.filter(
                  (item) => item.Name === timeNumberLocalStorage
                )[0]?.NextCollection[0]?.Name,
              })
            );
            setCurrentItem5({
              ...currentItem5,
              id: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.Id,
              Name: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.Name,
            });
            setCurrentItem6({
              ...currentItem6,
              id: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.Id,
              Name: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.Name,
            });
            setCurrentItem4({
              ...currentItem4,
              id: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.NextCollection[0]?.Id,
              Name: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection.filter(
                (item) => item.Name === timeNumberLocalStorage
              )[0]?.NextCollection[0]?.NextCollection[0]?.Name,
            });
          }
        } else {
          console.log("TIME_TYPE");
          localStorage.setItem(
            "Common_TimeNumber",
            currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.Name
          );
          localStorage.setItem(
            "Common_CampaignAndDashboard_AttributionValue",
            JSON.stringify({
              Id: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection[0]?.NextCollection[0]?.Id,
              Value: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection[0]?.NextCollection[0]?.Name,
            })
          );
          localStorage.setItem(
            "Dashboard_Competitive_AttributionValue",
            JSON.stringify({
              Id: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection[0]?.NextCollection[0]?.Id,
              Value: currentOptions[0]?.NextCollection.filter(
                (item) => item.Name === timeTypeLocalStorage
              )[0]?.NextCollection[0]?.NextCollection[0]?.Name,
            })
          );
          setCurrentItem2(
            currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.Name
          );
          setCurrentItem5({
            ...currentItem5,
            id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.NextCollection[0]?.Id,
            Name: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.NextCollection[0]?.Name,
          });
          setCurrentItem6({
            ...currentItem6,
            id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.NextCollection[0]?.Id,
            Name: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.NextCollection[0]?.Name,
          });
          setCurrentItem4({
            ...currentItem4,
            id: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.NextCollection[0]?.NextCollection[0]?.Id,
            Name: currentOptions[0]?.NextCollection.filter(
              (item) => item.Name === timeTypeLocalStorage
            )[0]?.NextCollection[0]?.NextCollection[0]?.NextCollection[0]?.Name,
          });
        }
      } else {
        //setTimeNumber ... default
        //setTimeType ... default
        console.log("DEFAULT");
        localStorage.setItem("Common_TimeNumber", defaultTimeNumber);
        localStorage.setItem("Common_TimeType", defaultTimeType);
        localStorage.setItem(
          "Common_CampaignAndDashboard_AttributionValue",
          JSON.stringify({
            Id: defaultAttributionValue.Id,
            Value: defaultAttributionValue.Name,
          })
        );
        localStorage.setItem(
          "Dashboard_Competitive_AttributionValue",
          JSON.stringify({
            Id: defaultAttributionValue.Id,
            Value: defaultAttributionValue.Name,
          })
        );
        setCurrentItem(defaultTimeType);
        setCurrentItem2(defaultTimeNumber);
        setCurrentItem5({
          ...currentItem5,
          id: defaultAttributionValue.Id,
          Name: defaultAttributionValue.Name,
        });
        setCurrentItem6({
          ...currentItem6,
          id: defaultAttributionValue.Id,
          Name: defaultAttributionValue.Name,
        });
        setCurrentItem4({
          ...currentItem4,
          id: defaultTargetValue?.Id,
          Name: defaultTargetValue?.Name,
        });
      }
      dispatch({
        type: "SET_RENDER",
        payload: true,
      });
    }
  }, [date.dateTo]);

  return (
    <div>
      <Navbar />
      <div className={classes.root}>
        <Box className={classes.chart_container}>
          <Box
            className={classes.chart_top_bar}
            style={isActive ? { paddingBottom: "25px" } : {}}
          >
            <Box className={classes.chart_description}>
              Dayparts distribution
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "530px",
                marginRight: "14px",
              }}
            >
              <Box className={classes.discription}>Display:</Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "191px",
                }}
              >
                <Box
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "5px",
                    background: "#00AAF4",
                  }}
                ></Box>
                <Box
                  className={classes.chart_description}
                  style={{ color: "#783CC6" }}
                >
                  {currentItem5 ? currentItem5.Name : "Campaign"}
                </Box>
                <Box
                  className={classes.check_box}
                  onClick={() => setCampaignSelect(!campaignSelect)}
                >
                  {campaignSelect ? (
                    <img
                      alt="select"
                      src={"/img/common/Vector.svg"}
                      className={classes.check_box_icon}
                    />
                  ) : null}
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <Box
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "5px",
                    background: "#FB58A4",
                  }}
                ></Box>
                <Box
                  className={classes.chart_description}
                  style={{ color: "#783CC6" }}
                >
                  {currentItem6 ? currentItem6.Name : "Competitive"}
                </Box>
                <Box
                  className={classes.check_box}
                  onClick={() => setCompetitiveSelect(!competitiveSelect)}
                >
                  {competitiveSelect ? (
                    <img
                      alt="select"
                      src={"/img/common/Vector.svg"}
                      className={classes.check_box_icon}
                    />
                  ) : null}
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "210px",
                  display: "none",
                }}
              >
                <Box
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "5px",
                    background: "#B8E561",
                  }}
                ></Box>
                <Box
                  className={classes.chart_description}
                  style={{ color: "#783CC6" }}
                >
                  All Inventory
                </Box>
                <Box
                  className={classes.check_box}
                  onClick={() => setAllInventorySelect(!allInventorySelect)}
                >
                  {allInventorySelect ? (
                    <img
                      alt="select"
                      src={"/img/common/Vector.svg"}
                      className={classes.check_box_icon}
                    />
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{ height: "490px" }}>
            <BarChart
              campaingBar={campaignSelect}
              competitiveBar={competitiveSelect}
              allInventoryBar={allInventorySelect}
              Adv1Performance={dayAndTimeData.Adv1Daypart}
              Adv2Performance={dayAndTimeData.Adv2Daypart}
              AllPerformance={dayAndTimeData.AllDaypart}
              sort={false}
              sortMode={sortMode}
              channelAmount={7}
              adv1Name={currentItem5 ? currentItem5.Name : "Campaign"}
              adv2Name={currentItem6 ? currentItem6.Name : "Competitive"}
            />
          </Box>
        </Box>

        <Box className={classes.chart_container} style={{ marginTop: "30px" }}>
          <Box
            className={classes.chart_top_bar}
            style={isActive ? { paddingBottom: "25px" } : {}}
          >
            <Box className={classes.chart_description}>
              Weekdays distribution
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "530px",
                marginRight: "14px",
              }}
            >
              <Box className={classes.discription}>Display:</Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "191px",
                }}
              >
                <Box
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "5px",
                    background: "#00AAF4",
                  }}
                ></Box>
                <Box
                  className={classes.chart_description}
                  style={{ color: "#783CC6" }}
                >
                  {currentItem5 ? currentItem5.Name : "Campaign"}
                </Box>
                <Box
                  className={classes.check_box}
                  onClick={() => setCampaignSelect2(!campaignSelect2)}
                >
                  {campaignSelect2 ? (
                    <img
                      alt="select"
                      src={"/img/common/Vector.svg"}
                      className={classes.check_box_icon}
                    />
                  ) : null}
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <Box
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "5px",
                    background: "#FB58A4",
                  }}
                ></Box>
                <Box
                  className={classes.chart_description}
                  style={{ color: "#783CC6" }}
                >
                  {currentItem6 ? currentItem6.Name : "Competitive"}
                </Box>
                <Box
                  className={classes.check_box}
                  onClick={() => setCompetitiveSelect2(!competitiveSelect2)}
                >
                  {competitiveSelect2 ? (
                    <img
                      alt="select"
                      src={"/img/common/Vector.svg"}
                      className={classes.check_box_icon}
                    />
                  ) : null}
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "210px",
                  display: "none",
                }}
              >
                <Box
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "5px",
                    background: "#B8E561",
                  }}
                ></Box>
                <Box
                  className={classes.chart_description}
                  style={{ color: "#783CC6" }}
                >
                  All Inventory
                </Box>
                <Box
                  className={classes.check_box}
                  onClick={() => setAllInventorySelect2(!allInventorySelect2)}
                >
                  {allInventorySelect2 ? (
                    <img
                      alt="select"
                      src={"/img/common/Vector.svg"}
                      className={classes.check_box_icon}
                    />
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{ height: "490px" }}>
            <BarChart
              campaingBar={campaignSelect2}
              competitiveBar={competitiveSelect2}
              allInventoryBar={allInventorySelect2}
              Adv1Performance={dayAndTimeData.Adv1Weekday}
              Adv2Performance={dayAndTimeData.Adv2Weekday}
              AllPerformance={dayAndTimeData.AllWeekday}
              sort={false}
              sortMode={sortMode}
              channelAmount={7}
              adv1Name={currentItem5 ? currentItem5.Name : "Campaign"}
              adv2Name={currentItem6 ? currentItem6.Name : "Competitive"}
            />
          </Box>
        </Box>

        <Box>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className={classes.accadion_one}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              {!expanded ? (
                <Typography className={classes.heading}>Filters</Typography>
              ) : null}
            </AccordionSummary>
            <AccordionDetails className={classes.accadion_details}>
              <Typography className={classes.discription}>
                Trend period:
              </Typography>
              <Box
                className={classes.box}
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                <Box
                  style={{
                    width: "49px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                >
                  {currentItem ? currentItem : setDefaultValue()}
                </Box>
                <Box>
                  <Button
                    style={{ width: "0px", height: "0px", minWidth: "0px" }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <img alt="arrow" src="/img/accardion/Vector9.svg" />
                  </Button>
                </Box>
              </Box>
              <Box
                className={classes.box}
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "3.88%",
                  cursor: "pointer",
                }}
                onClick={handleClick2}
              >
                <Box
                  style={{
                    width: "30px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                >
                  {currentItem2 ? currentItem2 : setDefaultValue()}
                </Box>
                <Box>
                  <Button
                    style={{ width: "0px", height: "0px", minWidth: "0px" }}
                    aria-controls="simple-menu2"
                    aria-haspopup="true"
                  >
                    <img alt="arrow" src="/img/accardion/Vector9.svg" />
                  </Button>
                </Box>
              </Box>
              <Box
                className={classes.box}
                style={
                  isActive
                    ? {
                        width: "15.88%",
                        marginLeft: "0.94%",
                        display: "flex",
                        justifyContent: "space-around",
                        cursor: "pointer",
                      }
                    : {
                        width: "18.88%",
                        marginLeft: "0.94%",
                        display: "flex",
                        justifyContent: "space-around",
                        cursor: "pointer",
                      }
                }
                onClick={handleClick3}
              >
                <Box
                  style={{
                    width: "250px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                >
                  {currentItem3.Name ? currentItem3.Name : setDefaultValue2()}
                </Box>
                <Box>
                  <Button
                    style={{ width: "0px", height: "0px", minWidth: "0px" }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <img alt="arrow" src="/img/accardion/Vector9.svg" />
                  </Button>
                </Box>
              </Box>
              <Box
                className={classes.box}
                style={
                  isActive
                    ? {
                        width: "27.88%",
                        marginLeft: "1.38%",
                        marginRight: "4%",
                        display: "flex",
                        justifyContent: "space-around",
                        cursor: "pointer",
                      }
                    : {
                        width: "33.88%",
                        marginLeft: "1.38%",
                        display: "flex",
                        justifyContent: "space-around",
                        cursor: "pointer",
                      }
                }
                onClick={handleClick5}
              >
                <Box
                  style={{
                    width: "550px",
                    paddingLeft: "13px",
                    paddingTop: "4px",
                  }}
                >
                  {currentItem5 ? currentItem5.Name : "Choose Advertiser"}
                </Box>
                <Box>
                  <Button
                    style={{ width: "0px", height: "0px", minWidth: "0px" }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <img alt="arrow" src="/img/accardion/Vector9.svg" />
                  </Button>
                </Box>
              </Box>
              <Box
                style={
                  isActive
                    ? {
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: ".2%",
                      }
                    : {
                        display: "flex",
                        justifyContent: "space-between",
                        width: "22.88%",
                        marginRight: "2.5%",
                      }
                }
              >
                <Box
                  style={
                    isActive ? {} : { marginLeft: "7.5%", marginRight: "2.5%" }
                  }
                >
                  <Typography className={classes.discription}>
                    Trend period end date:
                  </Typography>
                </Box>
                <Box>
                  <DatePickerCustom
                    dateType={"SET_DATE_TO_DAYANDTIME"}
                    reducer={"planner"}
                    dateArr={"dateOptionsDayAndTime"}
                  />
                </Box>
              </Box>
              <img
                alt="icon"
                src="/img/accardion/Group736.svg"
                style={{ marginTop: "-1.88%", marginRight: "0.32%" }}
              />
              <Typography className={classes.discription}>including</Typography>
              <Box
                className={classes.box}
                style={
                  isActive
                    ? {
                        width: "15.19%",
                        marginLeft: "1.25%",
                        display: "flex",
                        justifyContent: "space-around",
                        cursor: "pointer",
                      }
                    : {
                        width: "17.19%",
                        marginLeft: "1.25%",
                        display: "flex",
                        justifyContent: "space-around",
                        cursor: "pointer",
                      }
                }
                onClick={handleClick4}
              >
                <Box
                  style={{
                    width: "220px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                >
                  {currentItem4 ? currentItem4.Name : "Target audience"}
                </Box>
                <Box>
                  <Button
                    style={{ width: "0px", height: "0px", minWidth: "0px" }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <img alt="arrow" src="/img/accardion/Vector9.svg" />
                  </Button>
                </Box>
              </Box>
              <Box style={{ marginLeft: "41px" }}>
                <Typography className={classes.discription}>
                  Choose competitive:
                </Typography>
              </Box>
              <Box
                className={classes.box}
                style={
                  isActive
                    ? {
                        width: "15.19%",
                        marginLeft: "1.25%",
                        display: "flex",
                        justifyContent: "space-around",
                        cursor: "pointer",
                      }
                    : {
                        width: "17.19%",
                        marginLeft: "1.25%",
                        display: "flex",
                        justifyContent: "space-around",
                        cursor: "pointer",
                      }
                }
                onClick={handleClick6}
              >
                <Box
                  style={{
                    width: "220px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                >
                  {currentItem6 ? currentItem6.Name : "Choose advertiser"}
                </Box>
                <Box>
                  <Button
                    style={{ width: "0px", height: "0px", minWidth: "0px" }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <img alt="arrow" src="/img/accardion/Vector9.svg" />
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box>
          <Accordion
            expanded={expanded2 === "panel1"}
            onChange={handleChange2("panel1")}
            className={classes.accadion_one}
            style={{ marginTop: "30px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              {!expanded2 ? (
                <Typography className={classes.heading}>Data table</Typography>
              ) : null}
            </AccordionSummary>
            <AccordionDetails
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "-24px",
              }}
            >
              <Box>
                <TableContainer component={Paper} style={{ boxShadow: "none" }}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                    style={{
                      width: "89.88%",
                      marginLeft: "5%",
                      marginRight: "5%",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="right"></StyledTableCell>
                        <StyledTableCell
                          align="right"
                          colSpan={isActive ? 3 : 2}
                        >
                          {currentItem5 ? currentItem5.Name : "Advertiser 1"}{" "}
                          Performance
                        </StyledTableCell>
                        <StyledTableCell align="right" colSpan={3}>
                          {currentItem6 ? currentItem6.Name : "Advertiser 2"}{" "}
                          Performance
                        </StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                        {/* For future */}
                        {/* <StyledTableCell align="right" colSpan={3}>
                          All Inventory Performance
                        </StyledTableCell> */}
                      </TableRow>
                      <TableRow>
                        <StyledTableCell align="center"></StyledTableCell>

                        <StyledTableCellDescription align="center">
                          Cumulate Reach
                        </StyledTableCellDescription>
                        <StyledTableCellDescription align="center">
                          Cumulate Ratings
                        </StyledTableCellDescription>
                        <StyledTableCellDescription align="center">
                          Avg Ratings
                        </StyledTableCellDescription>

                        <StyledTableCellDescription align="center">
                          Cumulate Reach
                        </StyledTableCellDescription>
                        <StyledTableCellDescription align="center">
                          Cumulate Ratings
                        </StyledTableCellDescription>
                        <StyledTableCellDescription align="center">
                          Avg Ratings
                        </StyledTableCellDescription>
                        {/* For future */}
                        {/* <StyledTableCellDescription align="center">
                          Cumulate Reach
                        </StyledTableCellDescription>
                        <StyledTableCellDescription align="center">
                          Cumulate Ratings
                        </StyledTableCellDescription>
                        <StyledTableCellDescription align="center">
                          Avg Ratings
                        </StyledTableCellDescription> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dayAndTimeDataForTable.map((item) => (
                        <StyledTableRow
                          key={item.Name + Math.random(-10, 1000)}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            className={classes.headTableSell}
                            align="center"
                          >
                            {item.Name}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.defaultTableSell}
                          >
                            {item.CumulativeReach}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.defaultTableSell}
                          >
                            {item.CumulativeRatings}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.defaultTableSell}
                          >
                            {item.AvgRatings}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.defaultTableSell}
                          >
                            {item.Adv2CumulativeReach}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.defaultTableSell}
                          >
                            {item.Adv2CumulativeRatings}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.defaultTableSell}
                          >
                            {item.Adv2AvgRatings}
                          </StyledTableCell>
                          {/* For future */}
                          {/* <StyledTableCell
                            align="center"
                            className={classes.defaultTableSell}
                          >
                            {item.AllCumulativeReach}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.defaultTableSell}
                          >
                            {item.AllCumulativeReach}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.defaultTableSell}
                          >
                            {item.AllAvgRatings}
                          </StyledTableCell> */}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Box>
                  <img alt="download" src="/img/accardion/Download_light.svg" />
                </Box>
                <Box>
                  <CSVLink
                    data={"data"}
                    filename={`${currentItem}-rating${new Date()
                      .toJSON()
                      .slice(0, 10)
                      .replace(/-/g, "/")}.csv`}
                    className={classes.downlod_text}
                  >
                    Download as Excel
                  </CSVLink>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {currentItem === "Weeks"
            ? days?.map((item) => (
                <MenuItem
                  onClick={handleClose}
                  data-my-value={item.Name}
                  key={item.Id}
                >
                  {item.Name}
                </MenuItem>
              ))
            : weeks?.map((item) => (
                <MenuItem
                  onClick={handleClose}
                  data-my-value={item.Name}
                  key={item.Id}
                >
                  {item.Name}
                </MenuItem>
              ))}
        </StyledMenu>
        <StyledMenu
          id="simple-menu2"
          anchorEl={anchorEl2}
          keepMounted
          open={Boolean(anchorEl2)}
          onClose={handleClose2}
          PaperProps={{
            style: {
              width: "3.5%",
            },
          }}
        >
          {currentItem === "Weeks"
            ? weeks !== undefined
              ? weeks[0]?.NextCollection.map((item) => (
                  <MenuItem
                    onClick={handleClose2}
                    data-my-value={item.Name}
                    key={item.Id}
                  >
                    {" "}
                    {item.Name}{" "}
                  </MenuItem>
                ))
              : null
            : days !== undefined
            ? days[0]?.NextCollection.map((item) => (
                <MenuItem
                  onClick={handleClose2}
                  data-my-value={item.Name}
                  key={item.Id}
                >
                  {" "}
                  {item.Name}{" "}
                </MenuItem>
              ))
            : null}
        </StyledMenu>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl3}
          keepMounted
          open={Boolean(anchorEl3)}
          onClose={handleClose3}
          PaperProps={{
            style: {
              width: "16.5%",
            },
          }}
        >
          {currentItem3.Name === "All"
            ? [
                [
                  <MenuItem
                    onClick={handleClose3}
                    id="2"
                    data-my-value={"Linear"}
                  >
                    Linear
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose3}
                    id="3"
                    data-my-value={"Non-Linear"}
                  >
                    Non-Linear
                  </MenuItem>,
                ],
              ]
            : currentItem3.Name === "Linear"
            ? [
                [
                  <MenuItem onClick={handleClose3} id="1" data-my-value={"All"}>
                    All
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose3}
                    id="3"
                    data-my-value={"Non-Linear"}
                  >
                    Non-Linear
                  </MenuItem>,
                ],
              ]
            : currentItem3.Name === "Non-Linear"
            ? [
                [
                  <MenuItem onClick={handleClose3} id="1" data-my-value={"All"}>
                    All
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose3}
                    id="2"
                    data-my-value={"Linear"}
                  >
                    Linear
                  </MenuItem>,
                ],
              ]
            : null}
        </StyledMenu>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl4}
          keepMounted
          open={Boolean(anchorEl4)}
          onClose={handleClose4}
          PaperProps={{
            style: {
              width: "15.2%",
            },
          }}
        >
          {currentItem === "Weeks"
            ? weeks !== undefined
              ? weeks[0]?.NextCollection.filter(
                  (item) =>
                    item.Name === localStorage.getItem("Common_TimeNumber")
                )[0]
                  ?.NextCollection.filter(
                    (item) =>
                      item.Name ===
                      JSON.parse(
                        localStorage.getItem(
                          "Common_CampaignAndDashboard_AttributionValue"
                        )
                      )?.Value
                  )[0]
                  ?.NextCollection.map((item) => (
                    <MenuItem
                      onClick={handleClose4}
                      id={item?.Id}
                      data-my-value={item?.Name}
                      key={item?.Id}
                    >
                      {item?.Name}
                    </MenuItem>
                  ))
              : null
            : days !== undefined
            ? days[0]?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]
                ?.NextCollection.filter(
                  (item) =>
                    item.Name ===
                    JSON.parse(
                      localStorage.getItem(
                        "Common_CampaignAndDashboard_AttributionValue"
                      )
                    )?.Value
                )[0]
                ?.NextCollection.map((item) => (
                  <MenuItem
                    onClick={handleClose4}
                    id={item?.Id}
                    data-my-value={item?.Name}
                    key={item?.Id}
                  >
                    {item?.Name}
                  </MenuItem>
                ))
            : null}
        </StyledMenu>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl5}
          keepMounted
          open={Boolean(anchorEl5)}
          onClose={handleClose5}
          PaperProps={{
            style: {
              width: "29.5%",
            },
          }}
        >
          {currentItem === "Weeks"
            ? weeks !== undefined
              ? weeks[0]?.NextCollection.filter(
                  (item) =>
                    item.Name === localStorage.getItem("Common_TimeNumber")
                )[0]?.NextCollection.map((item) => (
                  <MenuItem
                    onClick={handleClose5}
                    id={item.Id}
                    data-my-value={JSON.stringify(item)}
                    key={item.Id + Math.random(-200, -99)}
                  >
                    {item.Name}
                  </MenuItem>
                ))
              : null
            : days !== undefined
            ? days[0]?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]?.NextCollection.map((item) => (
                <MenuItem
                  onClick={handleClose5}
                  data-my-value={JSON.stringify(item)}
                  key={item.Id}
                >
                  {item.Name}
                </MenuItem>
              ))
            : null}
        </StyledMenu>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl6}
          keepMounted
          open={Boolean(anchorEl6)}
          onClose={handleClose6}
          PaperProps={{
            style: {
              width: "15.2%",
            },
          }}
        >
          {currentItem === "Weeks"
            ? weeks !== undefined
              ? weeks[0]?.NextCollection.filter(
                  (item) =>
                    item.Name === localStorage.getItem("Common_TimeNumber")
                )[0]?.NextCollection.map((item) => (
                  <MenuItem
                    onClick={handleClose6}
                    id={item.Id}
                    data-my-value={JSON.stringify(item)}
                    key={item.Id}
                  >
                    {item.Name}
                  </MenuItem>
                ))
              : null
            : days !== undefined
            ? days[0]?.NextCollection.filter(
                (item) =>
                  item.Name === localStorage.getItem("Common_TimeNumber")
              )[0]?.NextCollection.map((item) => (
                <MenuItem
                  onClick={handleClose6}
                  data-my-value={JSON.stringify(item)}
                  key={item.Id + Math.random(-100, -10)}
                >
                  {item.Name}
                </MenuItem>
              ))
            : null}
        </StyledMenu>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl7}
          keepMounted
          open={Boolean(anchorEl7)}
          onClose={handleClose7}
          PaperProps={{
            style: !isActive
              ? {
                  width: "320px",
                  marginLeft: "0px",
                }
              : { width: "320px", marginLeft: "0px" },
          }}
        >
          {campaignSelect && competitiveSelect && allInventorySelect
            ? [
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv1 Cumulative Reach"}
                  >
                    {currentItem5 ? currentItem5.Name : "Adv1"} Cumulative Reach
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv1 Cumulative Ratings"}
                  >
                    {currentItem5 ? currentItem5.Name : "Adv1"} Cumulative
                    Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv1 Avg Ratings"}
                  >
                    {currentItem5 ? currentItem5.Name : "Adv1"} Avg Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv2 Cumulative Reach"}
                  >
                    {currentItem6 ? currentItem6.Name : "Adv2"} Cumulative Reach
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv2 Cumulative Ratings"}
                  >
                    {currentItem5 ? currentItem6.Name : "Adv2"} Cumulative
                    Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv2 Avg Ratings"}
                  >
                    {currentItem6 ? currentItem6.Name : "Adv2"} Avg Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"All Cumulative Reach"}
                  >
                    All Cumulative Reach
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"All Cumulative Ratings"}
                  >
                    All Cumulative Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"All Avg Ratings"}
                  >
                    All Avg Ratings
                  </MenuItem>,
                ],
              ]
            : !campaignSelect && !competitiveSelect
            ? [
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"All Cumulative Reach"}
                  >
                    All Cumulative Reach
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"All Cumulative Ratings"}
                  >
                    All Cumulative Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"All Avg Ratings"}
                  >
                    All Avg Ratings
                  </MenuItem>,
                ],
              ]
            : !allInventorySelect && !competitiveSelect
            ? [
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv1 Cumulative Reach"}
                  >
                    {currentItem5 ? currentItem5.Name : "Adv1"} Cumulative Reach
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv1 Cumulative Ratings"}
                  >
                    {currentItem5 ? currentItem5.Name : "Adv1"} Cumulative
                    Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv1 Avg Ratings"}
                  >
                    {currentItem5 ? currentItem5.Name : "Adv1"} Avg Ratings
                  </MenuItem>,
                ],
              ]
            : !allInventorySelect && !campaignSelect
            ? [
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv2 Cumulative Reach"}
                  >
                    {currentItem6 ? currentItem6.Name : "Adv2"} Cumulative Reach
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv2 Cumulative Ratings"}
                  >
                    {currentItem6 ? currentItem6.Name : "Adv2"} Cumulative
                    Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv2 Avg Ratings"}
                  >
                    {currentItem6 ? currentItem6.Name : "Adv2"} Avg Ratings
                  </MenuItem>,
                ],
              ]
            : !campaignSelect
            ? [
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv2 Cumulative Reach"}
                  >
                    {currentItem6 ? currentItem6.Name : "Adv2"} Cumulative Reach
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv2 Cumulative Ratings"}
                  >
                    {currentItem6 ? currentItem6.Name : "Adv2"} Cumulative
                    Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv2 Avg Ratings"}
                  >
                    {currentItem6 ? currentItem6.Name : "Adv2"} Avg Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"All Cumulative Reach"}
                  >
                    All Cumulative Reach
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"All Cumulative Ratings"}
                  >
                    All Cumulative Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"All Avg Ratings"}
                  >
                    All Avg Ratings
                  </MenuItem>,
                ],
              ]
            : !competitiveSelect
            ? [
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv1 Cumulative Reach"}
                  >
                    {currentItem5 ? currentItem5.Name : "Adv1"} Cumulative Reach
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv1 Cumulative Ratings"}
                  >
                    {currentItem5 ? currentItem5.Name : "Adv1"} Cumulative
                    Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv1 Avg Ratings"}
                  >
                    {currentItem5 ? currentItem5.Name : "Adv1"} Avg Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"All Cumulative Reach"}
                  >
                    All Cumulative Reach
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"All Cumulative Ratings"}
                  >
                    All Cumulative Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"All Avg Ratings"}
                  >
                    All Avg Ratings
                  </MenuItem>,
                ],
              ]
            : !allInventorySelect
            ? [
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv1 Cumulative Reach"}
                  >
                    {currentItem5 ? currentItem5.Name : "Adv1"} Cumulative Reach
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv1 Cumulative Ratings"}
                  >
                    {currentItem5 ? currentItem5.Name : "Adv1"} Cumulative
                    Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv1 Avg Ratings"}
                  >
                    {currentItem5 ? currentItem5.Name : "Adv1"} Avg Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv2 Cumulative Reach"}
                  >
                    {currentItem6 ? currentItem6.Name : "Adv2"} Cumulative Reach
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv2 Cumulative Ratings"}
                  >
                    {currentItem6 ? currentItem6.Name : "Adv2"} Cumulative
                    Ratings
                  </MenuItem>,
                ],
                [
                  <MenuItem
                    onClick={handleClose7}
                    data-my-value={"Adv2 Avg Ratings"}
                  >
                    {currentItem6 ? currentItem6.Name : "Adv2"} Avg Ratings
                  </MenuItem>,
                ],
              ]
            : null}
        </StyledMenu>
      </div>
    </div>
  );
}
