import React from "react";
import styles from "../style.module.css";
import { Link, useHistory } from "react-router-dom";

export default function Content({ children }) {
  const history = useHistory();
  return (
    <div className={styles.main_container}>
      {/* <div className={styles.container}>
                   {children}
                </div> */}
      {children}
      <footer
        className={styles.mediabrain_footer}
        style={
          history.location.pathname === "/Privacy" ? { display: "none" } : {}
        }
      >
        <div className={styles.mediabrain_footer_text}>
          &copy;{" "}
          <a id="fLink" target="_blank" href="http://mediabrain.io">
            MediaBrain Inc. 2021
          </a>{" "}
          &#9679; Data Provided by{" "}
          <a id="fLink1" target="_blank" href="http://samba.tv">
            samba.tv
          </a>{" "}
          &#9679;{" "}
          <Link target={"_blank"} to="/Privacy">
            Privacy
          </Link>
        </div>
      </footer>
    </div>
  );
}
