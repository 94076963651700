import { combineReducers, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import userReducer from "./userReducer";
import plannerReducer from "./plannerReducer";
import dashboardReducer from "./dashboardReducer";
import retargetingReducer from "./retargetingReducer";
import buyoptimizerReducer from "./buyoptimizerReducer";
import adminReducer from "./adminReducer";

const rootReducer = combineReducers({
  user: userReducer,
  planner: plannerReducer,
  dashboard: dashboardReducer,
  retargeting: retargetingReducer,
  buyoptimizer: buyoptimizerReducer,
  admin: adminReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
