const SET_USER = "SET_USER";
const LOGIN_REQ_ERR = "LOGIN_REQ_ERR";
const LOGOUT = "LOGOUT";
const SET_OPEN = "SET_OPEN";
const SET_USER_AUTH = "SET_USER_AUTH";
const SESSION_END = "SESSION_END";

const defaultState = {
  currentUser: {
    UserName: "",
    OrganizationId: "",
    AvailableDataId: "",
    IsAdmin: false,
    Roles: [],
  },
  isAuth: false,
  isLogout: false,
  isLogin: false,
  isSessionEnd: false,
  error: "",
  open: false,
};

export default function userReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        currentUser: {
          UserName: action.payload.UserName,
          OrganizationId: action.payload.OrganizationId,
          AvailableDataId: action.payload.AvailableDataId,
          IsAdmin: action.payload.IsAdmin,
          Roles: [...action.payload.Roles],
        },
        isAuth: true,
        isLogin: true,
        isLogout: false,
        isSessionEnd: false,
      };
    case SET_USER_AUTH:
      return {
        ...state,
        currentUser: {
          UserName: action.payload.UserName,
          OrganizationId: action.payload.OrganizationId,
          AvailableDataId: action.payload.AvailableDataId,
          IsAdmin: action.payload.IsAdmin,
          Roles: [...action.payload.Roles],
        },
        isAuth: true,
      };
    case LOGIN_REQ_ERR:
      return {
        ...state,
        error: action.payload,
      };
    case LOGOUT:
      localStorage.removeItem("token");
      localStorage.removeItem("RefreshToken");
      return {
        ...state,
        currentUser: {},
        isAuth: false,
        isLogout: true,
        isLogin: false,
      };
    case SESSION_END:
      return {
        ...state,
        isSessionEnd: true,
      };
    case SET_OPEN:
      return {
        ...state,
        open: action.payload,
      };
    default:
      return state;
  }
}

export const setUserActionCreator = () => ({ type: SET_USER });
