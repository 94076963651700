import React from "react";
import MainUsersComponent from "./users-components/MainUsersComponent/MainUsersComponent.js";
import { useSelector, useDispatch } from "react-redux";
import { usersList } from "../../../../actions/admin.js";

export default function Admin() {
  const dispatch = useDispatch();
  const UsersList = useSelector((state) => state.admin.UsersList);

  //accardion
  const [expandedAccordion, setExpandedAccordion] = React.useState(false);

  //accardion handler
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    dispatch(usersList());
  }, []);

  return (
    <MainUsersComponent
      users={UsersList}
      expandedAccordion={expandedAccordion}
      handleChangeAccordion={handleChangeAccordion}
    />
  );
}
