import React from "react";
import MainBrandsComponent from "./brands-components/MainBrandsComponent/MainBrandsComponent";
import { useSelector, useDispatch } from "react-redux";
import { brandsTreeData } from "../../../../actions/buyoptimizer.js";
import Helper from "../../../../helpers/helper.js";

export default function BuyOptimizer({ children }) {
  const dispatch = useDispatch();
  const BrandsTreeData = useSelector(
    (state) => state.buyoptimizer.BrandsTreeData
  );
  const BrandsAllCampaignIds = useSelector(
    (state) => state.buyoptimizer.BrandsAllCampaignIds
  );
  const SearchData = useSelector((state) => state.buyoptimizer.SearchTree);
  //accardion
  const [expandedAccordion, setExpandedAccordion] = React.useState(false);
  //tree
  const [expandedTree, setExpandedTree] = React.useState([]);
  const [selectedTreeNode, setSelectedTreeNode] = React.useState([]);

  //accardion handler
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };
  //tree handler
  const handleExpandClick = () => {
    setExpandedAccordion("panel1");
    setExpandedTree(BrandsAllCampaignIds);
  };
  const handleCollapseClick = () => {
    setExpandedTree([]);
    setExpandedAccordion(false);
  };
  const handleSelectTree = (event, nodeIds) => {
    setSelectedTreeNode(nodeIds);
  };
  const handleToggleTree = (event, nodeIds) => {
    event.persist();
    let iconClicked = event.target.closest(".MuiTreeItem-iconContainer");
    if (iconClicked) {
      setExpandedTree(nodeIds);
    }
  };
  // search input handler
  const handleChangheSearchInput = (e) => {
    if (e.target.value !== "") {
      setExpandedAccordion("panel1");
      dispatch({
        type: "SET_BRANDS_SEARCH_TREE_DATA",
        payload: Helper.recursiveTreeTraversal(BrandsTreeData, e.target.value)
          .map((item) => item.path)
          .flat(Infinity),
      });
      setExpandedTree(
        Helper.recursiveTreeTraversal(BrandsTreeData, e.target.value)
          .map((item) => item.path)
          .flat(Infinity)
      );
    } else {
      dispatch({
        type: "SET_BRANDS_SEARCH_TREE_DATA",
        payload: [],
      });
      setExpandedTree([]);
      setExpandedAccordion(false);
    }
  };
  React.useEffect(() => {
    dispatch(brandsTreeData());
  }, []);

  return (
    <MainBrandsComponent
      treeData={BrandsTreeData}
      searchData={SearchData}
      expandedAccordion={expandedAccordion}
      handleChangeAccordion={handleChangeAccordion}
      expandedTree={expandedTree}
      selectedTreeNode={selectedTreeNode}
      handleExpandClick={handleExpandClick}
      handleCollapseClick={handleCollapseClick}
      handleSelectTree={handleSelectTree}
      handleToggleTree={handleToggleTree}
      handleChangheSearchInput={handleChangheSearchInput}
    />
  );
}
