import React from "react";
import { LoadScript, GoogleMap, InfoWindow } from "@react-google-maps/api";
import apiKeys from "../../../config/apiKeys.json";
import {
  retargetingHeatmapInit,
  retargetingHeatmapData,
} from "../../../actions/retargeting.js";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import styles from "./Heatmap.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: "1000px",
  },
  infoText: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "19px",
    letterSpacing: "0em",
    textAlign: "left",
  },
}));

export default function Heatmap({ attributions, isActive }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const containerStyle = {
    height: "621px",
    width: "100%",
    borderRadius: "10px",
  };
  const containerStyleLittleDisplay = {
    height: "412px",
    width: "100%",
    borderRadius: "10px",
  };
  const [tempMap, setTempMap] = React.useState(null);
  const [infoWindow, setInfoWindow] = React.useState(null);
  const positionZero = { lat: -50, lng: -50 };

  let hmData = null;

  const populateMap = (geoJson) => {
    tempMap.data.addGeoJson(geoJson);
  };

  const getHtmlTooltip = (postcode) => {
    var res = "Postcode: " + postcode;

    let currData = hmData.find((x) => x.Postcode === postcode);

    if (currData === null || currData === undefined) return res;

    let rating = currData.Rating;
    let viewers = currData.Viewers;
    let reach = currData.Reach;
    let cost = (viewers / 1000) * 13;
    let cprp = viewers / reach;

    res += "<br> Rating: " + rating.toFixed(2);
    //res += "<br> Cost: " + cost.toFixed(2);
    res += "<br> Impressions: " + viewers;
    res += "<br> Reach: " + reach.toFixed(2);
    //res += "<br> CPRP: " + cprp.toFixed(2);

    return res;
  };

  const colorizeMap = (heatmapData, colors, missedColor, bank) => {
    hmData = heatmapData;

    tempMap.data.setStyle(function (feature) {
      let name = feature.getProperty("name");

      if (bank !== null && bank !== undefined && bank.length > 0) {
        if (
          bank.some((item) => {
            return item === name;
          }) === false
        ) {
          return {
            fillOpacity: 0,
            strokeWeight: 0,
          };
        }
      }

      let currData = heatmapData.find((x) => x.Postcode === name);
      if (currData === null || currData === undefined) {
        return {
          fillColor: missedColor,
          fillOpacity: 0.8,
          strokeWeight: 1,
        };
      }
      return {
        fillColor: currData.Color,
        fillOpacity: 0.8,
        strokeWeight: 1,
      };
    });

    tempMap.data.addListener("mouseover", function (event) {
      let name = event.feature.getProperty("name");
      let html = getHtmlTooltip(name);

      let coords = event.latLng.toJSON();

      infoWindow.setContent(html);
      infoWindow.setPosition({ lat: coords.lat + 0.1, lng: coords.lng });
    });

    tempMap.data.addListener("mouseout", function () {
      infoWindow.setContent("");
      infoWindow.setPosition(positionZero);
    });
  };
  React.useEffect(() => {
    if (tempMap !== null && infoWindow !== null) {
      dispatch(retargetingHeatmapInit(populateMap));
    }
  }, [tempMap, infoWindow]);
  React.useEffect(() => {
    if (tempMap !== null && infoWindow !== null) {
      retargetingHeatmapData(colorizeMap, attributions);
    }
  }, [tempMap, infoWindow, attributions]);
  return (
    <LoadScript googleMapsApiKey={apiKeys.googleMapsApiKey}>
      <GoogleMap
        mapContainerStyle={
          isActive ? containerStyleLittleDisplay : containerStyle
        }
        id="map"
        onLoad={setTempMap}
        center={{
          lat: 51.5074,
          lng: 0.1278,
        }}
        zoom={8}
      >
        <InfoWindow
          position={positionZero}
          onLoad={setInfoWindow}
          options={{ disableAutoPan: true }}
        >
          <div className={classes.infoText}>123</div>
        </InfoWindow>
      </GoogleMap>
    </LoadScript>
  );
}
