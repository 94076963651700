import axios from "axios";
import endpoints from "../config/endpoind.json";

export const brandsTreeData = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        endpoints.apiUrlBuyOptimizer + endpoints.BrandsTreeData,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: "SET_BRANDS_TREE_DATA",
        payload: response.data,
      });
    } catch (err) {
      console.log("ERROR planner", err);
    }
  };
};
